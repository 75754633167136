// VoiceControlDropdown.tsx

import React, { useState, useRef, useEffect } from 'react';
import MicrophoneIcon from '../../assets/volume-max-tertiary-24px.svg';
import BarredMicrophoneIcon from '../../assets/volume-x-tertiary-24px.svg';
import ChevronDownIcon from '../../assets/chevron-down-tertiary-24px.svg';
import ChevronUpIcon from '../../assets/chevron-up-tertiary-24px.svg';
import ChevronRightIcon from '../../assets/chevron-right-tertiary-24px.svg';
import ChevronLeftIcon from '../../assets/chevron-left-tertiary-24px.svg';
import { useAuth } from '../../authentification/AuthContext';
import { User } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import Slider from 'react-slider';
import Button from './Button';
import Toast from './Toast';
import { Divider } from './Divider';


export const VoiceControlDropdown: React.FC<{
  user: User;
  handleVoiceModeClick: () => void;
  handleResumeSynthesizer: (user: User) => void;
  handleInterruptSynthesizer: () => void;
}> = ({ user, handleVoiceModeClick, handleResumeSynthesizer, handleInterruptSynthesizer }) => {
  const { updateUser } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const [voice, setVoice] = useState(user?.voice_name || 'Emma');
  const voiceList = ['Emma', 'James', 'Sophia', 'Michael'];
  const [voiceIndex, setVoiceIndex] = useState(voiceList.indexOf(voice));
  const [voiceSpeed, setVoiceSpeed] = useState(user?.voice_speed || 1.0);
  const [unsavedChangesTimeout, setUnsavedChangesTimeout] = useState<NodeJS.Timeout | null>(null);
  const { getTextLangFrom } = useLanguage();
  const [showToast, setShowToast] = useState<boolean>(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.relatedTarget as Node)) {
        setShowDropdown(false);
      }
    };

    const currentDropdownRef = dropDownRef.current;

    if (showDropdown && currentDropdownRef) {
      currentDropdownRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentDropdownRef) {
        currentDropdownRef.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [showDropdown]);

  useEffect(() => {
    // Clean up timeout on component unmount or dependency change
    return () => {
      if (unsavedChangesTimeout) {
        clearTimeout(unsavedChangesTimeout);
      }
    };
  }, [unsavedChangesTimeout]);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const switchVoice = (direction?: 'left' | 'right') => {
    let newIndex;
    if (direction === 'left') {
      newIndex = voiceIndex > 0 ? voiceIndex - 1 : voiceList.length - 1;
    } else if (direction === 'right') {
      newIndex = voiceIndex < voiceList.length - 1 ? voiceIndex + 1 : 0;
    } else {
      newIndex = voiceIndex < voiceList.length - 1 ? voiceIndex + 1 : 0;
    }

    setVoiceIndex(newIndex);
    setVoice(voiceList[newIndex] as 'Emma' | 'James' | 'Sophia' | 'Michael');
    triggerSaveChangesAutomatically(newIndex, voiceSpeed);
  };

  const adjustVoiceSpeed = (increment: number) => {
    const newSpeed = voiceSpeed + increment;
    if (newSpeed > 1.5) {
      setVoiceSpeed(0.5);
    } else if (newSpeed < 0.5) {
      setVoiceSpeed(1.5);
    } else {
      setVoiceSpeed(newSpeed);
    }
    triggerSaveChangesAutomatically(voiceIndex, newSpeed);
  };

  const setNewVoiceSpeed = (newSpeed: number) => {
    setVoiceSpeed(newSpeed);
    triggerSaveChangesAutomatically(voiceIndex, newSpeed);
  };

  const triggerSaveChangesAutomatically = (newIndex: number, newSpeed: number) => {
    if (unsavedChangesTimeout) {
      clearTimeout(unsavedChangesTimeout);
    }
    const timeoutId = setTimeout(() => handleSaveChanges(newIndex, newSpeed), 1000);
    setUnsavedChangesTimeout(timeoutId);
  };

  const handleSaveChanges = async (newIndex: number, newSpeed: number) => {
    if (!user) return;
    handleInterruptSynthesizer();
    setShowToast(false);

    const updatedUser: User = {
      ...user,
      voice_speed: newSpeed,
      voice_name: voiceList[newIndex] as 'Emma' | 'James' | 'Sophia' | 'Michael',
    };

    try {
      await updateUser(updatedUser);
      setShowToast(true);
      handleResumeSynthesizer(updatedUser);
      
      // TODO: fix setVoiceSpeed. Since it is async, it may not update the value in time for the next render
      console.log('User preferences updated:', updatedUser);
      // Add any necessary post-update actions here
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const renderMicIcon = () => {
    return user?.voice_on ? MicrophoneIcon : BarredMicrophoneIcon;
  };

  return (
    <div>
      {user?.voice_on ? (
        <div className="relative flex flex-row">
          {showDropdown && (
            <div ref={dropDownRef} className="z-10 absolute flex flex-col gap-4 top-[-12px] right-[-12px] w-[300px] bg-bg-primary border border-border-secondary shadow-md rounded-lg p-3">
              <div className="flex flex-row gap-2">
                <Button
                  onClick={handleVoiceModeClick}
                  leadingIcon={renderMicIcon()}
                  text={getTextLangFrom('word-voice-on')}
                  variant='tertiary'
                  className="flex-1 h-10 justify-start"
                />
                <Button
                  iconOnly={true}
                  onClick={toggleDropdown}
                  leadingIcon={ChevronUpIcon}
                  variant='tertiary'
                />
              </div>
              <Divider />

              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-buttons-tertiary">{getTextLangFrom('VolumeDropdown-select-voice-name')}</p>
                <div className="flex justify-between items-center w-full text-sm font-medium text-buttons-tertiary gap-2">
                  <Button
                    iconOnly={true}
                    onClick={() => switchVoice('left')}
                    leadingIcon={ChevronLeftIcon}
                    variant='tertiary'
                  />
                  <Button
                    onClick={() => switchVoice()}
                    text={voice}
                    variant='tertiary'
                    className="flex-1 h-10"
                  />
                  <Button
                    onClick={() => switchVoice('right')}
                    leadingIcon={ChevronRightIcon}
                    iconOnly={true}
                    variant='tertiary'
                  />
                </div>
              </div>
              <Divider />
              <div className="flex flex-col">
                <p className="text-sm font-medium text-buttons-tertiary">{getTextLangFrom('VolumeDropdown-select-voice-speed')}</p>
                <div className="flex items-center gap-3">
                  <Slider
                    value={voiceSpeed}
                    onChange={(value) => setNewVoiceSpeed(value as number)}
                    min={0.5}
                    max={1.5}
                    step={0.1}
                    className="flex-1"
                    thumbClassName="mt-[-6px] w-5 h-5 rounded-full bg-bg-secondary border-2 border-border-secondary transition-transform transform 
                      hover:scale-110 hover:border-border-primary"
                    trackClassName="bg-bg-secondary h-1.5 rounded-full"
                  />
                  <Button
                    onClick={() => adjustVoiceSpeed(0.1)}
                    text={voiceSpeed.toFixed(1)}
                    variant='tertiary'
                    className="w-10 h-10"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex">
            <Button 
              onClick={handleVoiceModeClick}
              leadingIcon={renderMicIcon()}
              iconOnly={true}
              variant='tertiary'
            />
            <Button
              onClick={toggleDropdown}
              leadingIcon={ChevronDownIcon}
              iconOnly={true}
              variant='tertiary'
            />
          </div>
        </div>
      ) : (
        <Button 
          onClick={handleVoiceModeClick}
          leadingIcon={renderMicIcon()}
          iconOnly={true}
          variant='tertiary'
        />
      )}

      <Toast
        title={getTextLangFrom('SettingsPage-toast-title')}
        subtitle={getTextLangFrom('SettingsPage-toast-message')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default VoiceControlDropdown;
