import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import HorizontalMenu from '../baseComponents/HorizontalMenu';
import WordsTable from './WordsTable';
import { ProfileData, Word } from '../../types';
import { fetchProfilePageData } from '../../api/Profile';
import { useAuth } from '../../authentification/AuthContext';
import { StatsTiles } from './StatsTiles';
import { MainLayout } from '../baseComponents/Layout';


const ProfilePage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [wordTableData, setWordTableData] = useState<Word[]>([]);
  const [profileData, setProfileData] = useState<ProfileData>();
  const { user } = useAuth();

  const tabs = [
    { text: getTextLangFrom('word-statistics'), handleClick: () => setSelectedTab(0) },
    { text: getTextLangFrom('word-words'), handleClick: () => setSelectedTab(1) },
  ];

  useEffect(() => {
    if (user) {
      const fetchDataAsync = async () => {
        try {
          const fetchedProfileData = await fetchProfilePageData(user.id);
          setWordTableData(fetchedProfileData.words);
          setProfileData(fetchedProfileData);
          console.log('fetchedProfileData:', fetchedProfileData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchDataAsync();
    }
  }, [user]);

  return (
    <MainLayout
      hasNavbar={true}
      title={getTextLangFrom('word-profile')}
    >
      <HorizontalMenu tabs={tabs} />
      {selectedTab === 0 ? (
        <StatsTiles profileData={profileData} />
      ) : (
        <WordsTable wordTableData={wordTableData} />
      )}
    </MainLayout>
  );

};

export default ProfilePage;
