import React from 'react';
import NewWordIcon from '../../assets/new_word.svg';
import MoonIcon from '../../assets/moon-01.svg';
import styled, { css } from 'styled-components';
import { useLanguage } from '../../languages/LanguageContext';

interface BadgeProps {
  tag: string;
}

interface StyledBadgeProps {
  tag: string;
}

export const BadgeContainer = styled.div<StyledBadgeProps>`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.2px; /* Corrected from -0.2, units are required */
  text-align: center;

  img {
    height: 20px;
    width: 20px;
  }

  ${({ tag }) => {
    switch (tag) {
      case 'new':
        return css`
          color: #0CAF9F; /* Green color for 'new' */
        `;
      case 'review':
        return css`
          color: #F3C574; /* Yellow color for 'review' */
        `;
      default:
        return null; /* Default color will be applied */
    }
  }}
`;

const Badge: React.FC<BadgeProps> = ({ tag }) => {
  let content: JSX.Element | null = null;
  const { getTextLangFrom } = useLanguage();

  switch (tag) {
    case 'new':
      content = (
        <>
          <img src={NewWordIcon} alt="New" />
          {getTextLangFrom('GameCardTag-new-word')}
        </>
      );
      break;
    case 'review':
      content = (
        <>
          <img src={MoonIcon} alt="Review" />
          {getTextLangFrom('GameCardTag-previews-mistake')}
        </>
      );
      break;
    default:
      break;
  }

  return <BadgeContainer tag={tag}>{content}</BadgeContainer>;
};

export default Badge;
