import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../languages/LanguageContext';
import CloseIcon from '../../assets/x-close-tertiary-24px.svg';
import { BookDetails, Chapter } from '../../types';
import { getChapterPartMessages } from '../../api/Books';
import { handleStartGameClick } from '../baseComponents/startGame';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { useAuth } from '../../authentification/AuthContext';
import ChapterList from './ChapterList';
import Button from '../baseComponents/Button';


const BookNavigation = ({ book, chapters, onClose }: { book: BookDetails | undefined; chapters: Chapter[]; onClose: () => void }) => {
  const [openChapter, setOpenChapter] = useState<number | null>(null);
  const [selectedChapterPartId, setSelectedChapterPartId] = useState<number | undefined>(undefined);
  const [expandedChapterPartId, setExpandedChapterPartId] = useState<number | null>(null);
  const [chapterPartMessages, setChapterPartMessages] = useState<{ [key: number]: string[] }>({});
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const handleChapterClick = (index: number) => {
    setOpenChapter(openChapter === index ? null : index);
  };

  const handleContentClick = (chapterPartId: number) => {
    setSelectedChapterPartId((prev) => (prev === chapterPartId ? undefined : chapterPartId));
  };

  const toggleContentExpansion = async (event: React.MouseEvent<HTMLButtonElement>, chapter_part_id: number) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the PartDiv
    if (expandedChapterPartId === chapter_part_id) {
      setExpandedChapterPartId(null); // Collapse the chapterPartId if already expanded
    } else {
      setExpandedChapterPartId(chapter_part_id);
      if (!chapterPartMessages[chapter_part_id] && book) {
        const messages = await getChapterPartMessages(book.id, chapter_part_id);
        setChapterPartMessages((prev) => ({ ...prev, [chapter_part_id]: messages as string[] }));
      }
    }
  };

  const isButtonDisabled = selectedChapterPartId === undefined;

  return (
    <div className="overlay-page">
      <div className="bg-bg-primary p-5 rounded-lg max-w-md max-h-[560px] w-full flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">{getTextLangFrom('word-navigation')}</h2>
          <Button variant="tertiary" onClick={onClose} trailingIcon={CloseIcon} />
        </div>
        <ChapterList
          chapters={chapters}
          openChapter={openChapter}
          setOpenChapter={handleChapterClick}
          selectedChapterPartId={selectedChapterPartId}
          setSelectedChapterPartId={handleContentClick}
          expandedChapterPartId={expandedChapterPartId}
          setExpandedChapterPartId={toggleContentExpansion}
          chapterPartMessages={chapterPartMessages}
        />
        <Button
          className="w-full"
          disabled={isButtonDisabled}
          onClick={() => handleStartGameClick('resume-book', undefined, navigate, user, setIsLoading, book?.chat_id, book?.id, selectedChapterPartId)}
          text={getTextLangFrom('word-start')}
        />
      </div>
      <LoadingAnimation isLoading={isLoading} />
    </div>
  );
};

export default BookNavigation;
