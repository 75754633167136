import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
  placeholder?: string;
  label?: string;
}

const InputField = forwardRef<HTMLInputElement, InputProps>(({ hasIcon, className, ...props }, ref) => {
  return (
    <div className="form-div">
      {props.label && <label className="form-label">{props.label}</label>}
      <input
        ref={ref}
        placeholder={props.placeholder}
        className={`border border-border-primary rounded-lg py-2 px-3 text-text-primary 
                    shadow-sm bg-bg-primary 
                    placeholder:text-text-placeholder placeholder:font-normal
                    focus:border-border-brand focus:outline-none focus:shadow-ring-brand-xs
                    disabled:text-text-disabled disabled:border-border-disabled 
                    disabled:cursor-not-allowed 
                    ${hasIcon ? 'pl-10' : ''} ${className}`}
        {...props}
      />
    </div>
  );
});

export default InputField;
