import React from 'react';
import { Table } from '@tanstack/react-table';
import Button from './Button';

type PaginationControlsProps = {
  table: Table<any>;
};

const PaginationControls: React.FC<PaginationControlsProps> = ({ table }) => {
  return (
    <div className="flex justify-between items-center p-2">
      <div className="flex">
        {table.getCanPreviousPage() && (
          <Button text="Previous" variant="tertiary" onClick={() => table.previousPage()} />
        )}
      </div>
      <div className="flex items-center">
        <span className="text-sm text-text-tertiary font-medium">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
      </div>
      <div className="flex justify-end">
        {table.getCanNextPage() && (
        <Button text="Next" variant="tertiary" onClick={() => table.nextPage()} />
        )}
      </div>
    </div>
  );
};

export default PaginationControls;