import SolutionTextWrapper from './Book/SolutionTextWrapper';
import { MessageDiv } from './ChatPageStyles';
import { BookExercisePart, Message, TranslationTooltip, User } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import onBookMouseUp from './Book/TranslationTooltip/onBookMouseUp';


interface MessageContentProps {
  index: number;
  game_mode: string;
  activeMessage: Message;
  messages: Message[];
  focusedExerciseIndex: number;
  hasFlipped: boolean;
  user: User | null;
  tooltip: TranslationTooltip;
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  currentTTSMessageIndex: number | null;
  messageRole: string;
  handleTranslateInputFocus: (index: number) => void;
  handleTranslateInputBlur: () => void;
  inputRefs: any;
}

const MessageContent = ({ 
  index, game_mode, activeMessage, messages, focusedExerciseIndex, hasFlipped, 
  user, setTooltip, currentTTSMessageIndex, messageRole, handleTranslateInputFocus, 
  handleTranslateInputBlur, inputRefs
 }: MessageContentProps) => {
  const { getTextLangFrom } = useLanguage();
  const isLastMessage = index === messages.length - 1;
  

  const handleWordClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, part: BookExercisePart | string) => {
    event.preventDefault();
    event.stopPropagation();
    onBookMouseUp(event, setTooltip, user, messages, getTextLangFrom, part, true);

  }

  const ClickableTextSpan = ({ text }: { text: string }) => {
    const words = text.split(/(\s+|[.,!?;:(){}[\]<>]+)/);

    return (
      <p className="inline">
        {words.map((word, wordIndex) => (
          word.trim().length > 0 && !/[.,!?;:]/.test(word) ? (
            <span className="clickable-span span-with-click" key={wordIndex} onClick={(event) => handleWordClick(event, word)}>
              {word}
            </span>
          ) : (
            word
          )
        ))}
      </p>
    );
  };

  return (
    <MessageDiv
      key={index}
      message_role={activeMessage.message_role}
      className="message-div-class"
      game_mode={game_mode}
      transition={index === messages.length - 1 && activeMessage.message_role === 'user'}
      isreadingthismessage={index === currentTTSMessageIndex}
      data-tip
      data-for="tooltip"
    >
      <p>
        { activeMessage.bookExerciseParts?.map((part, partIndex) => (
          typeof part === 'string' ? (
              <ClickableTextSpan text={part} />
            ) : isLastMessage && part.isExercise === true ? (
              messageRole === 'book-flashcard' ? (
                <span 
                  key={partIndex}
                  className={`inline-block font-medium ${partIndex === focusedExerciseIndex ? 'text-text-primary bg-tab-bgSelected' : 'text-text-primary bg-bg-tertiary'} px-1 py-0.5 rounded`}
                  onClick={() => console.log('FlashCard clicked')}
                >
                  { ((partIndex !== focusedExerciseIndex && part.userAnswer === '') || (partIndex === focusedExerciseIndex && !hasFlipped)) ? (
                    part.lemma_lang_from
                  ) : (
                    part.solution
                  )}
                </span>     
              ) : messageRole === 'book-discover' ? (
                <span onClick={(event) => handleWordClick(event, part)}
                  key={partIndex}
                  className={`inline-block font-medium ${partIndex === focusedExerciseIndex ? 'text-text-primary bg-tab-bgSelected' : 'text-text-primary bg-bg-tertiary'} px-1 py-0.5 rounded`}
                >
                  { part.solution }
                </span>       
              ) : (messageRole === 'book-translate' && part.userAnswer === '') ? (
                <input
                  key={partIndex}
                  type="text"
                  placeholder={part.lemma_lang_from}
                  onFocus={() => handleTranslateInputFocus(partIndex)}
                  onBlur={handleTranslateInputBlur}
                  ref={(el) => inputRefs.current[partIndex] = el}
                  className={`px-2 border border-border-primary rounded-lg text-text-primary font-medium shadow-xs bg-bg-primary w-[120px] 
                    placeholder:text-text-placeholder placeholder:font-normal
                    focus:border-border-brand focus:outline-none focus:shadow-ring-brand-xs 
                    disabled:text-text-disabled disabled:border-border-disabled disabled:cursor-not-allowed`}
                />
              ) : (messageRole === 'book-translate' && part.userAnswer !== '') ? (
                <SolutionTextWrapper 
                  key={partIndex}
                  part={part}
                  theme={user?.theme || 'light'} 
                  handleShowTooltip={(event, part) => handleWordClick(event, part)}
                />
              ) : (
                <span className="span-with-click" onClick={(event) => handleWordClick(event, part)}>
                  {part.solution}
                </span>
              )
            ) : // isLastMessage is false or (isLastMessage is true and isExercise is false)
            <span className="span-with-click" onClick={(event) => handleWordClick(event, part)}>
              {part.solution}
            </span>
        ))}
      </p>
    </MessageDiv>
  );
};

export default MessageContent;
