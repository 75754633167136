import React, { useCallback, useEffect, useRef, useState } from 'react';
import StreakImage from '../../assets/streak-small.svg';
import { DailyStreakTile } from '../profilePage/StatsTiles';
import { useAuth } from '../../authentification/AuthContext';

const StreakInfo: React.FC = () => {
  const tileRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isStreakInfoOpen, setIsStreakInfoOpen] = useState(false);
  const { user } = useAuth();

  const onClose = useCallback(() => {
    setIsStreakInfoOpen(false);
  }, []);

  // Close the streak tile if clicking outside of it or the button
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tileRef.current && !tileRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isStreakInfoOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isStreakInfoOpen, onClose]);

  // Handle mouse enter and leave events
  const handleMouseEnterOrClick = () => {
    setIsStreakInfoOpen(true);
  };

  const handleMouseLeave = () => {
    setIsStreakInfoOpen(false);
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className={`h-9 flex items-center gap-3 text-lg ${isStreakInfoOpen ? 'bg-active-color' : ''}`}
        onMouseEnter={handleMouseEnterOrClick}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseEnterOrClick}
      >
        <img src={StreakImage} alt="Streak" />
        <span>{user?.number_streak_days ? user.number_streak_days : 0}</span>
      </button>
      {isStreakInfoOpen && (
        <div
          ref={tileRef}
          className="absolute right-0 mt-2 bg-bg-primary shadow-lg rounded-lg z-50"
          onMouseEnter={handleMouseEnterOrClick}
          onMouseLeave={handleMouseLeave}
        >
          <DailyStreakTile />
        </div>
      )}
    </div>
  );
};

export default StreakInfo;
