// src/globalStyles.ts
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  :root {
    // Reference for breakpoints
    // --screen-xs: 480px;
    // --screen-sm: 768px;
    // --screen-md: 1000px;
    // --screen-lg: 1280px;

    // --max-height-sm: 840px;


    --top-bar-height: 120px;
    --top-bar-height-sm: 64px;
  }
`;


