import { styled } from 'styled-components';
import { ExerciseState } from '../../../types';


const StyledGapInput = styled.div`
  display: flex;
  justify-content: center;
`;

const GapInputTextStyled = styled.div<{ exerciseState?: ExerciseState, isInputField?: boolean }>`
  white-space: pre;
  color: ${({ theme, exerciseState, isInputField }) => {
    if (isInputField && exerciseState === 'wrong') {
      return theme.error_700_fg;
    } else if (isInputField && exerciseState === 'almost-correct') {
      return theme.warning_700_fg;
    } else if (!isInputField && exerciseState) {
      return theme.success_700_fg;
    }
  }};
`;


const getStyledGapText = (string1: string, string2: string, exerciseState: ExerciseState, isInputField: boolean = false) => {
  let idx1; // index of the first different character
  let idx2; // index of the last different character initialized 
  
  for (idx1 = 0; idx1 < string1.length; idx1++) {
    if (string1[idx1] !== string2[idx1]) {
      break;
    }
  }

  // search for the first different character string1 from the end of both strings
  for (idx2 = string1.length; idx2 > 0; idx2--) {
    const idx_string2 = idx2 - string1.length + string2.length;
    if ( idx_string2 < 0 || string1[idx2-1] !== string2[idx_string2-1]) {
      break;
    }
  }

  return (
    <StyledGapInput>
      {isInputField  && string1 === string2 ? (
         <GapInputTextStyled exerciseState={exerciseState}>{string1.slice(0, idx1)} </GapInputTextStyled>
      ) : (
        <GapInputTextStyled>{string1.slice(0, idx1)}</GapInputTextStyled>
      )}
      <GapInputTextStyled exerciseState={exerciseState} isInputField={isInputField}>{string1.slice(idx1, idx2)}</GapInputTextStyled>
      {string1 !== string2 &&
        <GapInputTextStyled>{string1.slice(idx2)}</GapInputTextStyled>
      }
    </StyledGapInput>
  );
};

export default getStyledGapText;