// ChatBar.tsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ReactComponent as SendIcon } from '../../../assets/send-03-chat.svg';
import { ReactComponent as StartRecordingIcon } from '../../../assets/start-recording-chat.svg';
import { getMessageResponse } from '../../../api/Messages';
import { transcribeAudio } from '../../../api/speechToText';
import {
  ChatBarWrapper,
  ChatBarOuterDiv,
  ChatBarInnerDiv,
  StyledInput,
  ChatBarButton,
  InputChatLoader,
  StyledStopRecordingDiv,
  StyledStopRecordingCircle,
} from '../ChatPageStyles';
import { Message, User, Card } from '../../../types';
import { useLanguage } from '../../../languages/LanguageContext';
import { fetchTTSMessages, playTTSMessages } from '../../../api/textToSpeech';


interface ChatBarProps {
  game_mode: string;
  chat_id: number | null;
  agent_id: number | null;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isRecording: boolean;
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSpeechOn: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTTSMessageIndex: React.Dispatch<React.SetStateAction<number | null>>;
  audioElementsRef: React.MutableRefObject<HTMLAudioElement[]>;
  user: User | null;
  card?: Card;
}

const ChatBar: React.FC<ChatBarProps> = ({
  game_mode,
  chat_id,
  agent_id,
  messages,
  setMessages,
  isRecording,
  setIsRecording,
  isLoading,
  setIsLoading,
  setIsSpeechOn,
  setCurrentTTSMessageIndex,
  audioElementsRef,
  user,
  card,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const audioElementRef = useRef<HTMLAudioElement>(null);
  const [audioUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const { getTextLangTo, getTextLangFrom } = useLanguage();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    setIsButtonVisible(value.trim() !== '');

    if (isRecording) {
      stopRecording();
    }
  };

  const handleSend = async () => {
    if (inputValue.trim() && chat_id !== null && agent_id !== null && user) {
      setIsLoading(true);
      setInputValue('');

      const userMessage: Message = {
        chat_id: chat_id,
        user_id: user.id,
        agent_id: agent_id,
        text: inputValue,
        message_role: 'user',
      };

      const newMessages = [...messages, userMessage];
      setMessages(newMessages);

      try {
        let updatedUserMsg: Message = userMessage; // Assuming userMessage is of type Message
        let agentMessage_s: Message | Message[];
        if (game_mode === 'chat-mode') {
          const correctedMessagePromise = getMessageResponse<Message>(userMessage, "corrector-agent");
          const responsePromise = getMessageResponse<Message | Message[]>(userMessage, game_mode);
          const [correctedMessage, response_s] = await Promise.all([correctedMessagePromise, responsePromise]);
          updatedUserMsg = correctedMessage;
          agentMessage_s = response_s;
        } else {
          // One of the games, e.g. flashcards, etc. which has a sideChat with a game-support-agent
          agentMessage_s = await getMessageResponse<Message>(userMessage, "game-support-agent", card);
        }
        const updatedMessages = [...newMessages.slice(0, -1), updatedUserMsg, ...(Array.isArray(agentMessage_s) ? agentMessage_s : [agentMessage_s])];
        setMessages(updatedMessages);
        setIsLoading(false);
        setIsButtonVisible(false);

        if (user?.voice_on) {
          let languageTTS = user.lang_from;
          if (game_mode === 'chat-mode') {
            languageTTS = user.lang_to;
          }
          const number_agent_msgs = Array.isArray(agentMessage_s) ? agentMessage_s.length : 1;
          const audioElements = await fetchTTSMessages(updatedMessages, number_agent_msgs, languageTTS, user.voice_speed, user.voice_name);
          audioElementsRef.current.push(...audioElements);
          if (user.voice_on) {
            await playTTSMessages(audioElements, setIsSpeechOn, setCurrentTTSMessageIndex, updatedMessages.length);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      } 
    }
  }

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      audioElementsRef.current.forEach((audioElement: { pause: () => any; }) => audioElement.pause());
      audioElementsRef.current = [];
    }
  }, [mediaRecorderRef, setIsRecording, audioElementsRef]);

  useEffect(() => {
    return () => {
      // Cleanup function to stop recording and release resources
      if (isRecording) {
        stopRecording();
      }
    };
  }, [isRecording, stopRecording]);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (audioUrl && audioElement) {
      const playAudio = () => {
        audioElement.src = audioUrl;
        const playPromise = audioElement.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error('Error playing audio:', error);
          });
        }
      };
      playAudio();
    }
  }, [audioUrl]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunksRef.current.push(event.data);
        });

        mediaRecorder.addEventListener('stop', async () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          try {
            const transcription = await transcribeAudio(audioBlob, user?.lang_to || 'en-US');
            setInputValue(transcription);
            setIsButtonVisible(true);
          } catch (error) {
            console.error('Error transcribing audio:', error);
          }
        });

        mediaRecorder.start();
        setIsRecording(true);
      })
      .catch(error => console.error('Error accessing microphone:', error));
  };

  return (
    <ChatBarWrapper >
      <ChatBarOuterDiv >
        <ChatBarInnerDiv>
          <StyledInput
            type="text"
            value={inputValue}
            placeholder={game_mode === 'book-chat-mode' ? getTextLangTo('ChatPage-input-placeholder-story') : 
               getTextLangTo('ChatPage-input-placeholder-chat')}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
                e.preventDefault();
                handleSend();
              }
            }}
            onBlur={(e) => {
              e.preventDefault();
            }}
            inputMode="text"
            autoComplete="off"
            autoCapitalize="none"
            game_mode={game_mode}
          />
          {isLoading ? (
            <InputChatLoader />
          ) : (
            <div>
              {!isButtonVisible && (
                <ChatBarButton
                  isVisible={true}
                  onClick={isRecording ? stopRecording : startRecording}
                  leadingIcon={
                    isRecording ? (
                      <StyledStopRecordingDiv>
                        <StyledStopRecordingCircle game_mode={game_mode} theme={user?.theme} />
                      </StyledStopRecordingDiv>
                    ) : (
                      <StartRecordingIcon />
                    )
                  }
                  content="icon"
                  hierarchy="tertiaryGray"
                  game_mode={game_mode}
                />
              )}
              {audioUrl && <audio ref={audioElementRef} src={audioUrl} />}
              <ChatBarButton
                isVisible={isButtonVisible}
                onClick={handleSend}
                leadingIcon={<SendIcon />}
                content="icon"
                hierarchy="tertiaryGray"
                game_mode={game_mode}
              />
            </div>
          )}
        </ChatBarInnerDiv>
      </ChatBarOuterDiv>
    </ChatBarWrapper>
  );
};

export default ChatBar;
