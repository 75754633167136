import { styled } from 'styled-components';
import { DivArrowIcon } from '../ChatPageStyles';
import ArrowIconGreen from '../../../assets/arrow-narrow-right-green.svg';
import ArrowIconBrown from '../../../assets/arrow-narrow-right-brown.svg';


const HighlightText = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.user_message_highlight };
  border-radius: 1px;
  font-weight: 500;
`;

const BoldText = styled.span`
  
`;

const BarredText = styled.span`
  text-decoration: line-through;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const exerciseText = (message: string, game_mode: string, theme: string) => {
  const parts = message.split(/\$(.*?)>(.*?)\$/g);

  const ArrowIcon = (game_mode === 'book-chat-mode' && theme === 'light')  ? ArrowIconBrown : ArrowIconGreen;

  const formattedParts = parts.map((part, index) => {
    if (index % 3 === 1) {
      const nextPart = parts[index + 1];
      if (part !== "" && nextPart !== "") {
        return [
          <HighlightText key={index}>
            <BarredText>{part}</BarredText>
          </HighlightText>,
          <HighlightText key={`icon-${index}`}>
            <DivArrowIcon>&nbsp;<Icon src={ArrowIcon} alt="Arrow" />&nbsp;</DivArrowIcon>
          </HighlightText>,
        ];
      } else {
        return (
          <HighlightText key={index}>
            <BarredText>{part}</BarredText>
          </HighlightText>
        );
      }
    } else if (index % 3 === 2) {
      return (
        <HighlightText key={index}>
          <BoldText>{part}</BoldText>
        </HighlightText>
      );
    } else {
      return part;
    }
  });

  return <div>{formattedParts}</div>;
};

export default exerciseText;