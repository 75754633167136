import { useEffect } from 'react';

interface VisualViewportResizeCallback {
  (height: number): void;
}

const useVisualViewportResize = (callback: VisualViewportResizeCallback) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const height = window.visualViewport.height;
        callback(height);
      }
    };

    // Initial setup
    handleResize();

    // Add event listener for visual viewport resize
    window.visualViewport?.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, [callback]); // Include callback in the dependency array to ensure it's updated correctly
};


export default useVisualViewportResize;
