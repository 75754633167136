import React from 'react';
import ChevronRight from '../../../assets/chevron-right-white.svg';
import { useLanguage } from '../../../languages/LanguageContext';
import { Card, ExerciseState, ExerciseStateToButtonVariant } from '../../../types';
import FooterControls from '../../baseComponents/FooterControls';
import { ResultsDisplay } from '../FooterExerciseInfo';
import Button from '../../baseComponents/Button';


interface WriteControlsProps {
    showAnswerButtons: boolean;
    exerciseState: ExerciseState
    card: Card;
    handleRevealAnswerClick: () => void;
    handleNextButtonClick: () => void;
    userResponse: string;
    messageRole: string;
}

const WriteControls: React.FC<WriteControlsProps> = ({
    showAnswerButtons,
    exerciseState,
    card,
    handleRevealAnswerClick,
    handleNextButtonClick,
    userResponse,
    messageRole
}) => {
    const { getTextLangFrom } = useLanguage();

    return (
        <FooterControls exerciseState={showAnswerButtons ? exerciseState : undefined} messageRole={messageRole}>
            {showAnswerButtons && (
                <ResultsDisplay
                    lemma_lang_to={card.lemma_lang_to}
                    example_lang_to={card.example_lang_to}
                    exerciseState={exerciseState}
                    pos={card.pos}
                />
            )}
            <div className="footer-controls-action-div">
                <Button
                    size="control"
                    trailingIcon={ChevronRight}
                    onClick={!showAnswerButtons ? handleRevealAnswerClick : handleNextButtonClick}
                    variant={ExerciseStateToButtonVariant(showAnswerButtons ? exerciseState : "unanswered")}
                    disabled={userResponse === ''}
                    text={!showAnswerButtons ? getTextLangFrom('word-show') : getTextLangFrom('word-next')}
                />
            </div>
        </FooterControls>
    );
};

export default WriteControls;
