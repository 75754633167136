import React, { useState, useCallback } from 'react';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { uploadBook } from '../../api/Books';
import { useLanguage } from '../../languages/LanguageContext';
import { useAuth } from '../../authentification/AuthContext';
import { ReactComponent as BackIcon } from '../../assets/chevron-left-tertiary-24px.svg';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../baseComponents/Layout';
import DropArea from '../baseComponents/DropArea';

const BookUploadPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const { user } = useAuth();

  const handleFileUpload = useCallback(async (file: File) => {
    if (!user) {
      return;
    }

    if (file && file.type === 'application/epub+zip') {
      setIsLoading(true);
      try {
        const bookDetails = await uploadBook(file, user.id);
        navigate('/book-edit', { state: { bookDetails } });
      } catch (error) {
        console.error("Error uploading the file:", error);
        alert("Failed to upload file. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please upload a valid .epub file.");
    }
  }, [user, navigate]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  }, [handleFileUpload]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <MainLayout
      hasNavbar={true}
      title={getTextLangFrom('BookUploadPage-title')}
    >
      <DropArea 
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('droparead-upload')?.click()}
        handleFileChange={handleFileChange}
        isLoading={isLoading}
        getTextLangFrom={getTextLangFrom}
        supportedFormats='.epub'
      />
      <LoadingAnimation isLoading={isLoading} />
    </MainLayout>
  );
};

export default BookUploadPage;
