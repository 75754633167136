import { parseTextWithoutExercises } from '../components/chatPage/Book/messageTextParser';
import { Message } from '../types';

const apiUrl = 'https://texttospeech.googleapis.com/v1/text:synthesize';
// TODO: Remove this key and use a secure way to store it
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;


interface TTSResponse {
  audioContent: string;
}

interface LanguageInfo {
  languageCode: string;
  voice: string;
}

function transformLangToCode(language: string, provider: string, voice_name: string): LanguageInfo {
  let languageCode = '';
  let voice = '';
  
  if (provider === 'google') {
    // List of supported voices: https://cloud.google.com/text-to-speech/docs/voices
    switch (language) {
      // IMPORTANT: The voice names should be the same as in server/utils/agents.py
      case 'en':
        languageCode = 'en-US';
        voice = voice_name === 'Emma' ? 'en-US-Studio-O' :
                voice_name === 'James' ? 'en-US-Studio-Q' :
                voice_name === 'Sophia' ? 'en-US-Wavenet-C' :
                voice_name === 'Michael' ? 'en-US-Wavenet-A' : '';
        break;
      case 'de':
        languageCode = 'de-DE';
        voice = voice_name === 'Emma' ? 'de-DE-Studio-C' :
                voice_name === 'James' ? 'de-DE-Studio-B' :
                voice_name === 'Sophia' ? 'de-DE-Wavenet-A' :
                voice_name === 'Michael' ? 'de-DE-Wavenet-B' : '';
        break;
      case 'it':
        languageCode = 'it-IT';
        voice = voice_name === 'Emma' ? 'it-IT-Wavenet-A' :
                voice_name === 'James' ? 'it-IT-Wavenet-C' :
                voice_name === 'Sophia' ? 'it-IT-Standard-A' :
                voice_name === 'Michael' ? 'it-IT-Wavenet-D' : '';
        break;
      // case 'es':
      //   languageCode = 'es-ES';
      //   voice = voice_name === 'Emma' ? 'es-ES-Studio-C' :
      //           voice_name === 'James' ? 'es-ES-Studio-F' :
      //           voice_name === 'Sophia' ? 'es-ES-Wavenet-C' :
      //           voice_name === 'Michael' ? 'es-ES-Wavenet-B' : '';
      //   break;
      // case 'fr':
      //   languageCode = 'fr-FR';
      //   voice = voice_name === 'Emma' ? 'fr-FR-Studio-A' :
      //           voice_name === 'James' ? 'fr-FR-Studio-D' :
      //           voice_name === 'Sophia' ? 'fr-FR-Wavenet-A' :
      //           voice_name === 'Michael' ? 'fr-FR-Studio-D' : '';
      //   break;
      // case 'pl':
      //   languageCode = 'pl-PL';
      //   voice = voice_name === 'Emma' ? 'pl-PL-Wavenet-A' :
      //           voice_name === 'James' ? 'pl-PL-Wavenet-B' :
      //           voice_name === 'Sophia' ? 'pl-PL-Wavenet-D' :
      //           voice_name === 'Michael' ? 'pl-PL-Wavenet-C' : '';
      //   break;
      default:
        console.error('Unsupported language:', language);
        languageCode = 'en-US';
        voice = voice_name === 'Emma' ? 'en-US-Studio-O' :
                voice_name === 'James' ? 'en-US-Studio-Q' :
                voice_name === 'Sophia' ? 'en-US-Wavenet-C' :
                voice_name === 'Michael' ? 'en-US-Wavenet-A' : '';
        break;
    }
  } else {
    throw new Error('Unsupported provider');
  }

  return { languageCode, voice };
}


export async function getTTSResponse(text: string, language: string, voice_speed: number, voice_name: string): Promise<ArrayBuffer> {
  // TODO: consider adding more voice options
  const { languageCode, voice } = transformLangToCode(language, 'google', voice_name );
  const cleanText = cleanTextForTTS(text);
  
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = {
    key: googleApiKey,
  };

  const requestData = {
    input: {
      text: cleanText,
    },
    voice: {
      languageCode: languageCode,
      name: voice,
    },
    audioConfig: {
      audioEncoding: 'MP3',
      pitch: 0, // [-20.0, 20.0]
      speakingRate: voice_speed, // [0.25, 4.0]
    },
  };

  const response = await fetch(`${apiUrl}?key=${params.key}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
  }

  const responseData: TTSResponse = await response.json();

  if (!responseData.audioContent) {
    throw new Error('Error: No audio content in the response');
  }

  // Decode base64-encoded audio content to ArrayBuffer
  const audioContentBytes = base64ToArrayBuffer(responseData.audioContent);
  return audioContentBytes;
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer as ArrayBuffer;
}

function cleanTextForTTS(text: string): string {
  // Remove special characters for exercises
  text = parseTextWithoutExercises(text);

  // Remove emojis
  text = text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

  // Remove * and "
  text = text.replace(/[*"]/g, '');

  return text;
}


export const fetchTTSMessages = async (
  messages: Message[], 
  maxNumberMessagesToFetch: number = 1, 
  language: string,
  voice_speed: number,
  voice_name: string,
) => {
  try {
    const audioElements: HTMLAudioElement[] = [];

    for (let i = messages.length - maxNumberMessagesToFetch; i < messages.length; i++) {
      const message = messages[i];

      const audioData = await getTTSResponse(message.text, language || 'en', voice_speed, voice_name);
      const blob = new Blob([audioData], { type: 'audio/mpeg' });
      const objectURL = URL.createObjectURL(blob);
      const audioElement = new Audio(objectURL);
      audioElements.push(audioElement);
    }

    return audioElements;
  } catch (error) {
    console.error('Error fetching TTS messages:', error);
    throw error;
  }
};

export const playTTSMessages = async (
  audioElements: HTMLAudioElement[],
  setIsSpeechOn: React.Dispatch<React.SetStateAction<boolean>>, 
  setCurrentTTSMessageIndex: React.Dispatch<React.SetStateAction<number | null>>, 
  numberOfMessages: number
) => {
  try {
    for (let i = 0; i < audioElements.length; i++) {
      const audioElement = audioElements[i];

      setIsSpeechOn(true);
      setCurrentTTSMessageIndex(numberOfMessages-audioElements.length+i); // Set the current message index being read

      await new Promise<void>((resolve, reject) => {
        audioElement.addEventListener('ended', () => {
          URL.revokeObjectURL(audioElement.src);
          setIsSpeechOn(false);
          setCurrentTTSMessageIndex(null); // Reset the current message index
          resolve();
        });
        audioElement.addEventListener('error', (error) => {
          console.error('Error playing audio:', error);
          setIsSpeechOn(false);
          setCurrentTTSMessageIndex(null); // Reset the current message index
          reject(error);
        });
        audioElement.play();
      });
    }
  } catch (error) {
    console.error('Error playing TTS messages:', error);
  }
};