import React, { useState, useRef, useEffect, useCallback } from 'react';
import { User, Message, BookExercisePart, GameState, TranslationTooltip, ExerciseState } from '../../../types';
import { useLanguage } from '../../../languages/LanguageContext';
import { getMessageResponse } from '../../../api/Messages';
import { fetchTTSMessages, playTTSMessages } from '../../../api/textToSpeech';
import { useLocation, useNavigate } from 'react-router-dom';
import { evaluateExerciseState } from '../../../api/Games';
import { parseTextWithExercises } from './messageTextParser';
import { terminateChapter } from '../../../api/Books';
import BookFooterControls from './BookFooterControls';
import MessageContent from '../MessageContent';
import BookDiscoveryDiv from './BookDiscoveryDiv';
import TranslationTooltipDiv from './TranslationTooltip/TranslationTooltip';
import onBookMouseUp from './TranslationTooltip/onBookMouseUp';


const BookExercise: React.FC<{ 
  messages: Message[]; 
  game_mode: string; 
  user: User | null;
  currentTTSMessageIndex: number | null;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  audioElementsRef: React.MutableRefObject<HTMLAudioElement[]>;
  setIsSpeechOn: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTTSMessageIndex: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ messages, game_mode, user, currentTTSMessageIndex, setMessages, audioElementsRef, setIsSpeechOn, setCurrentTTSMessageIndex }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState({} as TranslationTooltip);
  const [lastMessageExParts, setLastMessageExParts] = useState<(string | BookExercisePart)[]>([]);
  const { getTextLangFrom } = useLanguage();
  const [focusedExerciseIndex, setFocusedExerciseIndex] = useState<number>(-1);
  const [messageRole, setMessageRole] = useState<string>('');
  const [hasFlipped, setHasFlipped] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState(getTextLangFrom('word-continue'));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [exerciseState, setExerciseState] = useState<ExerciseState>("unanswered");
  const [learnWordPreferences, setLearnWordPreferences] = useState<{ translation_id: number, isToLearn: boolean }[]>([]);

  const focusNextExercise = useCallback((bookExerciseParts: (string | BookExercisePart)[]) => {
    // Remove focus from flashcard
    setFocusedExerciseIndex(-2);
    setExerciseState("unanswered");
  
    // Find next input
    for (let i = 0; i < bookExerciseParts.length; i++) {
      if (typeof bookExerciseParts[i] !== 'string' && (bookExerciseParts[i] as BookExercisePart).isExercise && (bookExerciseParts[i] as BookExercisePart).userAnswer === '') {
        setFocusedExerciseIndex(i);
        if (messageRole === 'book-translate') {
          const inputRef = inputRefs.current[i];
          inputRef?.focus();
        }
        return i;
      }
    }
    return -2;
  }, [messageRole]);

  useEffect(() => {
    // Parse the last message to get the exercise bookExerciseParts
    if (messages.length !== 0 && messages[messages.length - 1].bookExerciseParts === undefined) {
      
      const updatedMessages = messages.map((message, index) => {
        const newbookExerciseParts = parseTextWithExercises(message.text);
        message.bookExerciseParts = newbookExerciseParts;
        if (index === messages.length - 1) {
          setLastMessageExParts(newbookExerciseParts);
        }
        return message;
      });
      setMessages(updatedMessages);
      
      const lastMessageText = messages[messages.length - 1];
      setMessageRole(lastMessageText.message_role);
      const index = focusNextExercise(lastMessageText?.bookExerciseParts || []);
      if (lastMessageText.message_role === 'book-translate') {
        // Set focus on index
        const inputRef = inputRefs.current[index];
        inputRef?.focus();
      }
    }
  }, [messages, setMessages, focusNextExercise]);

  const setMessagesAndScrollDown = useCallback((newMessages: Message[]) => {
    setMessages(newMessages);
    setTimeout(() => {
      messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current?.scrollHeight);
    }, 100);
  }, [setMessages]);


  const handleClick = useCallback(async (userAnswer: string) => {
    const buildMessageFromBookExerciseParts = (lastMessage: Message) => {
      let newMessageText = '';
      if (lastMessage.bookExerciseParts !== undefined) {
        lastMessage.bookExerciseParts.forEach(part => {
          if (typeof part === 'string') {
            newMessageText += part;
          } else {
            newMessageText += `$${part.translation_id}>${part.solution}>${part.userAnswer}>${part.isExercise}$`;
          }
        });
        return { ...lastMessage, text: newMessageText, message_role: messageRole };
      } else {
        return lastMessage;
      }
    }

    let inputRef = null as HTMLInputElement | null;

    if (focusedExerciseIndex === -2) {
      // End of exercise. Get the next messages
      setFocusedExerciseIndex(-1);
      setIsSpeechOn(false);
      setExerciseState("loading");
      const last_message = buildMessageFromBookExerciseParts(messages[messages.length - 1]);

      const newMessages = await getMessageResponse<Message[]>(last_message, game_mode);
      setExerciseState("unanswered");
      
      if (newMessages.length === 0) {
        // End of chapter
        const { gameData } = location.state as { gameData: GameState };
        const { accuracy, session_duration } = await terminateChapter(messages[0].chat_id);
        const updatedGameData = {
          ...gameData,
          chat_id: messages[0].chat_id,
          accuracy: accuracy,
          session_duration: session_duration,
        }

        navigate('/endgame', { state: { gameData: updatedGameData } });
      } else {
        // Continue with the next messages
        setMessageRole(newMessages[newMessages.length - 1].message_role);
        setMessagesAndScrollDown([...messages, ...newMessages] );
        if (user?.voice_on) {
          const number_agent_msgs = newMessages.length;
          const audioElements = await fetchTTSMessages(newMessages, number_agent_msgs, user.lang_to, user.voice_speed, user.voice_name);
          audioElementsRef.current.push(...audioElements);
          if (user.voice_on) {
            await playTTSMessages(audioElements, setIsSpeechOn, setCurrentTTSMessageIndex, newMessages.length);
          }
        }
        return;
      }
    } else if (messageRole === 'book-translate') {
      if (focusedExerciseIndex >= 0) {
        // console.log('Focused index', focusedExerciseIndex);
        inputRef = inputRefs.current[focusedExerciseIndex];
        userAnswer = inputRef?.value || '';
        const exState = await evaluateExerciseState(userAnswer, (lastMessageExParts[focusedExerciseIndex] as BookExercisePart).solution, "write-mode")
        setExerciseState(exState);
        (lastMessageExParts[focusedExerciseIndex] as BookExercisePart).exerciseState = exState;
        setButtonText(getTextLangFrom('word-continue'));
      } else {
        const index = focusNextExercise(lastMessageExParts);
        if (index !== undefined) {
          inputRef = inputRefs.current[index];
        }
        return;
      }
    } else if (messageRole === 'book-flashcard') {
      setHasFlipped(false);
    }

    const newbookExerciseParts = [...lastMessageExParts];
    const part = newbookExerciseParts[focusedExerciseIndex];
    if (typeof part !== 'string') {
      newbookExerciseParts[focusedExerciseIndex] = { ...part, userAnswer: userAnswer };
    }

    messages[messages.length - 1].bookExerciseParts = newbookExerciseParts;
    setLastMessageExParts(newbookExerciseParts);

    if (messageRole === 'book-translate' && userAnswer !== '') {
      setFocusedExerciseIndex(-1);
    } else {
      focusNextExercise(newbookExerciseParts);
    }

  }, [focusedExerciseIndex, lastMessageExParts, messageRole, messages, focusNextExercise, 
    setMessagesAndScrollDown, setIsSpeechOn, setCurrentTTSMessageIndex, inputRefs, user, 
    navigate, location, getTextLangFrom, audioElementsRef, game_mode]);
  
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (exerciseState !==  "loading") {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (focusedExerciseIndex < 0){
            await handleClick('');
          } else if (messageRole === 'book-flashcard' && !hasFlipped) {
            setHasFlipped(true);
          } else if (messageRole === 'book-translate') {
            await handleClick('');
          }
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          if (messageRole === 'book-flashcard' && !hasFlipped) {
            setHasFlipped(true);
          } else if (messageRole === 'book-flashcard' && hasFlipped) {
            await handleClick('true');
          } else if (messageRole === 'book-discover') {
            await handleClick('true');
          }
        } else if (event.key === 'ArrowLeft') {
          event.preventDefault();
          if (messageRole === 'book-flashcard' && hasFlipped) {
            await handleClick('false');
          } else if (messageRole === 'book-discover') {
            await handleClick('false');
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedExerciseIndex, hasFlipped, messageRole, handleClick, exerciseState]);

  const handleTranslateInputFocus = (index: number) => {
    setExerciseState("unanswered");
    setButtonText(getTextLangFrom('word-check'));
    setFocusedExerciseIndex(index);  
  };

  const handleTranslateInputBlur = () => {
    setButtonText(getTextLangFrom('word-continue'));
  };

  const handleOnMouseUp = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    // console.log('Clicked on', target);
    const selection = window.getSelection();
    const isTextSelected = selection && selection.rangeCount > 0 && selection.toString().length > 0;

    if (isTextSelected || !(target instanceof HTMLSpanElement && target.classList.contains('clickable-span'))) {
      onBookMouseUp(event, setTooltip, user, messages, getTextLangFrom, undefined, undefined);
    } 
  }

  return (
    <>
      <div className="chat-wrapper"  onMouseUp={handleOnMouseUp} >
        <div className="chat-inner-wrapper">
          <div className="chat-scroll-wrapper" ref={messagesContainerRef}>
            <div className="chat-scroll-inner-div">
              {messages.map((message, index) => (
                <MessageContent
                  key={index}
                  index={index}
                  game_mode={game_mode}
                  activeMessage={message}
                  messages={messages}
                  focusedExerciseIndex={focusedExerciseIndex}
                  hasFlipped={hasFlipped}
                  user={user}
                  tooltip={tooltip}
                  setTooltip={setTooltip}
                  currentTTSMessageIndex={currentTTSMessageIndex}
                  messageRole={messageRole}
                  handleTranslateInputFocus={handleTranslateInputFocus}
                  handleTranslateInputBlur={handleTranslateInputBlur}
                  inputRefs={inputRefs}
                />
              ))}
              <TranslationTooltipDiv tooltip={tooltip} learnWordPreferences={learnWordPreferences} setLearnWordPreferences={setLearnWordPreferences} />
            </div>
          </div>
        </div>
      </div>
      {(messageRole === 'book-discover' && !hasFlipped && lastMessageExParts && lastMessageExParts[focusedExerciseIndex]) && (
        <BookDiscoveryDiv
          focusedExerciseIndex={focusedExerciseIndex}
          bookExerciseParts={lastMessageExParts}
        />
      )}
      <BookFooterControls
        bookExerciseParts={lastMessageExParts}
        exerciseState={exerciseState}
        focusedExerciseIndex={focusedExerciseIndex}
        hasFlipped={hasFlipped}
        messageRole={messageRole}
        buttonText={buttonText}
        handleClick={handleClick}
        getTextLangFrom={getTextLangFrom}
        setHasFlipped={setHasFlipped}
      />
    </>
  );
};


export default BookExercise;