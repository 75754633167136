// FlashCardControls.tsx

import React from 'react';
import FooterControls from '../../baseComponents/FooterControls';
import ChevronRight from '../../../assets/chevron-right-white.svg';
import ChevronLeft from '../../../assets/chevron-left-white.svg';
import { useLanguage } from '../../../languages/LanguageContext';
import Button from '../../baseComponents/Button';

interface FlashCardControlsProps {
  hasFlipped: boolean;
  toggleCardFlip: () => void;
  handleCorrectScore: () => void;
  handleIncorrectScore: () => void;
}

const FlashCardControls: React.FC<FlashCardControlsProps> = ({ hasFlipped, toggleCardFlip, handleCorrectScore, handleIncorrectScore }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <FooterControls>
      <div className="footer-controls-action-div">
        {!hasFlipped ? (
          <Button 
            size="control"
            trailingIcon={ChevronRight}
            onClick={toggleCardFlip}
            text={getTextLangFrom('word-show')}
          />
        ) : (
          <>
            <Button 
              size="control"
              variant={"error"}
              leadingIcon={ChevronLeft}
              onClick={handleIncorrectScore}
              text={getTextLangFrom('word-wrong')}
            />
            <Button 
              size="control"
              variant={"success"}
              trailingIcon={ChevronRight}
              onClick={handleCorrectScore}
              text={getTextLangFrom('word-correct')}
            />
          </>
        )}
      </div>
    </FooterControls>
  );
};

export default FlashCardControls;
