// tooltipUtils.tsx
import { TranslationTooltip, Message, User, BookExercisePart } from '../../../../types';
import { getGoogleTranslation } from '../../../../api/Messages';
import { parseTextWithExercises, parseTextWithoutExercises } from '../messageTextParser';
import setTooltipPosition from './tooltipPosition';

// Function to set up tooltip on selection
const showTooltipOnTextSelection = async (
  selection: Selection,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  messages: Message[],
  tooltip: TranslationTooltip,
  user: User,
  getTextLangFrom: (key: string) => string,
) => {
  const selectedText = selection.toString().trim();
  const rect = selection.getRangeAt(0).getBoundingClientRect();
  tooltip = setTooltipPosition(tooltip, rect);

  if (selectedText.length > 300) {
    tooltip.error_message = getTextLangFrom('tooltip-long-translation-error');
    setTooltip({ ...tooltip, isVisible: true });
    return;
  }

  let messageText = "";
  if (selectedText.split(' ').length === 1) {
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    const messageDiv = container.parentElement;
    messageText = messageDiv?.textContent || "";
  }

  selection.removeAllRanges(); // TODO: Don't clear the selection, just select the right text

  tooltip = await setTooltipTranslation(messages, selectedText, messageText, tooltip, setTooltip, user);
};

export default showTooltipOnTextSelection;

// Function to handle the tooltip translation logic
const setTooltipTranslation = async (
  messages: Message[],
  text: string,
  messageText: string,
  tooltip: TranslationTooltip,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  user: User,
): Promise<TranslationTooltip> => {
  for (let i = messages.length - 1; i >= 0; i--) {
    if (isSameMessage(messages[i].text, messageText)) {
      const parts = parseTextWithExercises(messages[i].text);
      for (let j = 0; j < parts.length; j++) {
        const part = parts[j];
        if (typeof part !== 'string' && part.solution === text) {
          tooltip.bookExercise = part as BookExercisePart;
          setTooltip({ ...tooltip, isVisible: true });
          return tooltip;
        }
      }
    }
  }
  setTooltip({ ...tooltip, isVisible: true, isLoading: true });
  const googleTranslation = await getGoogleTranslation(text, user.lang_to, user.lang_from);
  if (googleTranslation) {
    // console.log('googleTranslation', googleTranslation);
    setTooltip({ ...tooltip, googleTranslation: googleTranslation, isLoading: false, isVisible: true });
  } else {
    setTooltip({ ...tooltip, error_message: "Timeout error", isLoading: false, isVisible: true });
  }
  return tooltip;
};

// Utility to check if the selected message is the same
const isSameMessage = (message: string, messageText: string): boolean => {
  const parsedMessage = parseTextWithoutExercises(message);
  const similarity = jaccardSimilarity(new Set(parsedMessage), new Set(messageText));
  return similarity > 0.7;
};

// Jaccard similarity for text comparison
const jaccardSimilarity = (setA: Set<string>, setB: Set<string>): number => {
  const arrayA = Array.from(setA);
  const arrayB = Array.from(setB);

  const intersectionSize = arrayA.filter(x => setB.has(x)).length;
  const unionSize = new Set([...arrayA, ...arrayB]).size;

  return intersectionSize / unionSize;
};