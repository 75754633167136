import { Message, GameState, BookDetails, Chapter } from '../types';
import { apiUrl, fetchData } from './api';


export async function uploadBook(book_file: File, user_id: number): Promise<BookDetails> {
  const formData = new FormData();
  formData.append('file', book_file);
  formData.append('user_id', user_id.toString());
  const response = await fetch(`${apiUrl}/book/upload`, {
    method: 'POST',
    body: formData
  });
  if (!response.ok) {
    throw new Error('Failed to upload file');
  }
  return await response.json();
}

type BookResponse = {
  initial_message_s: Message[];
  game_mode: string;
};

export async function startBook(gameState: GameState): Promise<BookResponse> {
  return await fetchData<BookResponse>(`${apiUrl}/book/start`, 'POST', gameState);
}

export async function getBooks(user_id: number, game_mode: string, limit?: number): Promise<BookDetails[]> {
  return await fetchData<BookDetails[]> (`${apiUrl}/book/list`, 'POST', { user_id, game_mode, limit });
}

export async function canUserResumeBook(user_id: number): Promise<boolean> {
  return await fetchData<boolean>(`${apiUrl}/book/can-resume?user_id=${user_id}`, 'GET');
}

export async function get_number_book_exercise_words(chat_id: number, user_id: number): Promise<{number_new_translations: number, number_chapter_review_translations: number, number_general_review_translations: number}> {
  return await fetchData<{number_new_translations: number, number_chapter_review_translations: number, number_general_review_translations: number}>(`${apiUrl}/book/chapter-exercises`, 'POST', { chat_id, user_id });
}


export async function updateBookDetails(formData: FormData): Promise<void> {
  const response = await fetch(`${apiUrl}/book/update`, {
    method: 'POST',
    body: formData
  });
  if (!response.ok) {
    throw new Error('Failed to upload file');
  }
  return await response.json();
}

export async function deleteBook(book_id: number): Promise<void> {
  return await fetchData<void>(`${apiUrl}/book/delete`, 'POST', { book_id });
}

export async function getChapterParts(book_id: number): Promise<Chapter[]> {
  return await fetchData<Chapter[]>(`${apiUrl}/book/chapter-parts?book_id=${book_id}`, 'GET');
}

export async function getChapterPartMessages(book_id: number, chapter_part_id: number): Promise<String[]> {
  return await fetchData<String[]>(`${apiUrl}/book/chapter-part-messages`, 'POST', { book_id, chapter_part_id });
}

export async function terminateChapter(chat_id: number): Promise<{ accuracy: number, session_duration: string }> {
  return await fetchData< {accuracy: number, session_duration: string} >(`${apiUrl}/book/terminate?chat_id=${chat_id}`, 'GET');
}