import { BookExercisePart } from "../../../types";

const TextDiv = ({ children, isInDatabase, className }: { children: React.ReactNode, isInDatabase: boolean, className?: string }) => (
  <p className={`${isInDatabase ? 'whitespace-nowrap' : 'whitespace-normal'} ${className}`}>
    {children}
  </p>
);

const WordDetails = ({ children, size }: { children: React.ReactNode, size: 'small' | 'large' }) => (
  <div className={`flex flex-wrap text-text-tertiary italic mt-[2.5px] ${size === 'large' ? 'text-base' : 'text-sm'}`}>
    {children}
  </div>
);

const LangFromDetailsDiv = ({ bookExercise, size }: { bookExercise?: BookExercisePart, size: 'small' | 'large' }) => {
  const isInDatabase = bookExercise?.translation_id !== undefined;
  
  return (
    <div className="flex gap-3">
      <TextDiv isInDatabase={isInDatabase} className="text-text-brand font-medium">{bookExercise?.lemma_lang_to}</TextDiv>
      <WordDetails size={size}>
        {bookExercise?.pos_lang_to}
        {bookExercise?.gender_lang_to && <p>, {bookExercise?.gender_lang_to}</p>}
        {bookExercise?.number_lang_to === "plural" && <p>, {bookExercise?.number_lang_to}</p>}
      </WordDetails>
      {bookExercise?.plural_lang_to && (
        <WordDetails size={size}>
          (pl: <TextDiv isInDatabase={isInDatabase} className="text-text-brand font-medium ml-1">{bookExercise?.plural_lang_to}</TextDiv>)
        </WordDetails>
      )}
    </div>
  );
};

const LangToDetailsDiv = ({ bookExercise, googleTranslation, size }: { bookExercise?: BookExercisePart, googleTranslation?: string, size: 'small' | 'large' }) => {
  const isInDatabase = bookExercise?.translation_id !== undefined;
  let primaryTranslation = isInDatabase ? bookExercise.lemma_lang_to : googleTranslation;
  let otherTranslations: string[] = [];

  if (isInDatabase) {
    primaryTranslation = bookExercise.lemma_lang_from;
    otherTranslations = bookExercise.alternative_lemmas_lang_from;
  }

  return (
    <div className="flex gap-3">
      <TextDiv isInDatabase={isInDatabase} className="font-medium">{primaryTranslation}</TextDiv>
      {otherTranslations.length > 0 && (
        <WordDetails size={size}>
          (also:{" "}
          {otherTranslations.map((translation, index) => (
            <span key={index} className="flex">
              {index > 0 && ", "}
              <TextDiv className="ml-[3px]" isInDatabase={isInDatabase}>
                {translation}
              </TextDiv>
            </span>
          ))}
          )
        </WordDetails>
      )}
    </div>
  );
};

interface TranslationsTextInterface {
  bookExercise?: BookExercisePart;
  size: 'small' | 'large';
  googleTranslation?: string;
  error_message?: string;
}

const TranslationsDetailsDiv = ({ bookExercise, size, googleTranslation, error_message }: TranslationsTextInterface) => {
  return (
    <>
      {error_message ? (
        <div className="flex gap-3">
          <TextDiv isInDatabase={false} className="text-text-error">{error_message}</TextDiv>
        </div>
      ) : (
        <div className={`flex flex-col ${size === 'large' ? 'text-lg gap-1.5' : 'gap-0'}`}>
          <LangFromDetailsDiv bookExercise={bookExercise} size={size} />
          <LangToDetailsDiv bookExercise={bookExercise} googleTranslation={googleTranslation} size={size} />
        </div>
      )}
    </>
  );
};

export default TranslationsDetailsDiv;
