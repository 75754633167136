import React from 'react';
import ChevronDown from '../../assets/chevron-down.svg';

interface DropDownProps {
  value: string;
  onChange: (value: string) => void;
  options: string[];
  disabled?: boolean;
}

export const DropDownButton: React.FC<DropDownProps> = ({ value, onChange, options, disabled }) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full">
      <select
        value={value}
        onChange={handleSelectChange}
        disabled={disabled}
        className={`w-full px-4 py-2 pr-12 border rounded-lg font-medium bg-bg-primary text-text-primary border-border-primary shadow-xs 
          focus:border-border-brand focus:outline-none focus:shadow-ring-brand-xs appearance-none
          ${disabled ? 'text-text-disabled cursor-not-allowed' : ''}
        `}
        style={{
          backgroundImage: `url(${ChevronDown})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 14px center',
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

interface DropDownFieldProps {
  value: string;
  onChange: (value: string) => void;
  options: string[];
  disabled?: boolean;
  label?: string;
}

export const DropDownField: React.FC<DropDownFieldProps> = ({ value, onChange, options, disabled, label }) => {
  return (
    <div className="form-div">
      {label && <label className="form-label">{label}</label>}
      <DropDownButton value={value} onChange={onChange} options={options} disabled={disabled} />
    </div>
  );
};


interface DropDownOptionsProps {
  options: string[];
}

export const DropDownOptions: React.FC<DropDownOptionsProps> = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </>
  );
};
