// WriteInput.tsx

import React, { useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { useLanguage } from '../../../languages/LanguageContext';
import { ExerciseState } from '../../../types';
import getStyledGapText from './WriteInputField';
import { Input } from '../../oldBaseComponents/PageStyles';

const InputWrapper = styled.div`
  position: relative;
`;



const AnswerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

const InputTextOverlay = styled.div<{ showAnswerButtons: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  pointer-events: none;

  opacity: ${({ showAnswerButtons }) => (showAnswerButtons ? '1' : '0')};
`;

const AnswerBox = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 54px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const StyledInput = styled(Input)`
  height: 40px;
  border-right: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid ${({ theme }) => theme.border_secondary};
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.background_primary};
  
  &:focus {
    box-shadow: 0 4px 0 -0.4px ${({ theme }) => theme.chat_border_input};
  }

  &::placeholder {
    color: ${({ theme }) => theme.text__text_placeholder_subtle};
    font-weight: 400;
  }

  &:disabled {
    font-weight: 500;
    color: ${({ theme }) => theme.color__foreground_disabled};
    background-color: ${({ theme }) => theme.background_primary};
    border-bottom: 1px solid ${({ theme }) => theme.border_secondary};
    cursor: not-allowed;
  }
`;

const WriteInput: React.FC<{ 
  userResponse: string;
  setUserResponse: React.Dispatch<React.SetStateAction<string>>;
  showAnswerButtons:
  boolean;
  solution: string 
  exerciseState: ExerciseState
  game_mode: string
}> = ({ userResponse, setUserResponse, showAnswerButtons, solution, exerciseState, game_mode }) => {
  const { getTextLangFrom } = useLanguage();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserResponse(event.target.value);
  };

  useEffect(() => {
    if (userResponse === '') {
      inputRef.current?.focus();
    }
  }, [userResponse]);

  return (
    <AnswerDiv>
      <InputWrapper style={{ width: '100%' }}>
        <StyledInput
          ref={inputRef}
          placeholder={getTextLangFrom('WritePage-input-placeholder')}
          value={userResponse}
          onChange={handleInputChange}
          style={showAnswerButtons ? { color: 'transparent' } : {}}
          autoCapitalize="none"
          inputMode="text"
          autoComplete="off"
          autoCorrect="off"
          disabled={showAnswerButtons}
        />
          <InputTextOverlay showAnswerButtons={showAnswerButtons}>{getStyledGapText(userResponse, solution, exerciseState, true)}</InputTextOverlay>
      </InputWrapper>
      {game_mode === 'write-mode' && (
        <AnswerBox isVisible={showAnswerButtons}>
          {getStyledGapText(solution, userResponse, exerciseState)}
        </AnswerBox>
      )}
    </AnswerDiv>
  );
};

export default WriteInput;
