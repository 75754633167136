// FlashCardPage.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchGameCard, initializeGame, terminateGame } from '../../../api/Games';
import { PageDiv, BadgeContainer, TitleWithSupportButton, PageTitle } from '../GameStyles';
import { Card } from '../../../types';
import CardTag from '../CardTag';
import FlashCard from './FlashCard';
import FlashCardControls from './FlashCardControls';
import { useAuth } from '../../../authentification/AuthContext';
import useFlashCardKeyboardControls from './useFlashCardKeyboardControls';
import { useLanguage } from '../../../languages/LanguageContext';
import { NavbarBackAndGameProgress } from '../../baseComponents/ProgressBar';
import { MainLayout } from '../../baseComponents/Layout';

const FlashCardPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [flipped, setFlipped] = useState(false);
  const [hasFlipped, setHasFlipped] = useState(false);
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const isInitialized = useRef(false);
  const location = useLocation();
  const { getTextLangFrom } = useLanguage();

  const isEmptyCard = (card: Card) => Object.keys(card).length === 0;

  useEffect(() => {
    if (!location.state?.gameData) {
      navigate('/exercises');
      return;
    }
  }, [location, navigate]);

  const { game_mode, exercise_type, chat_id } = location.state.gameData;

  useEffect(() => {
    const fetchInitialCard = async () => {
      if (isInitialized.current) return;

      isInitialized.current = true;

      try {
        if (user) {
          const card = await initializeGame<Card>({ user, game_mode, exercise_type, chat_id });
          if (isEmptyCard(card)) {
            navigate('/exercises');
          } else {
            setCurrentCard(card);
          }
        } else {
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching first card:', error);
      }
    };

    fetchInitialCard();
  }, [user, game_mode, navigate, exercise_type, chat_id]);

  const fetchNextCard = useCallback(async (currentCard: Card): Promise<void> => {
    try {
      const card = await fetchGameCard<Card>(game_mode, currentCard);
      if (isEmptyCard(card)) {
        const { accuracy, session_duration } = await terminateGame(currentCard?.session_id || 0, game_mode);
        navigate('/endgame', { state: { gameData: { ...location.state.gameData, accuracy, session_duration } } });
      } else {
        setCurrentCard(card);
      }
    } catch (error) {
      console.error('Error fetching next card:', error);
    }
  }, [game_mode, location.state, navigate]);

  const toggleCardFlip = () => {
    setFlipped(prev => !prev);
    setHasFlipped(true);
  };

  const submitScore = async (score: boolean) => {
    if (currentCard) {
      currentCard.score = score;
      try {
        await fetchNextCard(currentCard);
        setFlipped(false);
        setHasFlipped(false);
      } catch (error) {
        console.error('Error fetching next card:', error);
      }
    }
  };

  const handleCorrectScore = useCallback(() => submitScore(true), [currentCard, fetchNextCard]);
  const handleIncorrectScore = useCallback(() => submitScore(false), [currentCard, fetchNextCard]);

  const handleBack = () => navigate('/exercises');

  // Use the custom hook for keyboard controls
  useFlashCardKeyboardControls(hasFlipped, toggleCardFlip, handleCorrectScore, handleIncorrectScore);

  return (
    <MainLayout hasNavbar={false} hasFooterControls={true}>
      <NavbarBackAndGameProgress 
        session_progress={currentCard?.session_progress ?? 0}
        onBackClick={handleBack}
      />
      <PageDiv>
        <BadgeContainer>
          <CardTag tag={currentCard?.tag ?? 'Default'} />
          <TitleWithSupportButton>
            <PageTitle>{getTextLangFrom('FlashcardPage-title')}</PageTitle>
          </TitleWithSupportButton>
        </BadgeContainer>
        <FlashCard flipped={flipped} toggleFlip={toggleCardFlip} card={currentCard} />
      </PageDiv>
      <FlashCardControls 
        hasFlipped={hasFlipped}
        toggleCardFlip={toggleCardFlip}
        handleCorrectScore={handleCorrectScore}
        handleIncorrectScore={handleIncorrectScore}
      />
    </MainLayout>
  );
};

export default FlashCardPage;
