// types.tsx

export interface Card {
  id: number;
  session_id: number;
  translation_id: number;
  lemma_lang_from: string;
  example_lang_from: string;
  lemma_lang_to: string;
  example_lang_to: string;
  score: boolean;
  session_progress: number;
  tag: string;
  pos?: string;
}

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'menuDesktop' | 'menuMobile';

export const ExerciseStateToButtonVariant = (state: ExerciseState): ButtonVariants => {
  switch (state) {
    case 'correct':
      return 'success';
    case 'almost-correct':
      return 'warning';
    case 'wrong':
      return 'error';
    default:
      return 'primary';
  }
}

export interface Message {
  message_id?: number;
  message_role: string;
  user_id: number;
  agent_id: number;
  chat_id: number;
  text: string;
  session_progress?: number;
  bookExerciseParts?: (string | BookExercisePart)[];
}
export interface UserStatus {
  status: 'first-time-user' | 'active-user' | 'waitlisted' | 'server-error';
  waitlist_position?: number;
}

export interface User {
  id: number;
  username: string;
  lang_from: string;
  lang_to: string;
  cefr_level: string;
  theme: string;
  voice_on: boolean;
  voice_speed: number;
  voice_name: 'Emma' | 'James' | 'Sophia' | 'Michael';
  status: 'first-time-user' | 'active-user' | 'waitlisted' | 'server-error';
  number_streak_days: number;
  last_7d_streak_days: boolean[];
  is_testing?: boolean;
}



export interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  loginWithGoogle: (email: string) => Promise<void>;
  logout: () => void;
  updateUser: (updatedUser: User) => Promise<void>;
  checkUserStatus: (email: string) => Promise<UserStatus>;
}

export interface GameState {
  accuracy?: number;
  session_duration?: string;
  game_mode?: string;
  exercise_type?: string;
  game_url?: string;
  user_id?: number;
  initial_card_s?: Card[];
  initial_message_s?: Message[];
  chat_id?: number;
  NumberNewTranslations?: number;
  NumberReviewTranslations?: number;
  book_id?: number;
  chapter_part_id?: number;
}


export interface BookExercisePart {
  translation_id: number;
  lemma_lang_from: string;
  gender_lang_from: string;
  number_lang_from: string;
  plural_lang_from: string;
  alternative_lemmas_lang_from: string[];
  lemma_lang_to: string;
  pos_lang_to: string;
  gender_lang_to: string;
  number_lang_to: string;
  plural_lang_to: string;
  solution: string;
  userAnswer: string;
  exerciseState: ExerciseState;
  isExercise: boolean;
  has_uku: boolean;
  tag: "no_tag" | "new_word" | "hard_word" | "mastered_word" | "blacklisted_word";
}

export interface TranslationTooltip {
  user_id: number;
  bookExercise: BookExercisePart | undefined;
  googleTranslation: string | undefined;
  isVisible: boolean;
  position: {
    top: number | undefined;
    bottom: number | undefined;
    left: number | undefined;
    right: number | undefined;
  };
  chat_id: number;
  error_message?: string;
  isLoading?: boolean;
}

export type BookDetails = {
  id: number;
  chat_id: number;
  
  title: string;
  author: string;
  last_message_text: string;
  progress: number;
  cover?: string;
  uploader_id?: number;
};


export type ExerciseState = "correct" | "almost-correct" | "wrong" | "unanswered" | "loading";


export type Chapter = {
  chapter_title: string;
  parts: {
    id: number;
    number: number;
    overview: string;
  }[];
};


export interface Word {
  lemma_lang_from: string;
  lemma_lang_to: string;
  last_seen: string;
  score: number;
}

export interface ProfileData {
  number_words_learned: number;
  number_pages_read: number;
  pages_read_per_day: number[];
  words: Word[];
}

export interface OnboardingOption {
  code: string;
  text: string;
  icon?: string;
}

export interface OnboardingData {
  language?: 'de' | 'it';
  cefr_level?: 'A1' | 'A2' | 'B1' | 'B2';
}