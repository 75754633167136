import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import BackIcon from '../../assets/chevron-left-tertiary-24px.svg';
import Button from '../baseComponents/Button';
import { MainLayout } from '../baseComponents/Layout';

interface EndGameLayoutProps {
    title: string;
    isLoading: boolean;
    children: React.ReactNode;
}

const EndGameLayout: React.FC<EndGameLayoutProps> = ({ title, isLoading, children }) => {
    // const navigate = useNavigate();
    return (
        <MainLayout hasNavbar={false}>
            {/* <Button
                variant='tertiary'
                iconOnly={true}
                onClick={() => navigate('/library')}
                leadingIcon={BackIcon}
            /> */}
            <div className="flex flex-col items-center gap-8 max-w-[600px] ml-auto mr-auto">
                <h1 className="text-center text-3xl font-semibold md:text-4xl mb-2">{title}</h1>
                <div className="flex flex-col items-center w-full gap-6">{children}</div>
            </div>
            <LoadingAnimation isLoading={isLoading} />
        </MainLayout>
    );
};

export default EndGameLayout;