import React, { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fireworks from '../baseComponents/FireworksAnimation';
import CelebratingBotImage from '../../assets/robot-celebrating-success.svg';
import AccuracyIcon from '../../assets/accuracy-icon.svg';
import SpeedIcon from '../../assets/speed-icon.svg';
import ChevronRightWhite from '../../assets/chevron-right-white.svg';
import ChevronRightSecondary from '../../assets/chevron-left-tertiary.svg';
import FooterControls from '../baseComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import { handleStartGameClick } from '../baseComponents/startGame';
import { useAuth } from '../../authentification/AuthContext';
import { ReactComponent as BackIcon } from '../../assets/chevron-left-tertiary-24px.svg';
import { startBook } from '../../api/Books';
import { GameState, User } from '../../types';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';


const EndGameStatsPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getTextLangFrom } = useLanguage();
    const { user, updateUser } = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isStreakExtended, setIsStreakExtended] = React.useState<boolean | undefined>(undefined);

    // Check if the location state is valid and contains the game data
    useEffect(() => {
        if (!location.state || !location.state.gameData) {
            navigate('/library');
            return;
        }
    }, [location, navigate]);

    const { game_mode, exercise_type, accuracy, session_duration, chat_id } = location.state.gameData;

    useEffect(() => {
        if ( isStreakExtended === undefined ) {
            const isStreakToBeExtended = user?.last_7d_streak_days[user.last_7d_streak_days.length - 1] === false
            if (  isStreakToBeExtended ) {
                const updatedUser: User = {
                    ...user,
                    last_7d_streak_days: [
                        ...user.last_7d_streak_days.slice(0, -1), // Keep all but the last element
                        true, // Set the last element to true
                    ],
                    number_streak_days: user.number_streak_days + 1,
                };
                updateUser(updatedUser)
                setIsStreakExtended(true);
                console.log("Streak extended");
            } else if ( isStreakToBeExtended === false ) {
                setIsStreakExtended(false);
                console.log("Streak not extended");
            }
        }
    }, [user, updateUser, isStreakExtended]);


    const handleEndOfChapterClick = useCallback(() => {
        if ( isStreakExtended === true ) {
            navigate('/streak-extension', { state: { gameData: location.state.gameData } });
        } else if ( isStreakExtended === false ) {
            navigate('/book-review-exercises', { state: { gameData: location.state.gameData } });
        } else {
            console.error("isStreakExtended is undefined");
        }
    }, [isStreakExtended, location.state.gameData, navigate]);


    const handleNextChapterClick = useCallback(async () => {
        const gameDataForCreateBook: GameState = {
            ...location.state.gameData,
            exercise_type: undefined,
            game_mode: "resume-book",
          };
        
        const bookResponse = await startBook(gameDataForCreateBook)

        if (bookResponse.initial_message_s?.length === 0) {
            console.log("Book finished");
            navigate('/library');
        }

        const updatedGameData = {
            ...gameDataForCreateBook,
            initial_message_s: bookResponse.initial_message_s,
            game_mode: bookResponse.game_mode,
        }
        console.log("updatedGameData", updatedGameData);
        navigate('/book-mode', { state: { gameData: updatedGameData } });
    }, [location.state.gameData, navigate]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'ArrowRight') {
                if (game_mode === "book-chat-mode" || exercise_type === "book-chapter-review") {
                    navigate('/book-review-exercises', { state: { gameData: location.state.gameData } });
                } else if (exercise_type === "book-general-review") {
                    handleEndOfChapterClick();
                } else {
                    handleStartGameClick(game_mode, exercise_type, navigate, user, setIsLoading, chat_id);
                }
            } else if (event.key === 'Escape' || event.key === 'ArrowLeft') {
                navigate('/library');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate, location.state.gameData, game_mode, exercise_type, handleEndOfChapterClick, user, chat_id]);

    return (
        <EndGameLayout
            title={getTextLangFrom('EndChapterPage-title')}
            isLoading={isLoading}
        >
            <Fireworks />
            <img src={CelebratingBotImage} alt="Chatbot" className="w-full max-w-[260px] aspect-square" />
            <div className="mt-6 flex gap-10 justify-center min-w-[400px] w-full">
                <div className="flex flex-col items-center gap-2 bg-bg-brand rounded-2xl p-2 w-full max-w-[200px]">
                    <div className="text-sm font-semibold uppercase">
                        {getTextLangFrom('EndGameStatsPage-accuracy')}
                    </div>
                    <div className="bg-bg-primary flex gap-2 w-full rounded-xl items-center justify-center h-[72px]">
                        <img src={AccuracyIcon} alt="Accuracy Icon" className="w-7 h-7" />
                        <h3 className="text-2xl font-semibold">{accuracy}%</h3>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-2 bg-brand-yellow rounded-2xl p-2 w-full max-w-[200px]">
                    <div className="text-sm font-semibold uppercase">
                        {getTextLangFrom('EndGameStatsPage-speed')}
                    </div>
                    <div className="bg-bg-primary flex gap-2 w-full rounded-xl items-center justify-center h-[72px]">
                        <img src={SpeedIcon} alt="Speed Icon" className="w-7 h-7" />
                        <h3 className="text-2xl font-semibold">{session_duration}</h3>
                    </div>
                </div>
            </div>
            <FooterControls>
                <div className="footer-controls-action-div">
                    { (game_mode === "book-chat-mode" || exercise_type === "book-chapter-review") ? (
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite} 
                            onClick={() => handleEndOfChapterClick()}
                            text={getTextLangFrom('word-next')}
                        />
                    ) : (exercise_type === "book-general-review") ? (
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite}
                            onClick={handleNextChapterClick}
                            text={getTextLangFrom('word-next-chapter')}
                        />
                    ) : (
                    <>
                        <Button
                            size="control"
                            leadingIcon={ChevronRightSecondary}
                            onClick={() => navigate('/library')}
                            text={getTextLangFrom('word-exit-game')}
                        />
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite}
                            onClick={() => handleStartGameClick(game_mode, exercise_type, navigate, user, setIsLoading, chat_id)}
                            text={getTextLangFrom('word-play-again')}
                        />
                    </>
                    )}
                </div>
            </FooterControls>
        </EndGameLayout>
    );
};

export default EndGameStatsPage;