import React from 'react';
import { GameCard } from './ExerciseCard';
import { useLanguage } from '../../languages/LanguageContext';
import { MainLayout } from '../baseComponents/Layout';
import flashcardImage from '../../assets/home-flashcard.svg';
import writeImage from '../../assets/home-write.svg';
import chatImage from '../../assets/home-chat.svg';
import multipleChoiceImage from '../../assets/home-multiple-choice.svg';
import { useAuth } from '../../authentification/AuthContext';

const ExercisePage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const { user } = useAuth();

  return (
    <MainLayout 
      hasNavbar={true}
      title={getTextLangFrom('word-exercises')}
    >
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-10">
        <GameCard title={getTextLangFrom('word-multiple-choice')} image={multipleChoiceImage} game_mode="multiple-choice-mode" exercise_type="vocabulary" />
        <GameCard title={getTextLangFrom('word-flashcard')} image={flashcardImage} game_mode="flashcard-mode" exercise_type="vocabulary" />
        <GameCard title={getTextLangFrom('word-write')} image={writeImage} game_mode="write-mode" exercise_type="vocabulary" />
        {user?.is_testing && <GameCard title={getTextLangFrom('word-chat')} image={chatImage} game_mode="chat-mode" />}
      </div>
    </MainLayout>
  );
};

export default ExercisePage;