// api.ts

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL: 'http://localhost:8000';
console.log('Node environment:', process.env.NODE_ENV);

async function handleError(error: unknown) {
  if (error instanceof Error) {
    // throw new Error(`Failed to ${error.message}`);
    console.error(`Error: ${error.message}`);
    return `Failed to ${error.message}`;
  } else {
    // throw new Error('Unknown error occurred');
    console.error('Unknown error occurred');
    return 'Unknown error occurred';
  }
}

async function fetchData<T>(url: string, method: string, body?: any): Promise<T> {
  try {
    const response = await fetch(url, { method, headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
    if (!response.ok) {
      throw new Error(`Failed to ${method} data: ${await response.text()}`);
    }
    return response.json() as T;
  } catch (error) {
    throw handleError(error);
  }
}

export { apiUrl, handleError, fetchData };