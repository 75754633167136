import React, { useEffect, useState } from 'react';
import { useAuth } from '../../authentification/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../languages/LanguageContext';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import GoogleIcon from '../../assets/google_icon.svg';
import LoadingAnimation from '../baseComponents/LoadingAnimation';


const WEB_CLIENT_ID = '511189040549-h2vv0g9vc4hmkie5l3s9ntbkd2jgmpep.apps.googleusercontent.com';
const IOS_CLIENT_ID = '511189040549-kvdon0ra5bks286q30mbtjlb8gfj8hcn.apps.googleusercontent.com';
const ANDROID_CLIENT_ID = '445403036319-ss3lm3ctck6rqo0qb332s77d5lrv2ilp.apps.googleusercontent.com';

// Function to check connectivity to Google
const isClientIdValid = async (clientId: string = WEB_CLIENT_ID) => {
  const url = `https://oauth2.googleapis.com/tokeninfo?client_id=${clientId}`;

  try {
    const response = await fetch(url);
    
    // Check if the response is okay (status code 200)
    if (response.ok) {
      const data = await response.json();
      // console.log('Client ID is valid:', data);
      return true;
    } else {
      // console.log('Invalid Client ID:', response.status, response.statusText);
      return false;
    }
  } catch (error) {
    console.error('Error checking Client ID:', error);
    return false;
  }
};

export const getDefaultClientId = () => {
  return WEB_CLIENT_ID;
}

// Function to determine client ID based on environment and connectivity
export const getClientId = async () => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    // console.log('User Agent:', userAgent);

    // Forcing a client ID for testing purposes
    if (process.env.REACT_APP_FORCE_CLIENT_ID) {
      return process.env.REACT_APP_FORCE_CLIENT_ID;
    }

    let clientId;

    if (/android/i.test(userAgent)) {
      console.log('Android detected');
      clientId = ANDROID_CLIENT_ID;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      console.log('iOS detected');
      clientId = IOS_CLIENT_ID;
    } else {
      console.log('Web detected');
      clientId = WEB_CLIENT_ID;
    }

      // Check for connectivity to Google
      const isValid = await isClientIdValid();
      if (!isValid) {
        console.log('Cannot connect to Google, defaulting to Web client ID');
        return getDefaultClientId();
      } else {
        return clientId
      }
  }
  console.log('No window object, defaulting to Web');
  return getDefaultClientId();
};


const GoogleLoginButton: React.FC<{ setError: (error: string) => void }> = ({ setError }) => {
  const { loginWithGoogle, checkUserStatus } = useAuth();
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();
  const [clientId, setClientId] = useState(getDefaultClientId()); // Default to web client ID
  const [isLoading, setIsLoading] = React.useState(false);
  
  useEffect(() => {
    const fetchClientId = async () => {
      const id = await getClientId();
      setClientId(id);
      
      // Initialize GoogleAuth with fetched clientId
      GoogleAuth.initialize({
        clientId: id,
        scopes: ['email'],
        grantOfflineAccess: true,
      });
    };
  
    fetchClientId();
  }, []);
  
  GoogleAuth.initialize({
    clientId: clientId,
    scopes: ['email'],
    grantOfflineAccess: true,
  });

  const handleClick = async () => {  
    try { 
      // console.info("Sign in with Google")
      const googleUser = await GoogleAuth.signIn();
      setIsLoading(true);
      //console.log('Google User:', googleUser);
      const email = googleUser.email.toLowerCase();
  
      if (!email) {
        console.error('No email found in id_token');
        setError(getTextLangFrom('LoginPage-login-failed'));
        return;
      }
  
      const userStatus = await checkUserStatus(email);
      setIsLoading(false);
  
      if (userStatus.status === 'first-time-user' || userStatus.status ==='active-user') {
        await loginWithGoogle(email);
        if (userStatus.status === 'first-time-user') {
          navigate('/onboarding-welcome');
        } else {
          navigate('/library');
        }
      } else if (userStatus.status === 'waitlisted') {
        const waitlistPosition = userStatus.waitlist_position;
        navigate('/waitlist', { state: { waitlistPosition } });
      } else {
        console.error('Server error');
        setError(getTextLangFrom('LoginPage-server-error'));
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError(getTextLangFrom('LoginPage-server-error'));
    }
  };
  

  return (
    <button
      className={`border border-buttonTertiary-border box-shadow-xs flex items-center justify-center w-full 
        md:w-auto bg-buttonSecondary-bg text-buttonSecondary-fg hover:bg-buttonSecondary-bgHover 
        hover:border-buttonSecondary-borderHover hover:text-buttonSecondary-fgHover rounded-lg px-4 py-2`}
      onClick={handleClick}
    >
      <img src={GoogleIcon} alt="Google" className='h-8 w-8' />
      {getTextLangFrom('LoginPage-google-login')}
      <LoadingAnimation isLoading={isLoading} />
    </button>
  );
}

export default GoogleLoginButton;