// GameHeader.tsx

import React from 'react';
import { BadgeContainer, PageTitle, TitleWithSupportButton} from './GameStyles';
import CardTag from './CardTag';
import styled from 'styled-components';
import { useLanguage } from '../../languages/LanguageContext';
import AvatarWriteImage from '../../assets/robot-writing.svg';
import { Card, User } from '../../types';
import BubbleNoseDark from '../../assets/bubble-nose-dark.svg';
import BubbleNoseLight from '../../assets/bubble-nose-light.svg';


const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const AvatarImageDiv = styled.div`
  height: min(200px, 15vh, 20vw);
  width: min(200px, 15vh, 20vw);
`;

const InputBubble = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuestionDiv = styled.div`
  display: flex;
  min-height: 36px;
  padding-top: 24px;
`;

const BubbleNoseImage = styled.div<{ theme: string | undefined }>`
  width: 51px;
  height: 90px;
  margin-right: -1px;
  z-index: 1;
  background-image: ${({ theme }) => theme === 'dark' ? `url(${BubbleNoseDark})` : `url(${BubbleNoseLight})`};

  @media (max-width: 768px) or (max-height: 800px) {
    display: none;
  }
`;  

const BubbleTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.background_secondary};
  padding: 24px 30px;
  box-shadow: ${({ theme }) => theme.shadow_md};
  border: 1px solid ${({ theme }) => theme.border_tertiary};
  margin-bottom: 16px;
  min-width: 200px;
  gap: 8px;

  transition: width 0.3s ease;

  @media (max-width: 768px) or (max-height: 800px) {
    min-height: 0;
    margin-left: 4px;
    border-radius: 12px 12px 12px 2px;
    padding: 16px 20px;
    min-width: 0;
  }
`;

interface HeaderWithBubbleProps {
    card?: Card;
    user?: User;
}
  
const HeaderWithBubble: React.FC<HeaderWithBubbleProps> = ({ card, user }) => {
    const { getTextLangFrom } = useLanguage();
  
    return (
      <HeaderWrapper>
        <BadgeContainer>
          <CardTag tag={card?.tag ?? 'Default'} />
          <TitleWithSupportButton>
            <PageTitle>{getTextLangFrom('WritePage-title')}</PageTitle>
          </TitleWithSupportButton>
        </BadgeContainer>
        <QuestionDiv>
          <AvatarImageDiv>
            <img src={AvatarWriteImage} alt='Avatar' />
          </AvatarImageDiv>
          <InputBubble>
            <BubbleNoseImage theme={user?.theme} />
            <BubbleTextDiv>
              <div className="translation-solution-div">
                <div className="translation-solution-text">{card?.lemma_lang_from}</div>
                <div className="translation-details-text">{card?.pos}</div>
              </div>
              { card?.example_lang_from && <div className="translation-details-text">{card.example_lang_from}</div> }
            </BubbleTextDiv>
          </InputBubble>
        </QuestionDiv>
      </HeaderWrapper>
    );
};

export default HeaderWithBubble;