import axios from 'axios';

interface LanguageInfo {
  languageCode: string;
}

function transformLangToCode(language: string, provider: string): LanguageInfo {
  let languageCode = '';
  if (provider === 'google') {
    switch (language) {
      case 'en':
        languageCode = 'en-US';
        break;
      case 'de':
        languageCode = 'de-DE';
        break;
      case 'es':
        languageCode = 'es-ES';
        break;
      case 'fr':
        languageCode = 'fr-FR';
        break;
      case 'it':
        languageCode = 'it-IT';
        break;
      case 'pl':
        languageCode = 'pl-PL';
        break;
      default:
        languageCode = 'en-US';
        break;
    }
  } else {
    languageCode = 'en-US';
  }
  return { languageCode };
}

// Function to transcribe audio to text using Google Cloud Speech-to-Text API
export const transcribeAudio = async (audioBlob: Blob, language: string): Promise<string> => {
  const apiEndpoint = `https://speech.googleapis.com/v1/speech:recognize?key=AIzaSyA16MJpH22XVp_hgUFbTTiTbTCOJbgXtnc`;
  const { languageCode } = transformLangToCode(language, 'google');  

  try {
    // Convert audio data to base64
    const base64audio = await audioDataToBase64(audioBlob);

    // Send request to Google Cloud Speech-to-Text API
    const response = await axios.post(apiEndpoint, {
      audio: {
        content: base64audio,
      },
      config: {
        languageCode: languageCode,
      },
    });

    // Check if results are present and non-empty
    if (response.data.results && response.data.results.length > 0) {
      // Parse transcription from API response
      const transcription = response.data.results
        .map((result: any) => result.alternatives[0].transcript)
        .join('\n');

      return transcription;
    } else {
      console.error('Empty or undefined response from API:', response.data);
      throw new Error('Empty or undefined response from API');
    }
  } catch (error) {
    console.error('Error transcribing audio:', error);
    throw new Error('Error transcribing audio');
  }
};

// Function to convert audio data to base64 format
const audioDataToBase64 = (audioData: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result?.toString().split(',')[1] || '';
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(audioData);
  });
};
