import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../../authentification/AuthContext';
import { User } from '../../types';
import { useTheme } from '../../styles/ThemeContext';
import { lightTheme, darkTheme } from '../../styles/themes';
import { useLanguage } from '../../languages/LanguageContext';
import Toast from '../baseComponents/Toast';
import { MainLayout } from '../baseComponents/Layout';
import InputField from '../baseComponents/InputField';
import { Divider } from '../baseComponents/Divider';
import Button from '../baseComponents/Button';
import { DropDownField } from '../baseComponents/DropDown';
import Checkbox from '../baseComponents/Checkbox';

const SettingsPage: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { setAppTheme } = useTheme();
  const { getTextLangFrom } = useLanguage();
  const [username, setUsername] = useState<string>(user ? user.username : '');
  const cefr_levels = ["A1", "A2", "B1", "B2", "C1", "C2"]; // ["A1", "A2", "B1", "B2", "C1", "C2"]
  const languagesFrom = useMemo(() => ["en"], []); // ["de", "en", "it"] [user ? user.lang_from : 'en']
  const languagesTo = useMemo(() => ["de", "it"], []); // [user ? user.lang_to : 'de']
  const themes = useMemo(() => ['light', 'dark'], []);
  const [langFrom, setLangFrom] = useState<string>(user ? user.lang_from : 'en');
  const [langTo, setLangTo] = useState<string>(user ? user.lang_to : 'de');
  const [theme, setTheme] = useState<string>(user ? user.theme : 'light');
  const [cefr_level, setCEFRLevel] = useState<string>(user ? user.cefr_level : 'A1');
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isTesting, setIsTesting] = useState<boolean | undefined>(user ? user.is_testing : false);

  // Function to map display text to language ID
  const mapTextToId = useCallback((text: string) => {
    const textToIdMap: Record<string, string> = {};
    languagesFrom.forEach(lang => {
      textToIdMap[getTextLangFrom(`language-${lang}`)] = lang;
    });
    languagesTo.forEach(lang => {
      textToIdMap[getTextLangFrom(`language-${lang}`)] = lang;
    });
    themes.forEach(th => {
      textToIdMap[getTextLangFrom(`theme-${th}`)] = th;
    });
    return textToIdMap[text];
  }, [languagesFrom, languagesTo, themes, getTextLangFrom]);

  const handleSave = useCallback(() => {
    if (user) {
      const updatedUser: User = {
        ...user,
        username: username,
        cefr_level: cefr_level,
        lang_from: langFrom,
        lang_to: langTo,
        theme: theme,
        is_testing: isTesting,
      };
      console.log('Updated user properties:', updatedUser);

      updateUser(updatedUser)
        .then(() => {
          setAppTheme(updatedUser.theme === 'light' ? lightTheme : darkTheme);
          setIsChanged(false);
          setShowToast(true);
        })
        .catch((error: any) => {
          console.error('Failed to update user:', error);
        });
    }
  }, [user, username, cefr_level, langFrom, langTo, theme, updateUser, setAppTheme, isTesting]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isChanged) {
        event.preventDefault();
        handleSave();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSave, isChanged]);

  const handleCancel = () => {
    // Reset form fields with original user data
    if (user) {
      setUsername(user.username);
      setCEFRLevel(user.cefr_level);
      setLangFrom(user.lang_from);
      setLangTo(user.lang_to);
      setTheme(user.theme);
      setIsTesting(user.is_testing);
      setIsChanged(false); // Reset the change tracking state
    }
  };

  const handleChange = () => {
    setIsChanged(true); // Set the change tracking state to true
  };

  return (
    <MainLayout hasNavbar={true} title={getTextLangFrom('word-settings')} hideStreak={true}>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between gap-4 flex-col md:flex-row">
          <div className="w-full">
            <h2 className="text-lg font-semibold">{getTextLangFrom('SettingsPage-personal-info')}</h2>
            <p className="text-sm text-gray-500">{getTextLangFrom('SettingsPage-supporting-text')}</p>
          </div>
          <div className="flex md:hidden gap-5">
            <Button
              variant="secondary"
              onClick={handleCancel}
              disabled={!isChanged}
              text={getTextLangFrom('word-cancel')}
            />
            <Button
              onClick={handleSave}
              disabled={!isChanged}
              text={getTextLangFrom('word-save')}
            />
          </div>
        </div>
        <Divider />
      <form className="flex flex-col gap-5">
        <InputField
          label={getTextLangFrom('word-name')}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            handleChange();
          }}
        />
        <Divider />
        <DropDownField
          value={getTextLangFrom(`language-${langTo}`)}
          onChange={(value) => {
            setLangTo(mapTextToId(value));
            handleChange();
          }}
          options={languagesTo.map(lang => getTextLangFrom(`language-${lang}`))}
          label={getTextLangFrom('SettingsPage-language-to')}
        />
        <DropDownField
          value={cefr_level}
          onChange={(value) => {
            setCEFRLevel(value);
            handleChange();
          }}
          options={cefr_levels}
          label={getTextLangFrom('SettingsPage-language-level')}
        />
        
        {user?.is_testing === true && (
          <>
            <Divider />
            <DropDownField
              value={getTextLangFrom(`language-${langFrom}`)}
              onChange={(value) => {
                setLangFrom(mapTextToId(value));
                handleChange();
              }}
              options={languagesFrom.map(lang => getTextLangFrom(`language-${lang}`))}
              label={getTextLangFrom('SettingsPage-language-from')}
            />
          </>
        )}
        {user?.is_testing === true && (
          <DropDownField
            value={getTextLangFrom(`theme-${theme}`)}
            onChange={(value) => {
              setTheme(mapTextToId(value));
              handleChange();
            }}
            options={themes.map(th => getTextLangFrom(`theme-${th}`))}
            label={getTextLangFrom('word-theme')}
          />
          
        )}
        <Divider />
        {user?.is_testing !== undefined && (
          <div className="flex gap-3">
            <Checkbox
              size="large"
              checked={isTesting || false}
              onChange={() => {
                handleChange();
                setIsTesting(!isTesting);
              }}
            />
            <label className="text-sm">{getTextLangFrom('SettingsPage-is-testing')}</label>
          </div>
        )}
      </form>
      <Divider />
        <div className="flex gap-5 justify-start md:justify-end">
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={!isChanged}
            text={getTextLangFrom('word-cancel')}
          />
          <Button
            onClick={handleSave}
            disabled={!isChanged}
            text={getTextLangFrom('word-save')}
          />
        </div>
      </div>
      <Toast
        title={getTextLangFrom('SettingsPage-toast-title')}
        subtitle={getTextLangFrom('SettingsPage-toast-message')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </MainLayout>
  );
};

export default SettingsPage;
