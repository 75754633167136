// src/components/LibraryPage.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { BookDetails, Chapter } from '../../types';
import { useNavigate } from 'react-router-dom';
import EmptyState from '../baseComponents/EmptyState';
import { getBooks, deleteBook, getChapterParts } from '../../api/Books';
import { useLanguage } from '../../languages/LanguageContext';
import { useAuth } from '../../authentification/AuthContext';
import HorizontalMenu from '../baseComponents/HorizontalMenu';
import { MainLayout } from '../baseComponents/Layout';
import SearchBar from '../baseComponents/SearchBar';
import BookCard from './BookCard';
import BookNavigation from './BookNavigation';
import Button from '../baseComponents/Button';
import UploadIcon from '../../assets/upload-01.svg';


const LibraryPage: React.FC = () => {
  const navigate = useNavigate();
  const [books, setBooks] = useState<BookDetails[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const [isNoBooks, setIsNoBooks] = useState<boolean | null>(null);
  const { user } = useAuth();
  const [gameMode, setGameMode] = useState<string>('resume-book');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedBookId, setSelectedBookId] = useState<number>(-1);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [chapters, setChapters] = useState<Chapter[]>([]);

  useEffect(() => {
    if (books && books.length === 0) {
      setIsNoBooks(true);
    } else {
      setIsNoBooks(false);
    }
  }, [books]);

  useEffect(() => {
    if (selectedOption && books) {
      const bookDetails = books.find(book => book.id === selectedBookId);
      if (selectedOption === getTextLangFrom('word-edit')) {
        navigate('/book-edit', { state: { bookDetails } });
      } else if (selectedOption === getTextLangFrom('word-delete')) {
        const updatedBooks = books.filter(book => book.id !== selectedBookId);
        deleteBook(selectedBookId);
        setBooks(updatedBooks);
      } else if (selectedOption === getTextLangFrom('word-navigate')) {
        getChapterParts(selectedBookId).then((chapters) => {
          setChapters(chapters);
        });
        setShowOverlay(true);
      }
      setSelectedOption('');
    }
  }, [selectedOption, selectedBookId, books, navigate, getTextLangFrom]);

  const fetchBooks = useCallback(async (gameMode: string) => {
    try {
      if (!user) {
        return;
      }
      const newBooks = await getBooks(user.id, gameMode);
      setBooks(newBooks);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/library');
    }
    fetchBooks(gameMode);
  }, [user, navigate, gameMode, fetchBooks]);

  const handleTabSwitch = (gameMode: string) => {
    setGameMode(gameMode);
    fetchBooks(gameMode);
  };

  const filteredBooks = books?.filter(book =>
    book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    book.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const tabs = [
    { text: getTextLangFrom('word-my-books'), handleClick: () => handleTabSwitch("resume-book") },
    { text: getTextLangFrom('LibraryPage-bookly-library'), handleClick: () => handleTabSwitch("start-new-book") },
  ];

  return (
    <MainLayout 
      hasNavbar={true}
      title={getTextLangFrom('word-library')}
    >
      <HorizontalMenu tabs={tabs} />
      {isNoBooks === true ? (
        <EmptyState 
          title={getTextLangFrom('BookResume-no-book-title')} 
          subtitle={getTextLangFrom('BookResume-no-book-subtitle')}
          callToAction={getTextLangFrom('BookResume-no-book-cta')}
          handleCallToAction={() => navigate('/book-upload')}
        />
      ) : (
        <SearchBar
          filter={searchQuery}
          setFilter={setSearchQuery}
          placeholder={getTextLangFrom('LibraryPage-search-placeholder')}
          className="w-full"
        />
      )}
      {filteredBooks?.map((book) => (
        <BookCard
          key={book.id}
          book={book}
          gameMode={gameMode}
          user={user}
          setIsLoading={setIsLoading}
          setSelectedOption={setSelectedOption}
          setSelectedBookId={setSelectedBookId}
        />
      ))}
      {showOverlay && (
        <BookNavigation
          book={books?.find(book => book.id === selectedBookId)}
          chapters={chapters}
          onClose={() => setShowOverlay(false)}
        />
      )}
      {isNoBooks === false &&
        <Button 
          className="absolute bottom-8 right-0"
          variant="primary"
          leadingIcon={UploadIcon}
          onClick={() => navigate('/book-upload')}
          text={getTextLangFrom('word-upload')}
        />
      }
    </MainLayout>
  );
};

export default LibraryPage;
