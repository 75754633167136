import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { DefaultTheme } from 'styled-components';
import { lightTheme, darkTheme } from './themes'; // Adjust path as per your structure

interface ThemeContextType {
  theme: DefaultTheme;
  toggleTheme: () => void;
  setTheme: (theme: DefaultTheme) => void;
  setAppTheme: (theme: DefaultTheme) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
  defaultTheme: DefaultTheme;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, defaultTheme }) => {
  const [theme, setTheme] = useState<DefaultTheme>(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme === 'Dark' ? darkTheme : lightTheme;
  });

  const toggleTheme = () => {
    setAppTheme(theme === darkTheme ? lightTheme : darkTheme);
  }

  const setAppTheme = (theme: DefaultTheme) => {
    const newTheme = theme === darkTheme ? darkTheme : lightTheme;
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme === darkTheme ? 'dark' : 'light');

    const root = window.document.documentElement;
    if (newTheme === darkTheme) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  };

  useEffect(() => {
    setAppTheme(defaultTheme);
  }, [defaultTheme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, setTheme, setAppTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
