import React, { useEffect, useState } from 'react';
import './styles/App.css';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from './styles/ThemeContext';
import { GlobalStyles } from './styles/globalStyles';
import { useTheme } from './styles/ThemeContext';
import { LanguageProvider } from './languages/LanguageContext';
import { lightTheme, darkTheme } from './styles/themes';
import ExercisePage from './components/exercisePage/ExercisePage';
import FlashCardPage from './components/gamePages/FlashCardPage/FlashCardPage';
import MultiplechoicePage from './components/gamePages/MultipleChiocePage/MultipleChoicePage';
import ProfilePage from './components/profilePage/ProfilePage';
import WritePage from './components/gamePages/WritePage/WritePage';
import ChatPage from './components/chatPage/ChatPage';
import LoginPage from './components/loginPage/LoginPage';
import WaitlistPage from './components/loginPage/WaitlistPage';
import EndGameStatsPage from './components/endGamePages/EndGameStatsPage';
import EndChapterPage from './components/endGamePages/EndChapterPage';
import NotFoundPage from './components/notFoundPage/NotFoundPage';
import BookUploadPage from './components/libraryPages/BookUploadPage';
import EndGameStreakPage from './components/endGamePages/EndGameStreakPage';
import OnboardingWelcome from './components/onboarding/OnboardingWelcome';
import OnboardingLanguage from './components/onboarding/OnboardingLanguage';
import OnboardingLevel from './components/onboarding/OnboardingLevel';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './authentification/AuthContext';
import PrivateRoute from './authentification/PrivateRoute';
import * as Sentry from "@sentry/react";
import LibraryPage from './components/libraryPages/LibraryPage';
import SettingsPage from './components/settingsPage/SettingsPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getClientId, getDefaultClientId } from './components/loginPage/GoogleLoginButton';
import BookEditPage from './components/libraryPages/BookEditPage';
import './globals.css';


const AppContent: React.FC = () => {
  const { theme } = useTheme();
  const [themeClass, setThemeClass] = useState<string>('light');

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      <div className={`app ${themeClass}`}>
        <Routes>
          <Route path="/" element={<Navigate to="/library" />} />
          {/* <Route path="/" element={<HomePage />} /> */}

          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<LoginPage />} />
          <Route path="/waitlist" element={<WaitlistPage />} />
          <Route element={<PrivateRoute />}>
            <Route path='/library' element={<LibraryPage />} />
            <Route path='/onboarding-welcome' element={<OnboardingWelcome />} />
            <Route path='/onboarding-language' element={<OnboardingLanguage />} />
            <Route path='/onboarding-level' element={<OnboardingLevel />} />
            <Route path="/exercises" element={<ExercisePage />} />
            <Route path="/flashcard-mode" element={<FlashCardPage />} />
            <Route path="/multiple-choice-mode" element={<MultiplechoicePage />} />
            <Route path="/write-mode" element={<WritePage />} />
            <Route path="/chat-mode" element={<ChatPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/endgame" element={<EndGameStatsPage />} />
            <Route path="/streak-extension" element={<EndGameStreakPage />} />
            <Route path="/book-review-exercises" element={<EndChapterPage />} />
            <Route path="/book-mode" element={<ChatPage />} />
            <Route path="/book-upload" element={<BookUploadPage />} />
            <Route path="/book-edit" element={<BookEditPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </StyledThemeProvider>
  );
};

const AppTheme: React.FC = () => {
  const { user } = useAuth();
  const defaultTheme = user?.theme === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider defaultTheme={defaultTheme}>
      <AppContent />
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  const [clientId, setClientId] = useState(getDefaultClientId());

  useEffect(() => {
    const fetchClientId = async () => {
      const id = await getClientId();
      setClientId(id);
    };

    fetchClientId();
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <Router>
          <LanguageProvider>
            <AppTheme />
          </LanguageProvider>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
