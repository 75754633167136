import { Card, ExerciseState } from '../types';
import { apiUrl, fetchData } from './api';
import { User } from '../types';

export async function initializeGame<T>(gameData: { user: User; game_mode: string, exercise_type: string, chat_id?: number }): Promise<T> {
  return await fetchData<T>(`${apiUrl}/game/init`, 'POST', gameData);
}

export async function fetchGameCard<T>(game_mode: string, currentCard: Card): Promise<T> {
  return await fetchData<T>(`${apiUrl}/game/cards?game_mode=${game_mode}`, 'POST', currentCard);
}

export async function terminateGame(session_id: number, game_mode: string): Promise<{ accuracy: number, session_duration: string }> {
  return await fetchData< {accuracy: number, session_duration: string} >(`${apiUrl}/game/terminate`, 'POST', { session_id, game_mode });
}

export async function evaluateExerciseState(userAnswer: string, solution?: string, gameMode?: string): Promise<ExerciseState> {

  if (userAnswer.trim() === solution) {
    return "correct";
  } else if (!gameMode || gameMode !== "write-mode") {
    return "wrong";
  } else {
      // TODO: consider evaluating the exercise state on the client side
      const exerciseState = await fetchData<ExerciseState>(`${apiUrl}/game/write/evaluate`, 'POST', { userAnswer, solution });
      return exerciseState;
    // }
  }

}