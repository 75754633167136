import React from 'react';
import EmptyStateImage from '../../assets/empty-state-cloud.svg';
import Button from './Button';

interface EmptyStateItems {
  title: string;
  subtitle: string;
  callToAction: string;
  handleCallToAction: () => void;
}

const EmptyState: React.FC<EmptyStateItems> = ({ title, subtitle, callToAction, handleCallToAction }) => {
  return (
    <div className="flex flex-col items-center gap-4 py-10 text-center">
      <img src={EmptyStateImage} alt="Empty State" />
      <div className="flex flex-col gap-1 mb-2 max-w-[240px]">
        <h2 className="text-lg font-semibold text-text-primary">{title}</h2>
        <p className="text-sm text-text-tertiary">{subtitle}</p>
      </div>
      <Button onClick={() => handleCallToAction()} text={callToAction} />
    </div>
  );
};

export default EmptyState;
