import React from 'react';
import { Card } from '../../../types';

interface FlashCardProps {
  flipped: boolean;
  toggleFlip: () => void;
  card: Card | null;
}

const FlashCard: React.FC<FlashCardProps> = ({ flipped, toggleFlip, card }) => {
  return (
    <div 
      className="relative h-80 transition-transform duration-600 cursor-pointer"
      onClick={toggleFlip}
    >
      <div 
        className="flashcard"
        style={{ backfaceVisibility: 'hidden', transition: 'transform 0.6s',
           transform: flipped ? 'rotateX(-180deg)' : 'rotateX(0)', transitionDuration: 'var(--transition-duration, 0.6s)' }}
      >
        <div className="flashcard-text-div">
          <div className="translation-solution-div">
            <div className="translation-solution-text-large">{card?.lemma_lang_from}</div>
            <div className="translation-details-text">{card?.pos}</div>
          </div>
          <div className="translation-details-text">{card?.example_lang_from}</div>
        </div>
      </div>
      <div 
        className="flashcard" 
        style={{ backfaceVisibility: 'hidden', transition: 'transform 0.6s',
          transform: flipped ? 'rotateX(0)' : 'rotateX(180deg)', transitionDuration: 'var(--transition-duration, 0.6s)' }}
      >
        <div className="flashcard-text-div">
          <div className="translation-solution-div">
          <div className="translation-solution-text-large">{card?.lemma_lang_to}</div>
            <div className="translation-details-text">{card?.pos}</div>
          </div>
          <div className="translation-details-text">{card?.example_lang_to}</div>
        </div>
      </div>
    </div>
  );
};

export default FlashCard;
