import React from 'react';
import CloseIcon from '../../assets/x-close-tertiary-24px.svg';
import ModalPatternImg from '../../assets/modal-pattern.svg';
import SaveIcon from '../../assets/save-01-orange.svg';
import Button from './Button';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  message?: string;
  children?: React.ReactNode;
  size?: 'small' | 'large';
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  message,
  children,
  size = 'small',
}) => {
  if (!isOpen) return null;

  return (
    <div className="overlay-page">
      <div
        className={`relative bg-bg-primary p-6 rounded-xl shadow-xl w-full text-left ${
          size === 'small' ? 'max-w-md' : 'max-w-lg'
        }`}
      >
        {/* Modal background pattern */}
        <img
          src={ModalPatternImg}
          alt="Modal pattern"
          className="absolute top-0 left-0 object-cover w-52 h-52 pointer-events-none opacity-10"
        />

        {/* Modal Header */}
        <div className="flex justify-between mb-4">
          <div className="w-11 h-11 bg-orange-50 rounded-full flex justify-center items-center">
            <img src={SaveIcon} alt="Save icon" className="w-6 h-6" />
          </div>
          <Button variant="tertiary" iconOnly={true} onClick={() => setIsOpen(false)} leadingIcon={CloseIcon} />
        </div>

        {/* Modal Body */}
        <div className="flex flex-col gap-1">
          <h2 className="text-lg font-semibold">{title}</h2>
          {message && <p className="text-sm text-text-tertiary">{message}</p>}
        </div>

        {children && <div className="py-4">{children}</div>}

        {/* Modal Footer */}
        <div className="mt-6 flex gap-3 justify-end">
          <Button variant="secondary" onClick={onCancel} text={cancelText} />
          <Button onClick={onConfirm} text={confirmText} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
