// FooterControls.tsx
import React, { useEffect } from 'react';
import FooterControls from '../../baseComponents/FooterControls';
import { BookExercisePart, ExerciseState, ExerciseStateToButtonVariant } from '../../../types';
import ChevronRight from '../../../assets/chevron-right-white.svg';
import ChevronLeft from '../../../assets/chevron-left-white.svg';
import { ResultsDisplay } from '../../gamePages/FooterExerciseInfo';
import Button from '../../baseComponents/Button';

interface FooterControlsProps {
  bookExerciseParts: (string | BookExercisePart)[];
  exerciseState: ExerciseState
  focusedExerciseIndex: number;
  hasFlipped: boolean;
  messageRole: string;
  buttonText: string;
  handleClick: (userAnswer: string) => Promise<void>;
  getTextLangFrom: (key: string) => string;
  setHasFlipped: (hasFlipped: boolean) => void;
}

const BookFooterControls: React.FC<FooterControlsProps> = ({
  bookExerciseParts,
  exerciseState,
  focusedExerciseIndex,
  hasFlipped,
  messageRole,
  buttonText,
  handleClick,
  getTextLangFrom,
  setHasFlipped
}) => {
  const [lastFocusedExerciseIndex, setLastFocusedExerciseIndex] = React.useState<number>(-1);
  // console.log("focusedExerciseIndex", focusedExerciseIndex);

  useEffect(() => {
    if (focusedExerciseIndex >= 0) {
      setLastFocusedExerciseIndex(focusedExerciseIndex);
    } else if (focusedExerciseIndex === -2) {
      setLastFocusedExerciseIndex(-1);
    }
  }, [focusedExerciseIndex, lastFocusedExerciseIndex]);

  // console.log("lastFocusedExerciseIndex", lastFocusedExerciseIndex, "focusedExerciseIndex", focusedExerciseIndex);
  
  return (
    <FooterControls messageRole={messageRole} exerciseState={exerciseState}>
      <ResultsDisplay
        lemma_lang_to={(bookExerciseParts && focusedExerciseIndex < 0 && lastFocusedExerciseIndex >= 0) ? (bookExerciseParts[lastFocusedExerciseIndex] as BookExercisePart).solution : ''}
        example_lang_to={ (bookExerciseParts && focusedExerciseIndex < 0 && lastFocusedExerciseIndex >= 0) ? 
          `Lemma: ${(bookExerciseParts[lastFocusedExerciseIndex] as BookExercisePart).lemma_lang_to}` : ''}
        exerciseState={(messageRole === 'book-flashcard' && hasFlipped) ? "loading" : exerciseState}
        message_role={messageRole}
        // pos={(bookExerciseParts[focusedExerciseIndex] as BookExercisePart).
        lemma_lang_from={(focusedExerciseIndex >= 0) ? (bookExerciseParts[focusedExerciseIndex] as BookExercisePart).lemma_lang_from : ''}
      />
      <div className="footer-controls-action-div">
        {(exerciseState === "loading") ? (
          <Button
            variant="secondary"
            size="control"
            disabled
            onClick={() => {}}
            text={getTextLangFrom('word-loading-dots')}
          />
        ) : (focusedExerciseIndex < 0) ? (
          <Button 
            size="control"
            variant={ExerciseStateToButtonVariant(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick('')}
            text={buttonText}
          />
        ) : (messageRole === 'book-flashcard' && !hasFlipped) ? (
          <Button 
            size="control"
            trailingIcon={ChevronRight}
            onClick={() => setHasFlipped(true)}
            text={getTextLangFrom('word-reveal')}
          />
        ) : (messageRole === 'book-flashcard' && hasFlipped) ? (
          <>
            <Button 
              size="control"
              variant={"error"}
              leadingIcon={ChevronLeft}
              onClick={() => handleClick('false')}
              text={getTextLangFrom('word-wrong')}
            />
            <Button 
            size="control"
            variant={"success"}
            trailingIcon={ChevronRight}
            onClick={() => handleClick('true')}
            text={getTextLangFrom('word-correct')}
          />
          </>
        ) : (messageRole === 'book-discover') ? (
          <>
            <Button 
              size="control"
              variant="secondary"
              leadingIcon={ChevronLeft}
              onClick={() => handleClick('false')}
              text={getTextLangFrom('word-no')}
            />
            <Button 
              size="control"
              trailingIcon={ChevronRight}
              onClick={() => handleClick('true')}
              text={getTextLangFrom('word-yes')}
            />
          </>
        ) : (messageRole === 'book-translate') ? (
          <Button 
            size="control"
            variant={ExerciseStateToButtonVariant(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick('')}
            text={buttonText}
          />
        ) : (
          <>ERROR</>
        )}
      </div>
    </FooterControls>
  );
};

export default BookFooterControls;
