import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fireworks from '../baseComponents/FireworksAnimation';
import BotImage from '../../assets/robot-celebrating-success.svg';
import { useLanguage } from '../../languages/LanguageContext';
import Input from '../baseComponents/InputField';
import Button from '../baseComponents/Button';
import Toast from '../baseComponents/Toast';


const WaitlistPage: React.FC = () => {
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();
  const shareLink = `${window.location.origin}`;
  const location = useLocation();
  const [showToast, setShowToast] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setShowToast(true);
  };

  useEffect(() => {
    if (!location.state || !location.state.waitlistPosition) {
      navigate('/');
      return;
    }
  }, [location, navigate]);

  const waitlistPosition = location.state.waitlistPosition;

  return (
    <div className='flex flex-col items-center text-center p-4 gap-8 justify-center bg-bg-primary h-screen'>
      <Fireworks />
      <div className="flex-1 max-h-[350px] w-full" style={{ backgroundImage: `url(${BotImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
      <h1 className="text-4xl font-semibold md:text-3xl">{getTextLangFrom('WaitlistPage-title')}</h1>
      
      <div className="flex flex-col items-center gap-3">
        <h2 className="text-text-secondary">{getTextLangFrom('WaitlistPage-share-invite')}</h2>
        <div className="flex gap-3 w-full">
        <Input
          type="text"
          value={shareLink}
          onClick={(e) => { handleCopy(); e.currentTarget.select(); }}
          readOnly
          className='w-full md:w-auto pr-0'
        />
        <Button
          onClick={handleCopy}
          className="h-[41px] md:w-full"
          text={getTextLangFrom('word-copy')} 
        />
        </div>
      </div>
      
      <div className="flex flex-col items-center gap-2">
        <div className="pt-3 text-lg font-medium">{getTextLangFrom('WaitlistPage-position')}</div>
        <div className="text-5xl font-semibold">{waitlistPosition}</div>
      </div>
      
      <Toast
        title={getTextLangFrom('WaitlistPage-toast-title')}
        subtitle={getTextLangFrom('WaitlistPage-toast-message')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default WaitlistPage;
