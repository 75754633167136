import React, { useState } from "react";
import OptionsIcon from '../../assets/dots-vertical-tertiary-24px.svg';
import Button from "./Button";

type DropDownOptionProps = {
  options: string[];
  optionSelection: (option: string) => void;
  toggleDropDown: () => void;
};

const DropDownOption: React.FC<DropDownOptionProps> = ({
  options,
  optionSelection,
  toggleDropDown,
}: DropDownOptionProps): JSX.Element => {
  
  const onClickHandler = (option: string): void => {
    optionSelection(option);
    toggleDropDown(); // Close the dropdown after selection
  };

  return (
    <div className={`absolute z-10 top-12 right-0 border border-border-secondary bg-bg-primary p-1 text-left rounded-lg pointer-events-auto`}>
      {options.map((option: string, index: number): JSX.Element => {
        return (
          <Button
            variant="tertiary"
            size="sm"
            className={`w-full text-left`}
            text={option}
            onClick={() => onClickHandler(option)}
            key={index}
          />
        );
      })}
    </div>
  );
};

interface DropDownCustomProps {
    options: string[];
    setSelectedOption: (option: string) => void;
    id?: number;
    setSelectedId?: (id: number) => void;
}

const DropDownCustom: React.FC<DropDownCustomProps> = ({ options, setSelectedOption, id, setSelectedId }): JSX.Element => {
  const [showdropdown, setShowDropDown] = useState<boolean>(false);

  const toggleDropDown = () => {
    setShowDropDown(!showdropdown);
  };
  
  const optionSelection = (option: string): void => {
    setSelectedOption(option);
    setSelectedId && id && setSelectedId(id);
    setShowDropDown(false); // Hide dropdown after option selection
  };

  return (
    <div>
      <Button
        variant="tertiary"
        iconOnly={true}
        trailingIcon={OptionsIcon}
        className={showdropdown ? "active" : undefined}
        onClick={toggleDropDown}
      />
      {showdropdown && (
        <DropDownOption
          options={options}
          toggleDropDown={toggleDropDown}
          optionSelection={optionSelection}
        />
      )}
    </div>
  );
};

export default DropDownCustom;
