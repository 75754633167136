import React from 'react';
import ChevronRight from '../../../assets/chevron-right-white.svg';
import DisabledChevronGray from '../../../assets/chevron-right-disabled.svg';
import { Card, ExerciseState, ExerciseStateToButtonVariant } from '../../../types';
import FooterControls from '../../baseComponents/FooterControls';
import { useLanguage } from '../../../languages/LanguageContext';
import { ResultsDisplay } from '../FooterExerciseInfo';
import Button from '../../baseComponents/Button';

interface MultipleChoiceControlsProps {
  exerciseState: ExerciseState;
  card: Card;
  isActionButtonDisabled: boolean;
  onNextButtonClick: () => void;
}

const MultipleChoiceControls: React.FC<MultipleChoiceControlsProps> = ({ 
  exerciseState, 
  card, 
  isActionButtonDisabled, 
  onNextButtonClick,
}) => {
  const trailingIcon = isActionButtonDisabled ? DisabledChevronGray : ChevronRight;
  const { getTextLangFrom } = useLanguage();

  return (
    <FooterControls exerciseState={exerciseState}>
      <ResultsDisplay
        lemma_lang_to={card?.lemma_lang_to}
        example_lang_to={card?.example_lang_to}
        exerciseState={exerciseState}
        pos={card?.pos}
      />
      <div className="footer-controls-action-div">
        <Button 
          size="control"
          trailingIcon={trailingIcon}
          onClick={onNextButtonClick}
          disabled={isActionButtonDisabled}
          variant={ExerciseStateToButtonVariant(exerciseState)}
          text={getTextLangFrom('word-next')}
        />
      </div>
    </FooterControls>
  );
};

export default MultipleChoiceControls;
