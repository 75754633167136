import NotFoundImage from '../../assets/not_found_robot.svg';
import { useNavigate } from 'react-router-dom';
import Button from '../baseComponents/Button';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen p-6">
      <div className="flex flex-col items-center gap-7">
        <img src={NotFoundImage} alt="Not Found" className="w-full max-w-[300px]" />
        <h1 className="text-4xl font-bold text-center md:text-5xl">
          Lost in Space?
        </h1>
        <p className="text-center text-text-tertiary text-lg max-w-[510px]">
          It seems you've ventured into uncharted territory. Let's go home.
        </p>
        <Button text="Back to Earth" variant='primary' size='xl' onClick={() => navigate('/library')} />
      </div>
    </div>
  );
};

export default NotFoundPage;
