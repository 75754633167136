// src/languages/LanguageContext.tsx

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import en from './locales/en.json';
import de from './locales/de.json';
import it from './locales/it.json';
import { useAuth } from '../authentification/AuthContext'; // Adjust path as per your structure

// TODO: get list of supported lanaguages from server
export type Language = 'en' | 'de' | 'it';

const translations: { [key in Language]: { [key: string]: string } } = {
  en: en,
  de: de,
  it: it,
};

interface LanguageContextProps {
  lang_from: Language;
  lang_to: Language;
  setLangFrom: (language: Language) => void;
  setLangTo: (language: Language) => void;
  getTextLangFrom: (key: string) => string;
  getTextLangTo: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth(); // Assuming useAuth provides user information including language settings
  const [lang_from, setLangFrom] = useState<Language>(user?.lang_from as Language || 'en');
  const [lang_to, setLangTo] = useState<Language>(user?.lang_to as Language || 'de');

  useEffect(() => {
    // Set initial languages based on user's preferences
    if (user?.lang_from && user?.lang_to) {
      setLangFrom(user.lang_from as Language);
      setLangTo(user.lang_to as Language);
    } else {
      // Fallback to default languages if user settings are not available
      setLangFrom('en');
      setLangTo('de');
    }
  }, [user]);

  const getTextLangFrom = (key: string) => {
    return translations[lang_from][key] || key;
  };

  const getTextLangTo = (key: string) => {
    return translations[lang_to][key] || key;
  };

  const contextValue: LanguageContextProps = {
    lang_from,
    lang_to,
    setLangFrom,
    setLangTo,
    getTextLangFrom,
    getTextLangTo,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
