import styled, { css } from 'styled-components';

export const Input = styled.input<{ hasIcon?: boolean }>`
  padding: 10px 14px 10px 14px;
  border: 1px solid ${({ theme }) => theme.border_primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.text_primary};
  width: 100%;
  font-weight: 500;
  box-shadow: ${({ theme }) => theme.shadow_xs};
  background-color: ${({ theme }) => theme.background_primary};

  &::placeholder {
    color: ${({ theme }) => theme.text__text_placeholder};
    font-weight: 400;
  }

  &:focus {
    border-color: ${({ theme }) => theme.border_brand};
    outline: none;
    box-shadow: ${({ theme }) => theme.ring_brand_xs};
  }

  &:disabled {
    color: ${({ theme }) => theme.text__text_disabled};
    border-color: ${({ theme }) => theme.border_disabled};
    cursor: not-allowed;
    font-weight: 400;
  }

  ${(props) =>
    props.hasIcon &&
    css`
      padding-left: 42px;
    `}
`;



export const StyledInput = styled(Input)`
  width: 100%;
  max-width: 512px;
  background-color: ${({ theme }) => theme.background_primary};

  @media (max-width: 768px) {
    max-width: none;
  }
`;

