// WritePage.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageDiv } from '../GameStyles';
import HeaderWithBubble from '../HeaderWithBubble';
import WriteInput from './WriteInput';
import WriteControls from './WriteControls';
import { Card, ExerciseState, GameState } from '../../../types';
import { evaluateExerciseState, fetchGameCard, terminateGame } from '../../../api/Games';
import useWriteKeyboardControl from './useWriteKeyboardControls';
import { useAuth } from '../../../authentification/AuthContext';
import { NavbarBackAndGameProgress } from '../../baseComponents/ProgressBar';
import { MainLayout } from '../../baseComponents/Layout';


const WritePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const [showAnswerButtons, setShowAnswerButtons] = useState(false);
  const [userResponse, setUserResponse] = useState('');
  const [solution, setSolution] = useState<string>('');
  const [exerciseState, setExerciseState] = useState<ExerciseState>('unanswered');
  const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight);
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const isInitialized = useRef(false);

  // Check if the location state is valid
  useEffect(() => {
    if (!location.state || !location.state.gameData) {
      navigate('/exercises');
      return;
    }
  }, [location, navigate]);

  const { game_mode, initial_card_s } = location.state.gameData;

  const handleRevealAnswerClick = useCallback(async () => {
    if (userResponse !== '') {
      // Remove focus from the input field to remove the keyboard on mobile
      inputRef.current?.blur();
      await evaluateExerciseState(userResponse, solution, game_mode).then(setExerciseState);
      setShowAnswerButtons(true);
    } else {
      inputRef.current?.focus();
    }
  }, [userResponse, solution, game_mode]);

  const isEmptyCard = (card: Card) => {
    return Object.keys(card).length === 0;
  };

  const setCurrentCardAndCorrectText = useCallback((card: Card) => {
    setCurrentCard(card);
    if (game_mode === 'write-mode') {
      setSolution(card?.lemma_lang_to ?? '');
    } else {
      setSolution(card?.lemma_lang_from.split('**')[1] ?? '');
    }
  }, [game_mode]);

  useEffect(() => {
    const fetchInitialCard = async () => {
      if (isInitialized.current) {
        return;
      }

      isInitialized.current = true;
      let card: Card = {} as Card;
      card = initial_card_s as Card;
      console.log('Initial card:', card);

      if (isEmptyCard(card)) {
        navigate('/library');
      } else {
        setCurrentCardAndCorrectText(card);
      }
    };

    fetchInitialCard();
  }, [initial_card_s, navigate, game_mode, setCurrentCardAndCorrectText]);

  const fetchNextCard = useCallback((currentCard: Card) => {
    return new Promise<void>((resolve, reject) => {
      fetchGameCard<Card>(game_mode, currentCard)
        .then(async card => {
          if (isEmptyCard(card)) {
            try {
              const { accuracy, session_duration } = await terminateGame(currentCard?.session_id || 0, game_mode);
              const updatedEndGameData: GameState = {
                ...location.state.gameData,
                accuracy: accuracy,
                session_duration: session_duration,
              };

              navigate('/endgame', { state: { gameData: updatedEndGameData } });
            } catch (error) {
              console.error('Error terminating game:', error);
            }
          } else {
            setCurrentCardAndCorrectText(card);
          }
          resolve();
        })
        .catch(error => {
          console.error('Error fetching next card:', error);
          reject(error);
        });
    });
  }, [game_mode, location.state.gameData, navigate, setCurrentCardAndCorrectText]);
  
  const handleNextButtonClick = useCallback(async () => {
    if (currentCard) {
      currentCard.score = (exerciseState === 'correct' || exerciseState === 'almost-correct') ? true : false;
      setShowAnswerButtons(false);
      setExerciseState('unanswered');
      setUserResponse('');
      try {
        await fetchNextCard(currentCard);
      } catch (error) {
        console.error('Error fetching next card:', error);
      }
    }
  }, [currentCard, exerciseState, fetchNextCard]);

  useWriteKeyboardControl(showAnswerButtons, handleRevealAnswerClick, handleNextButtonClick);

  return (
    <MainLayout hasNavbar={false} hasFooterControls={true}>
      <NavbarBackAndGameProgress
        session_progress={currentCard?.session_progress ?? 0}
        onBackClick={() => navigate('/exercises')}
      />
      <PageDiv>
        <HeaderWithBubble
            card={currentCard || undefined}
            user={user || undefined}
        />
        <WriteInput 
          userResponse={userResponse}
          setUserResponse={setUserResponse}
          showAnswerButtons={showAnswerButtons}
          solution={solution}
          exerciseState={exerciseState}
          game_mode={game_mode}
        />
      </PageDiv>
      <WriteControls 
        showAnswerButtons={showAnswerButtons}
        exerciseState={exerciseState}
        card={currentCard || {} as Card}
        handleRevealAnswerClick={handleRevealAnswerClick}
        handleNextButtonClick={handleNextButtonClick}
        userResponse={userResponse}
        messageRole={game_mode}
      />
    </MainLayout>
  );
};

export default WritePage;
