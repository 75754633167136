import React, { useState, useEffect } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import PaginationControls from '../baseComponents/PaginationControl';
import SearchBar from '../baseComponents/SearchBar';
import ArrowUpIcon from '../../assets/arrow-up.svg';
import ArrowDownIcon from '../../assets/arrow-down.svg';
import { Word } from '../../types';
import { CellScore, CellTranslation, CellLastStudied } from './TableCellStyles';
import { useLanguage } from '../../languages/LanguageContext';

function WordsTable({ wordTableData }: { wordTableData: Word[] }) {
  const [filter, setFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getTextLangFrom } = useLanguage();

  // Filter data based on search input
  const filteredData = React.useMemo(() => {
    return wordTableData.filter(word =>
      word.lemma_lang_from.toLowerCase().includes(filter.toLowerCase()) ||
      word.lemma_lang_to.toLowerCase().includes(filter.toLowerCase())
    );
  }, [wordTableData, filter]);

  // Define columns for the table
  const columns = React.useMemo<ColumnDef<Word>[]>(
    () => [
      {
        accessorKey: 'score',
        cell: info => info.getValue(),
        size: 100,
        header: () => <span>{getTextLangFrom('word-score')}</span>,
      },
      {
        accessorKey: 'lemma_lang',
        header: () => <span>{getTextLangFrom('word-word')}</span>,
      },
      {
        accessorKey: 'last_seen',
        size: 160,
        cell: info => (
          <span className="hidden sm:table-cell">
            {info.getValue() as React.ReactNode}
          </span>
        ),
        header: () => (
          <span className="hidden sm:table-cell">
            {getTextLangFrom('word-last-studied')}
          </span>
        ),
      },
    ],
    [getTextLangFrom]
  );

  // Set sorting by default on the last_seen column
  useEffect(() => {
    setSorting([{ id: 'last_seen', desc: true }]);
  }, [wordTableData]);

  // Initialize the table instance using react-table hooks
  const table = useReactTable({
    columns,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [{ id: 'last_seen', desc: true }],
    },
  });

  return (
    <div className="table-and-search-wrapper">
      <div className="table-search-div">
        <SearchBar filter={filter} setFilter={setFilter} className="w-full md:w-[320px]"/>
      </div>
      <div className="table-and-pagination-wrapper">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize() !== 150 ? header.getSize() : undefined,
                      padding: '0px',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={'table-header-bottom ' +
                          (header.column.getCanSort() ? 'cursor-pointer select-none' : '')
                        }
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.id === 'last_seen' && header.column.getIsSorted() === 'asc' && (
                          <img className="hidden sm:inline table-icon-sorting" src={ArrowUpIcon} alt="Up arrow" />
                        )}
                        {header.column.id === 'last_seen' && header.column.getIsSorted() === 'desc' && (
                          <img className="hidden sm:inline table-icon-sorting" src={ArrowDownIcon} alt="Down arrow" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td className="table-cell" key={cell.id}>
                    {cell.column.id === 'score' ? (
                      <CellScore score={cell.getContext().row.original.score} />
                    ) : cell.column.id === 'lemma_lang' ? (
                      <CellTranslation
                        lemma_lang_from={cell.getContext().row.original.lemma_lang_from}
                        lemma_lang_to={cell.getContext().row.original.lemma_lang_to}
                      />
                    ) : (
                      <CellLastStudied last_seen={cell.getContext().row.original.last_seen} />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationControls table={table} />
      </div>
    </div>
  );
}

export default WordsTable;
