import React from 'react';
import { ButtonVariants } from '../../types';

interface ButtonProps {
  text?: string;
  variant?: ButtonVariants;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'control';
  onClick?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  leadingIcon?: string;
  trailingIcon?: string;
  iconOnly?: boolean; // Icon-only variant
  className?: string; // New className prop
  isActive?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
  key?: number;
}

const Button: React.FC<ButtonProps> = ({
  text,
  variant = 'primary',
  size = 'md',
  onClick,
  onBlur,
  disabled = false,
  leadingIcon,
  trailingIcon,
  iconOnly = variant === 'menuMobile' ? true : false,
  className = '',
  isActive = false,
  key,
}) => {
  const baseStyles = `font-medium focus:outline-none flex boxShadow-sm rounded-xl md:rounded-lg
    ${variant !== 'menuDesktop' && 'items-center justify-center'}    
  `;

  const disabledStyles = `${disabled && 'bg-buttonDisabled-bg text-buttonDisabled-fg border border-buttonDisabled-border cursor-not-allowed'}`;
  const disabledIconStyles = `${disabled && 'opacity-50'}`;

  let sizeBaseStyles = {
    sm: 'text-sm gap-3',
    md: 'text-sm gap-3',
    lg: 'text-base gap-4',
    xl: 'text-lg gap-5',
    control: 'text-base gap-3 md:min-w-[170px] w-full h-12 md:w-12',
  };

  if (variant === 'menuDesktop') {
    sizeBaseStyles[size] = 'w-full text-base gap-3';
  }

  let sizeIconDivStyles = {
    sm: 'w-8 h-8 px-0 py-0',
    md: 'w-10 h-10 px-0 py-0',
    lg: 'w-12 h-12 px-0 py-0',
    xl: 'w-14 h-14 px-0 py-0',
    control: 'w-10 h-10 px-0 py-0',
  };

  if (variant === 'menuMobile') {
    sizeIconDivStyles[size] = 'w-[50px] h-[50px]';
  }

  let sizeNotOnlyIconDivStyles = {
    sm: 'px-3 py-2',
    md: 'px-3.5 py-2.5',
    lg: 'px-4 py-2.5',
    xl: 'px-4.5 py-3',
    control: 'px-0 py-0',
  };

  if (variant === 'menuDesktop') {
    sizeNotOnlyIconDivStyles[size] = 'px-3 py-2';
  }

  const sizeIconImgStyles = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
    lg: 'w-8 h-8',
    xl: 'w-10 h-10',
    control: 'w-6 h-6',
  };

  sizeIconImgStyles[size] = variant === 'menuMobile' ? 'w-10 h-10' : sizeIconImgStyles[size];

  // Variant-specific styles
  const variantStyles = {
    primary: `${!disabled && `bg-buttonPrimary-bg border border-buttonPrimary-border text-buttonPrimary-fg 
      hover:bg-buttonPrimary-bgHover hover:border-buttonPrimary-borderHover hover:text-buttonPrimary-fgHover`}`,
    secondary: `${!disabled && `bg-buttonSecondary-bg border border-buttonSecondary-border text-buttonSecondary-fg
      hover:bg-buttonSecondary-bgHover hover:border-buttonSecondary-borderHover hover:text-buttonSecondary-fgHover`}`,
    tertiary: `bg-buttonTertiary-bg border border-buttonTertiary-border text-buttonTertiary-fg
        ${!disabled && 'hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:text-buttonTertiary-fgHover'}`,
    success: `bg-buttonSuccess-bg border border-buttonSuccess-border text-buttonSuccess-fg
        ${!disabled && 'hover:bg-buttonSuccess-bgHover hover:border-buttonSuccess-borderHover hover:text-buttonSuccess-fgHover'}`,
    warning: `bg-buttonWarning-bg border border-buttonWarning-border text-buttonWarning-fg
        ${!disabled && 'hover:bg-buttonWarning-bgHover hover:border-buttonWarning-borderHover hover:text-buttonWarning-fgHover'}`,
    error: `bg-buttonError-bg border border-buttonError-border text-buttonError-fg
        ${!disabled && 'hover:bg-buttonError-bgHover hover:border-buttonError-borderHover hover:text-buttonError-fgHover'}`,
    menuDesktop: 'text-left items-center justify-start border border-buttonTertiary-border hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:text-buttonTertiary-fgHover',
    menuMobile: 'border border-transparent',
  };

  // Styles for the active state
  const activeStyles = 'bg-bg-tertiary border border-border-secondary shadow-sm';

  return (
    <button
      className={`${baseStyles} ${sizeBaseStyles[size]} ${variantStyles[variant]} ${disabledStyles} ${
        iconOnly ? sizeIconDivStyles[size] : sizeNotOnlyIconDivStyles[size]
      } ${className} ${isActive ? activeStyles : ''}`}
      onClick={!disabled ? onClick : undefined}
      onBlur={!disabled ? onBlur : undefined}
      disabled={disabled}
    >
      {leadingIcon && <img src={leadingIcon} alt="Icon" className={`${sizeIconImgStyles[size]}`} />}
      {!iconOnly && text}
      {trailingIcon && <img src={trailingIcon} alt="Icon" className={`${sizeIconImgStyles[size]} ${disabledIconStyles}`} />}
    </button>
  );
};

export default Button;
