import React from 'react';
import CloseIcon from '../../assets/x-close-tertiary-24px.svg';
import RocketIcon from '../../assets/rocket.svg';
import Button from './Button';
import ReactTooltip from 'react-tooltip';
import { User } from '../../types';
import VoiceControlDropdown from './VoiceControlDropdown';


interface ProgressBarProps {
  session_progress: number;
  tooltipText?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ session_progress, tooltipText }) => {
  return (
    <div className="flex flex-1 h-5 bg-bg-tertiary rounded-full">
      <div
        data-tip={tooltipText}
        className="h-full bg-gradient-to-b from-bg-brand via-brand-c3 to-bg-brand rounded-[30px_20px_20px_30px] shadow-sm transition-width duration-500"
        style={{ width: `${session_progress}%` }}
      />
      <ReactTooltip />
    </div>
  );
};

interface NavbarBackProps {
  session_progress?: number;
  onBackClick: () => void;
}

export const NavbarBackAndGameProgress: React.FC<NavbarBackProps> = ({ session_progress, onBackClick }) => {
  return (
    <div className='progress-bar-wrapper'>
      <Button leadingIcon={CloseIcon} variant="tertiary" onClick={onBackClick} iconOnly={true} />
      <ProgressBar session_progress={session_progress || 0} />
      <img className="ml-3 w-10 h-10" src={RocketIcon} alt="rocket" />
    </div>
  );
};


export const NavbarBack: React.FC<NavbarBackProps> = ({ onBackClick }) => {
  return (
    <div className='progress-bar-wrapper'>
      <Button leadingIcon={CloseIcon} variant="tertiary" onClick={onBackClick} iconOnly={true} />
    </div>
  );
};


interface NavbarBackProgressVoiceProps {
  onBackClick: () => void;
  handleVoiceModeClick: () => void;
  game_mode: 'book-chat-mode' | 'chat-mode';
  user: User | null;
  handleResumeSynthesizer: (user: User) => void;
  handleInterruptSynthesizer: () => void;
  session_progress?: number;
}

export const NavbarBackProgressVoice: React.FC<NavbarBackProgressVoiceProps> = ({
  onBackClick,
  handleVoiceModeClick,
  user,
  handleResumeSynthesizer,
  handleInterruptSynthesizer,
  session_progress,
}) => {
  if (!user) {
    return null;
  }

  return (
    <div className='progress-bar-wrapper'>
      <Button variant="tertiary" iconOnly={true} onClick={onBackClick} leadingIcon={CloseIcon} />
      { session_progress !== undefined && <ProgressBar session_progress={session_progress} /> }
      <VoiceControlDropdown
        user={user}
        handleVoiceModeClick={handleVoiceModeClick}
        handleResumeSynthesizer={handleResumeSynthesizer}
        handleInterruptSynthesizer={handleInterruptSynthesizer}
      />
    </div>
  );
};
