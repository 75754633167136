import React from 'react';
import { Chapter } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { ReactComponent as FaChevronDown } from '../../assets/chevron-down-small.svg';
import { ReactComponent as FaChevronRight } from '../../assets/chevron-right-small.svg';
import { parseTextWithMask } from '../chatPage/Book/messageTextParser';

const ChapterList = ({
  chapters,
  openChapter,
  setOpenChapter,
  selectedChapterPartId,
  setSelectedChapterPartId,
  expandedChapterPartId,
  setExpandedChapterPartId,
  chapterPartMessages
}: {
  chapters: Chapter[];
  openChapter: number | null;
  setOpenChapter: (index: number) => void;
  selectedChapterPartId: number | undefined;
  setSelectedChapterPartId: (id: number) => void;
  expandedChapterPartId: number | null;
  setExpandedChapterPartId: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
  chapterPartMessages: { [key: number]: string[] };
}) => {
  const { getTextLangFrom, getTextLangTo } = useLanguage();

  return (
    <ul className="list-none p-0 m-0 overflow-y-auto h-full">
      {chapters.map((chapter, index) => {
        const isSelectedChapter = chapter.parts.some((part) => part.id === selectedChapterPartId);
        return (
          <li key={index} className="mb-2">
            <div
              className={`flex items-center p-2 gap-2 cursor-pointer text-lg font-medium ${isSelectedChapter && 'text-text-brand'}`}
              onClick={() => setOpenChapter(index)}
            >
              <div className={`transition-transform duration-300 ${openChapter === index ? 'rotate-180' : ''}`}>
                {openChapter === index ? <FaChevronDown /> : <FaChevronRight />}
              </div>
              <span>{chapter.chapter_title}</span>
            </div>
            {openChapter === index && (
              <div className="flex flex-col gap-1 ">
                {chapter.parts.map((part) => (
                  <div
                    key={part.id}
                    className={`flex flex-col p-3 gap-2 rounded-md cursor-pointer ${selectedChapterPartId === part.id ? 'bg-bg-tertiary' : ''}`}
                    onClick={() => setSelectedChapterPartId(part.id)}
                  >
                    <span className="font-medium">{getTextLangTo('word-part')} {part.number}</span>
                    <p className="text-text-secondary">{parseTextWithMask(part.overview)}</p>
                    {expandedChapterPartId === part.id && chapterPartMessages[part.id]?.map((msg, idx) => (
                      <div key={idx} className="text-text-secondary">{parseTextWithMask(msg)}</div>
                    ))}
                    <button
                      onClick={(e) => setExpandedChapterPartId(e, part.id)}
                      className="w-fit p-0 text-sm text-text-tertiary"
                    >
                      {expandedChapterPartId === part.id ? getTextLangFrom('word-show-less') : getTextLangFrom('word-show-more')}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ChapterList;
