import React from 'react';

export const CellScore: React.FC<{ score: number }> = ({ score }) => {
    return (
        <div className="flex items-end gap-0.5 h-full">
            <div className={`w-2 rounded-sm h-3 ${score > -1 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
            <div className={`w-2 rounded-sm h-5 ${score > 0 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
            <div className={`w-2 rounded-sm h-7 ${score > 1 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
        </div>
    );
};


export const CellTranslation: React.FC<{ lemma_lang_from: string, lemma_lang_to: string }> = ({ lemma_lang_from, lemma_lang_to }) => {
    return (
        <div className="flex flex-col w-0">
            <span className="text-primary font-semibold">{lemma_lang_from}</span>
            <p className="text-sm text-text-tertiary">{lemma_lang_to}</p>
        </div>
    );
}

const formatDateForClient = (date: Date): string => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();

    const seconds = Math.floor(diff / 1000);
    if (seconds < 60) {
        return "Few seconds ago";
    }
    
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} minutes ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} hours ago`;
    }

    const days = Math.floor(hours / 24);    
    if (days < 7) {
        return `${days} days ago`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months} months ago`;
    } 

    const years = Math.floor(days / 365);
    return `${years} years ago`;
};

export const CellLastStudied: React.FC<{ last_seen: string }> = ({ last_seen }) => {
    const lastSeenDate = new Date(last_seen);
    const formattedDate = formatDateForClient(lastSeenDate);

    return (
        <p className="text-sm text-text-tertiary hidden sm:block">{formattedDate}</p>
    );
};
