import React from 'react';
import { useTheme } from 'styled-components';
import { useLanguage } from '../../languages/LanguageContext';
import AccuracyIcon from '../../assets/accuracy-icon.svg';
import SpeedIcon from '../../assets/speed-icon.svg';
import StreakIcon from '../../assets/streak.svg';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { useAuth } from '../../authentification/AuthContext';
import { ProfileData } from '../../types';

ChartJS.register(CategoryScale, LinearScale, BarElement);

export const StreakDays: React.FC<{ lastWeekDailyStats: { day: string, isStreak: boolean }[] }> = ({ lastWeekDailyStats }) => {
  return (
    <div className="flex justify-around gap-2 sm:gap-4 w-full">
      {lastWeekDailyStats.map((day, index) => (
        <div key={index} className="flex flex-col items-center gap-1 sm:gap-2">
          <div className='text-md font-medium text-text-tertiary'>{day.day}</div>
          <div className={`flex justify-center items-center w-6 h-6 sm:w-8 sm:h-8  rounded-full ${day.isStreak ? 'bg-brand-yellow' : 'bg-bg-tertiary'}`}>
              {day.isStreak && <p className="font-bold text-lg">✓</p>}
          </div>
        </div>
      ))}
    </div>
  )
}

export const getOrderedDaysOfWeek = () => {
  const today = new Date().getDay();
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  return [...daysOfWeek.slice(today), ...daysOfWeek.slice(0, today)];
}

export const DailyStreakTile: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const { user } = useAuth();
  const last_7d_streak_days = user?.last_7d_streak_days || [];

  const orderedDaysOfWeek = getOrderedDaysOfWeek();
  const lastWeekDailyStats = last_7d_streak_days.map((isStreak, index) => ({
    day: orderedDaysOfWeek[index],
    isStreak,
  }));

  return (
    <div className="tile">
      <div className={'flex items-center gap-2'}>
        <div
          className="flex text-2xl font-bold items-center justify-center w-20 h-20 font-bold pt-1 pr-1 "
          style={{
            backgroundImage: `url(${StreakIcon})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {user?.number_streak_days? user.number_streak_days : 0}
        </div>
        <div className="flex flex-col flex-1 gap-1">
          <h3 className="font-medium text-2xl">{getTextLangFrom('Streak-title')}</h3>
          <p className="tile-p">{getTextLangFrom('Streak-text')}</p>
        </div>
      </div>
      <StreakDays lastWeekDailyStats={lastWeekDailyStats} />
    </div>
  );
};

const NumberTile: React.FC<{ icon: string, number: number, text: string }> = ({ icon, number, text }) => {
  return (
    <div className="tile">
      <div className="flex gap-4">
        <img src={icon} alt="icon" />
        <div>
          <h3 className="tile-h3">{number}</h3>
          <p className="tile-p">{text}</p>
        </div>
      </div>
    </div>
  );
}


const PagesReadTile: React.FC<{ profileData: ProfileData }> = ({ profileData }) => {
  const theme = useTheme() 
  const { getTextLangFrom } = useLanguage();


  const pagesReadData = {
    labels: getOrderedDaysOfWeek(),
    datasets: [
      {
        data: profileData.pages_read_per_day,
        backgroundColor: theme.background_brand,
        borderRadius: 4,
        barPercentage: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensure the chart always fills the container
    animation: {
      duration: 0, // Disable animation on page load
    },
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Disable vertical grid lines
        },
        ticks: {
          color: theme.text_tertiary, // Customize text color for X-axis
        },
      },
      y: {
        grid: {
          color: theme.border_secondary,
          borderDash: [4, 4],
        },
        ticks: {
          color: theme.text_tertiary,
          maxTicksLimit: 6, // Limit number of Y-axis labels to 5
          // padding: 6, // Add padding between Y-axis and labels
        },
        min: 0, // Set minimum value for Y-axis
        suggestedMax: 10, // Ensure Y-axis extends up to at least 10
        border: {
          display: false, // Remove the axis line next to Y labels
        },
      },
    },
  };
  
  return (
    <div className="tile">
      <h3 className="tile-h3">{getTextLangFrom('ProfilePage-pages-read')}</h3>
      <div className="flex justify-center w-full h-[200px]">
        <Bar data={pagesReadData} options={options} />
      </div>
    </div>
  );
};

export default PagesReadTile;


export const StatsTiles: React.FC<{ profileData?: ProfileData }> = ({ profileData }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className="flex flex-col gap-8">
      { profileData && (
        <>
          <DailyStreakTile />
          <div className="flex gap-8 flex-col sm:flex-row">
            <NumberTile
              icon={AccuracyIcon}
              number={profileData.number_pages_read}
              text={getTextLangFrom('ProfilePage-pages-read')}
            />
            <NumberTile
              icon={SpeedIcon}
              number={profileData.number_words_learned}
              text={getTextLangFrom('ProfilePage-words-learned')}
            />
          </div>
          <PagesReadTile profileData={profileData} />
        </>
      )}
    </div>
  );
};
