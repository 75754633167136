import CorrectIcon from '../../assets/result-correct.svg';
import WrongIcon from '../../assets/result-wrong.svg';
import AlmostCorrectIcon from '../../assets/result-almost-correct.svg';
import { useLanguage } from '../../languages/LanguageContext';
import { ExerciseState } from '../../types';
import styled from 'styled-components';
import { useEffect, useState } from 'react';


const HighlightText = styled.span`
  color: ${({ theme }) => theme.text_brand};
`;

const ResultWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const StatusIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 9999px;
  box-shadow: ${({ theme }) => theme.shadow_xs};
  background-color: ${({ theme }) => theme.background_primary};
  padding: 10px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    padding: 4px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    padding-bottom: 8px;
  }
`;

const ResultHeader = styled.div<{ exerciseState: ExerciseState }>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme, exerciseState }) => {
    switch (exerciseState) {
      case 'correct':
        return theme.success_300_fg;
      case 'wrong':
        return theme.error_300_fg;
      case 'almost-correct':
        return theme.warning_300_fg;
      default:
        return theme.text_primary;
    }
  }};

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const MobileResultTitle = styled.div<{ exerciseState?: ExerciseState }>`
  font-size: 18px;
  font-weight: 500;
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: ${({ exerciseState }) => (exerciseState === 'unanswered') ? '12px' : '0'};
  }
`;

const LemmaLangToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  @media (max-width: 768px) {
    padding-bottom: 8px;
  }
`;

const LemmaLangToRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
`;

const LemmaLangToText = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 1.3;
  }
`;

const LemmaLangToDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LemmaLangToDetailText = styled.div`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.text_tertiary};
`;

interface ResultsDisplayProps {
  lemma_lang_to: string;
  example_lang_to: string;
  exerciseState: ExerciseState;
  message_role?: string;
  pos?: string;
  lemma_lang_from?: string;
}

export const ResultsDisplay: React.FC<ResultsDisplayProps> = ({
  lemma_lang_to,
  example_lang_to,
  exerciseState,
  message_role,
  pos,
  lemma_lang_from
}) => {
  const { getTextLangFrom } = useLanguage();

  const [tempLemmaLangTo, setTempLemmaLangTo] = useState<string | undefined>(undefined);
  const [tempExampleLangTo, setTempExampleLangTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (lemma_lang_to !== undefined) {
      setTempLemmaLangTo(lemma_lang_to);
    }
    if (example_lang_to !== undefined) {
      setTempExampleLangTo(example_lang_to);
    }
  }, [lemma_lang_to, example_lang_to]);

  const getStatusIcon = () => {
    switch (exerciseState) {
      case 'correct':
        return CorrectIcon;
      case 'wrong':
        return WrongIcon;
      case 'almost-correct':
        return AlmostCorrectIcon;
      default:
        return '';
    }
  };

  const getResultTitleText = () => {
    switch (exerciseState) {
      case 'correct':
        return getTextLangFrom('word-correct');
      case 'wrong':
        return getTextLangFrom('control-title-wrong');
      case 'almost-correct':
        return getTextLangFrom('control-title-almost-correct');
      default:
        return '';
    }
  };
  // console.log('message_role', message_role, 'exerciseState', exerciseState);

  return (
    <ResultWrapper>
      {(exerciseState === 'correct' || exerciseState === 'almost-correct' || exerciseState === 'wrong') ? (
        <HeaderWrapper>
          <ResultHeader exerciseState={exerciseState}>
            <StatusIcon src={getStatusIcon()} />
            <MobileResultTitle>{getResultTitleText()}</MobileResultTitle>
          </ResultHeader>
          <LemmaLangToWrapper>
            <LemmaLangToRow>
              {lemma_lang_to && <LemmaLangToText>{lemma_lang_to}</LemmaLangToText>}
              <LemmaLangToDetails>
                <LemmaLangToDetailText>{pos}</LemmaLangToDetailText>
              </LemmaLangToDetails>
            </LemmaLangToRow>
            {example_lang_to && <LemmaLangToDetailText>{tempExampleLangTo}</LemmaLangToDetailText>}
          </LemmaLangToWrapper>
        </HeaderWrapper>
      ) : (message_role === 'book-discover' && lemma_lang_to) ? (
        <MobileResultTitle exerciseState={exerciseState}>Add <HighlightText>{lemma_lang_to}</HighlightText> to the dictionary?</MobileResultTitle>
      ) : (message_role === 'book-flashcard' && lemma_lang_to && exerciseState === 'unanswered') ? (
        <MobileResultTitle exerciseState={exerciseState}>Guess the translation of <HighlightText>{lemma_lang_to}</HighlightText></MobileResultTitle>
      ) : (message_role === 'book-flashcard' && lemma_lang_to && exerciseState !== 'unanswered') ? (
        <MobileResultTitle exerciseState={exerciseState}>Was your guess correct?</MobileResultTitle>
      ) : (message_role === 'book-translate' && lemma_lang_to) ? (
        <MobileResultTitle exerciseState={exerciseState}>Translate <HighlightText>{lemma_lang_from}</HighlightText></MobileResultTitle>
      ) : (
        <></>
      )}
    </ResultWrapper>
  );
};
