import styled, { keyframes, css } from 'styled-components';
import { Button } from '../oldBaseComponents/Buttons';

const messagePulsate = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
`;

export const MessageDiv = styled.div<{ message_role: string; transition?: boolean; game_mode: string, isreadingthismessage?: boolean }>`
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  padding: 12px ${({ message_role }) => message_role === 'user' ? '12px' : '0'};
  transition: opacity 0.3s ease-in-out;
  color: ${({ isreadingthismessage, theme }) =>  isreadingthismessage ? theme.chat_secondary_fg : theme.chat_primary_fg };
  transition: color 0.6s ease-in-out;
  background-color: ${({ message_role, theme }) => message_role === 'user' ? theme.chat_message_bg : 'none' };
  box-shadow: ${({ message_role, theme }) => message_role === 'user' ? `0 1px 2px ${theme.chat_border_input}` : 'none' };
  border-radius: 10px;
  align-self: ${({ message_role }) => (message_role === 'user' ? 'flex-end' : 'flex-start')};
  font-size: 20px;
  margin: ${({ message_role }) => (message_role === 'title' || message_role === 'intro') ? '12px auto 0 auto' : (message_role === 'title' || message_role === 'intro') ? '0 0 12px 0' : '0'};

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${({ transition }) =>
    transition === true &&
    css`
      opacity: 0.5;
      animation: ${messagePulsate} 3s infinite;
    `}
  
  p {
    margin-bottom: 5px;
  }

  ${({ message_role }) => {
    if (message_role === 'intro') {
      return css`
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        max-width: 450px;
        text-align: center;
      `;
    } else if (message_role === 'title') {
      return css`
        font-size: 24px;
        font-weight: 500;
        max-width: 450px;
        text-align: center;
        font-style: italic;
      `;
    }
  }}
`;

export const ChatPageWrapperWithNavbar = styled.div<{ game_mode: string }>`
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${({ theme }) => theme.chat_primary_bg };
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

export const DivArrowIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ChatBarWrapper = styled.div`
  posision: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 110px;
  padding: 4px 24px 0 24px;
  z-index: 500;
  
  @media (max-width: 768px) {
    height: 74px;
    @media (max-height: 540px) {
      height: 60px;
    }
  }
`;

export const ChatBarOuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  gap: 6px;
`;

export const ChatBarInnerDiv = styled.div`
  display: flex;
  position: relative;
`;

export const StyledInput = styled.input<{ game_mode: string }>`
  height: 50px;
  width: 100%;
  padding: 0 50px 0 20px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.chat_primary_bg };
  box-shadow: ${({ theme }) => `0 0 20px -2px ${theme.chat_border_input}` };
  color: ${({ theme }) => theme.chat_primary_fg };

  font-size: 18px;

  &::placeholder {
    color: ${({ theme }) => theme.text__text_placeholder_subtle};
    font-weight: 400;
  }

  &:focus {
    border: ${({ game_mode, theme }) => `1.5px solid ${theme.chat_border_input}` };
    outline: none;
    box-shadow: none;
  }
`;

const buttonPulsateChat = keyframes`
  0% {
    box-shadow: 0 0 4px 4px rgba(21, 195, 177, 0.2);
  }
  50% {
    box-shadow: 0 0 20px 12px rgba(21, 195, 177, .8);
  }
  100% {
    box-shadow: 0 0 4px 4px rgba(21, 195, 177, .2);
  }
`;

export const StyledStopRecordingDiv = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  cursor: pointer;
`;

export const StyledStopRecordingCircle = styled.div<{ game_mode: string, theme: string | undefined }>`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  border: ${({ game_mode, theme }) =>
    theme === 'dark' ? `1.5px solid ${theme.chat_primary_fg}` :
    game_mode === 'book-chat-mode' ? `1.5px solid ${theme.chat_primary_fg}` : 'inherit'};

  animation: ${buttonPulsateChat} 3s infinite ease-in-out;
`;

export const ChatBarButton = styled(Button)<{ isVisible: boolean, game_mode: string }>`
  position: absolute;
  right: 5px;
  top: 5px;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '50px')});
  transition: opacity 0.5s ease, transform 0.5s ease;

  svg {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.chat_border_input };
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  border: ${({ theme }) => `4px solid ${theme.chat_border_input}` };
  border-left-color: ${({ theme }) => theme.chat_border_input_dark };
  border-radius: 50%;
  animation: ${rotateAnimation} 0.8s linear infinite;
`;

export const InputChatLoader = styled(Loader)`
  padding: 10px;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const AvatarDiv = styled.div`
  position: relative;
  width: 0;
  height: 0;
  margin-right: 45px;

  @media (max-width: 480px) {
    display: none;
    margin-right: 0;
  }
`;

export const AvatarImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-15%, -15%);
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow_md};
  height: 50px;
  width: 50px;
  max-width: none;
  object-fit: cover;
`;

