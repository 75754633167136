import { Message, Card } from '../types';
import { apiUrl, fetchData } from './api';


// TODO: generalize getResponse function
export async function getMessageResponse<T>(message: Message, game_mode: string, card?: Card): Promise<T> {
  return await fetchData<T>(`${apiUrl}/message/response`, 'POST', { message, game_mode, card });
}

export async function initializeChat(user_id: number): Promise<Message[]> {
  return await fetchData<Message[]>(`${apiUrl}/chat/init?user_id=${user_id}`, 'GET');
}

export async function initializeGameSupportChat(user_id: number): Promise<Message[]> {
  return await fetchData<Message[]>(`${apiUrl}/game-support/init?user_id=${user_id}`, 'GET');
}

export async function getGoogleTranslation(text: string, source_lang: string, target_lang: string): Promise<string | void> {
  return await fetchData<string | void>(`${apiUrl}/message/translate`, 'POST', { text, source_lang, target_lang });
}

export async function saveTranslationPreferences(user_id: number, translation_id: number, chat_id: number, is_save_uku: boolean): Promise<void> {
  return await fetchData<void>(`${apiUrl}/user/save-translation-preferences`, 'POST', { user_id, translation_id, chat_id, is_save_uku });
}