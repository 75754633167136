import React from 'react';
import EmptyStateImage from '../../assets/empty-state-cloud.svg';

interface DropAreaProps {
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  getTextLangFrom: (key: string) => string;
  supportedFormats: string;
  fileImage?: string;
  label?: string;
}

const DropArea: React.FC<DropAreaProps> = ({ onDrop, onDragOver, onClick, handleFileChange, isLoading, getTextLangFrom, supportedFormats, fileImage, label }) => {
  let supportedFormatsText = '';
  if (supportedFormats === '.epub') {
    supportedFormatsText = getTextLangFrom('DropArea-support-epub');
  } else if (supportedFormats === '.jpg, .jpeg, .png') {
    supportedFormatsText = getTextLangFrom('DropArea-support-images');
  }

  return (
    <div className="form-div">
      {label && <label className="form-label">{label}</label>}
      <div
        className="w-full max-w-lg border-2 border-dashed border-border-secondary rounded-lg flex flex-col justify-center items-center text-center cursor-pointer p-6 hover:bg-bg-secondary"
        onDrop={onDrop}
        onDragOver={onDragOver}
        onClick={onClick}
      >
        {fileImage? (
          <img src={fileImage} alt="Book Cover" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        ) : (
          <>
            <img src={EmptyStateImage} alt="empty-state" className="max-w-[140px] max-h-[140px]" />
            <div className="text-md font-medium mt-4">
              <span className="text-text-brand">{getTextLangFrom('DropArea-header-part1')}</span>{' '}
              {getTextLangFrom('DropArea-header-part2')}
            </div>
            <div className="text-sm text-text-tertiary mt-2">
              {supportedFormatsText}
            </div>
            
          </>
        )}
        <input 
          id="droparead-upload"
          type="file"
          accept={supportedFormats}
          onChange={handleFileChange}
          disabled={isLoading}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default DropArea;
