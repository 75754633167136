import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ExerciseState } from '../../types';

// Define types for button size and hierarchy
type ButtonSize = 'sm' | 'md' | 'lg' | 'lg-expand-mobile' | 'xl' | '2xl';
type ButtonHierarchy = 'primary' | 'secondaryGray' | 'tertiaryGray' | 'navbarItemBase' | 'correct' | 'wrong' | 'almost-correct' | 'icon-tertiary';

export const ExerciseStateToButtonHierarchy = (state: ExerciseState): ButtonHierarchy => {
  switch (state) {
    case 'correct':
      return 'correct';
    case 'almost-correct':
      return 'almost-correct';
    case 'wrong':
      return 'wrong';
    default:
      return 'primary';
  }
}

// Define prop types for the Button component
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  hierarchy?: ButtonHierarchy;
  content?: 'text' | 'icon';
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  isSelected?: boolean
}

const BaseButton = styled.button<ButtonProps>`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.shadow_xs};
  height: fit-content;
  outline: none;


  @media (max-width: 768px) {
    border-radius: 16px;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.background_disabled};
    border-color: ${({ theme }) => theme.border_disabled_subtle};
    cursor: not-allowed;
    color: ${({ theme }) => theme.text__text_placeholder_subtle};
    svg {
      opacity: 0.2;
    }

    &:hover {
      background-color: ${({ theme }) => theme.background_disabled};
      border-color: ${({ theme }) => theme.border_disabled_subtle};
      color: ${({ theme }) => theme.text__text_placeholder_subtle};
    }
  }



  ${({ size }) => {
    switch (size) {
      case 'sm':
        return css`
          padding: 8px 12px;
          font-size: 14px;
          gap: 4px;
        `;
      case 'lg':
        return css`
          padding: 10px 16px;
          font-size: 14px;
          gap: 6px;
        `;
      case 'lg-expand-mobile':
          return css`
            padding: 12px 18px;
            font-size: 16px;
            gap: 4px;
  
            @media (max-width: 768px) {
              width: 100%;
              padding: 16px 22px;
              font-size: 18px;
              gap: 10px;
              svg {
                height: 24px;
                width: 24px;
              }
            }
          `;
      case 'xl':
        return css`
          padding: 12px 18px;
          font-size: 16px;
          gap: 4px;
        `;
      case '2xl':
        return css`
          padding: 16px 22px;
          font-size: 18px;
          gap: 10px;
          svg {
            height: 24px;
            width: 24px;
          }
        `;
      default:
        return css`
          padding: 10px 14px;
          font-size: 14px;
          gap: 4px;
        `;
    }
  }}

  ${({ hierarchy, isSelected }) => {
    switch (hierarchy) {
      case 'primary':
        return css`
          background-color: ${({ theme }) => theme.buttons_primary_bg};
          color: ${({ theme }) => theme.buttons_primary_fg};
          border-color: ${({ theme }) => theme.buttons_primary_border};

          &:hover {
            background-color: ${({ theme }) => theme.buttons_primary_bg_hover};
            color: ${({ theme }) => theme.buttons_primary_fg_hover};
            border-color: ${({ theme }) => theme.buttons_primary_border_hover};
          }
        `;
      case 'secondaryGray':
        return css`
          background-color: ${({ theme }) => (isSelected ?  'rgba(21, 195, 177, 0.1)'  : theme.buttons_secondary_bg )};
          color: ${({ theme }) => theme.buttons_secondary_fg};
          border-color: ${({ theme }) => (isSelected ?  '#17C3B2' : theme.buttons_secondary_border)};

          &:hover {
            background-color: ${({ theme }) => (isSelected ?  'rgba(21, 195, 177, 0.10)'  : theme.buttons_secondary_bg_hover )};
            color: ${({ theme }) => theme.buttons_secondary_fg_hover};
            border-color: ${({ theme }) => (isSelected ?  '#17C3B2'  : theme.buttons_secondary_border_hover )};
          }
        `;
      case 'tertiaryGray':
        return css`
          background-color: ${({ theme }) => theme.buttons_tertiary_bg};
          color: ${({ theme }) => theme.buttons_tertiary_fg};
          border-color: transparent;
          box-shadow: none;

          &:hover {
            background-color: ${({ theme }) => theme.buttons_tertiary_bg_hover};
            border-color: ${({ theme }) => theme.buttons_tertiary_border_hover};
            color: ${({ theme }) => theme.buttons_tertiary_fg_hover};
          }
        `;
      case 'correct':
        return css`
          background-color: ${({ theme }) => theme.buttons_success_bg};
          color: ${({ theme }) => theme.buttons_success_fg};
          border-color: ${({ theme }) => theme.buttons_success_border};
          &:hover {
            background-color: ${({ theme }) => theme.buttons_success_bg_hover};
            border-color: ${({ theme }) => theme.buttons_success_border_hover};
            color: ${({ theme }) => theme.buttons_success_fg};
          }
        `;
      case 'wrong':
        return css`
          background-color: ${({ theme }) => theme.buttons_error_bg};
          color: ${({ theme }) => theme.buttons_error_fg};
          border-color: ${({ theme }) => theme.buttons_error_border};
          &:hover {
            background-color: ${({ theme }) => theme.buttons_error_bg_hover};
            border-color: ${({ theme }) => theme.buttons_error_border_hover};
            color: ${({ theme }) => theme.buttons_error_fg};
          }
        `;
      case 'almost-correct':
        return css`
          background-color: ${({ theme }) => theme.buttons_warning_bg};
          color: ${({ theme }) => theme.buttons_warning_fg};
          border-color: ${({ theme }) => theme.buttons_warning_border};
          &:hover {
            background-color: ${({ theme }) => theme.buttons_warning_bg_hover};
            border-color: ${({ theme }) => theme.buttons_warning_border_hover};
            color: ${({ theme }) => theme.buttons_warning_fg};
          }
        `;
      case 'icon-tertiary':
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 42px;
          height: 42px;
          padding: 0;
          background-color: ${({ theme }) => theme.buttons_tertiary_bg};
          color: ${({ theme }) => theme.buttons_tertiary_fg};
          border-color: transparent;
          box-shadow: none;

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.buttons_tertiary_bg_hover};
            border-color: ${({ theme }) => theme.buttons_tertiary_border_hover};
            color: ${({ theme }) => theme.buttons_tertiary_fg_hover};
          }
        `;
      case 'navbarItemBase':
          return css`
            display: flex; 
            justify-content: flex-start;
            gap: 12px;
            padding: 8px 12px;
            background-color: transparent;
            border-radius: 6px;
            color: ${({ theme }) => theme.text_secondary};
            font-weight: 500;

            &:hover {
                background-color: ${({ theme }) => theme.background_primary_hover};
            }

            svg {
              height: 24px;
              width: 24px;
            }
  
            &:hover {
              background-color: ${({ theme }) => theme.background_primary_hover};
              border-color: ${({ theme }) => theme.buttons_tertiary_border};
              color: ${({ theme }) => theme.buttons_tertiary_fg_hover};
            }
          `;
      default:
        return '';
    }
  }}
`;


const IconWrapper = styled.span`
  
`;

export const Button: React.FC<ButtonProps> = ({
  size = 'md',
  hierarchy = 'primary',
  content = 'text',
  leadingIcon,
  trailingIcon,
  children,
  ...rest
}) => {
  return (
    <BaseButton size={size} hierarchy={hierarchy} {...rest}>
      {leadingIcon && <IconWrapper>{leadingIcon}</IconWrapper>}
      {content === 'text' && hierarchy !== 'icon-tertiary' ? (
        <div style={{ padding: '0 2px' }}>{children}</div>
      ) : <>{children}</>}
      {trailingIcon && <IconWrapper>{trailingIcon}</IconWrapper>}
    </BaseButton>
  );
};