import { useCallback, useEffect, useState } from 'react';
import { saveTranslationPreferences } from '../../../../api/Messages';
import { TranslationTooltip } from '../../../../types';
import Tag from '../../../baseComponents/Tag';
import NewWordIcon from '../../../../assets/new_word.svg';
import HardWordIcon from '../../../../assets/hard_word.svg';
import ReviewWordIcon from '../../../../assets/mastered_word.svg';
import BlackListedIcon from '../../../../assets/blacklisted_word.svg';
import Checkbox from '../../../baseComponents/Checkbox';
import { useLanguage } from '../../../../languages/LanguageContext';
import { Loader } from '../../ChatPageStyles';
import Toast from '../../../baseComponents/Toast';
import TranslationsDetailsDiv from '../TranslationDetails';


interface TranslationTooltipDivProps {
  tooltip: TranslationTooltip;
  learnWordPreferences: { translation_id: number, isToLearn: boolean }[];
  setLearnWordPreferences: (learnWordPreferences: { translation_id: number, isToLearn: boolean }[]) => void;
}

const TranslationTooltipDiv = ({ tooltip, learnWordPreferences, setLearnWordPreferences }: TranslationTooltipDivProps) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);
  const [isCheckboxClickable, setIsCheckboxClickable] = useState<boolean>(true);
  const [tagText, setTagText] = useState<string | undefined>(undefined);
  const [tagIcon, setTagIcon] = useState<string | undefined>(undefined);
  const originalTag = tooltip.bookExercise?.tag || "no_tag";

  const updateTag = (tag: string, isToLearn: boolean, isInBlackList: boolean) => {
    // isBlackListed is required since  the book exercise tags are not updated, while learnWordPreferences are
    // console.log("updateTag", tag, "isToLearn", isToLearn, "isInBlackList", isInBlackList);
    
    if ((tag === "blacklisted_word" || isInBlackList) && !isToLearn) {
      setTagText("Blacklisted");
      setTagIcon(BlackListedIcon);
    } else if (tag === "no_tag") {
      setTagText(undefined);
      setTagIcon(undefined);
    } else if (tag === "new_word" || (tag === "blacklisted_word" && isToLearn)) {
      setTagText("New word");
      setTagIcon(NewWordIcon);
    } else if (tag === "hard_word") {
      setTagText("Hard word");
      setTagIcon(HardWordIcon);
    } else if (tag === "mastered_word") {
      setTagText("Mastered word");
      setTagIcon(ReviewWordIcon);
    }
  }

  const getLearnWordPreference = useCallback(() => {
    const learnWordPreference = learnWordPreferences.find(
      (learnWordPreference: any) => learnWordPreference.translation_id === tooltip.bookExercise?.translation_id
    );
    if (learnWordPreference) {
      return { isToLearn: learnWordPreference.isToLearn, isInBlackList: !learnWordPreference.isToLearn };
    } else {
      return { isToLearn: tooltip.bookExercise?.has_uku && tooltip.bookExercise?.tag !== "blacklisted_word", isInBlackList: false };
    }
  }, [learnWordPreferences, tooltip]);

  useEffect(() => {
    if (tooltip.isVisible && tooltip.bookExercise?.translation_id !== undefined && isChecked === undefined) {
      const { isToLearn, isInBlackList } = getLearnWordPreference();
      updateTag(tooltip.bookExercise.tag, isToLearn || false, isInBlackList);
      setIsChecked(isToLearn);
    } else if (!tooltip.isVisible) {
      setIsChecked(undefined);
      setShowToast(false);
    }
  }, [tooltip, isChecked, getLearnWordPreference]);

  const onChange = async () => {
    if (tooltip.bookExercise?.translation_id && isCheckboxClickable) {
      // Prevent multiple clicks before the uku preferences are saved
      setIsCheckboxClickable(false);
      
      let isToLearn = !isChecked;

      setIsChecked(isToLearn);
      updateTag(originalTag, isToLearn, !isToLearn);

      let newLearnWordPreferences = learnWordPreferences.filter(
        (learnWordPreference: any) => learnWordPreference.translation_id !== tooltip.bookExercise?.translation_id
      );
      newLearnWordPreferences.push({ translation_id: tooltip.bookExercise.translation_id, isToLearn: isToLearn });
      setLearnWordPreferences(newLearnWordPreferences);

      await saveTranslationPreferences(tooltip.user_id, tooltip.bookExercise.translation_id, tooltip.chat_id, isToLearn);

      setShowToast(true);
      setIsCheckboxClickable(true);
    } else {
      console.error("Word translation id is not defined");
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (!tooltip.isVisible) {
    return null; // Tooltip is destroyed when not visible
  }

  console.log('tooltip', tooltip);

  return (
    <div
      className={`absolute bg-tooltip-bg shadow-xs py-2 rounded-md z-50 max-w-[350px] flex flex-col
        ${tooltip.position.top ? `top-[${tooltip.position.top}px]` : ''}
        ${tooltip.position.bottom ? `bottom-[${tooltip.position.bottom}px]` : ''}
        ${tooltip.position.left ? `left-[${tooltip.position.left}px]` : ''}
        ${tooltip.position.right ? `right-[${tooltip.position.right}px]` : ''}
      `}
      onMouseUp={handleMouseUp}
    >
      {tooltip.isLoading ? (
        <div className="px-2"><Loader /></div>
      ) : (
        <>
          <div className="flex flex-col gap-1 px-2">
            {(tooltip.bookExercise?.translation_id && tagText) && <Tag text={tagText} icon={tagIcon} />}
            <TranslationsDetailsDiv
              bookExercise={tooltip.bookExercise}
              googleTranslation={tooltip.googleTranslation}
              size="small"
              error_message={tooltip.error_message}
            />
          </div>
          {tooltip.bookExercise?.translation_id && (
            <div className="border-t border-border-tertiary mt-1 pt-1">
              <div className="flex gap-2 px-2 items-center text-sm">
                {isChecked !== undefined && <Checkbox onChange={onChange} checked={isChecked} />}
                {getTextLangFrom('tooltip-want-to-learning')}
              </div>
            </div>
          )}
        </>
      )}
      <Toast
        title={isChecked ? getTextLangFrom('tooltip-toast-add-word-title') : getTextLangFrom('tooltip-toast-remove-word-title')}
        subtitle={isChecked ? getTextLangFrom('tooltip-toast-add-word-text') : getTextLangFrom('tooltip-toast-remove-word-text')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </div>
  );
};

export default TranslationTooltipDiv;