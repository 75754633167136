import React from 'react';
import { Card, ExerciseState } from '../../../types';

interface MultipleChoiceOptionsProps {
  cards: Card[];
  selectedAnswer: Card | null;
  correctAnswer: Card | null;
  exerciseState: ExerciseState;
  onButtonClick: (card: Card) => Promise<void>;
}

const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({
  cards,
  selectedAnswer,
  correctAnswer,
  exerciseState,
  onButtonClick,
}) => (
  <div
    className={`flex ${cards.length <= 4 ? 'flex-col' : 'flex-wrap'} gap-4 min-h-[270px]`}
  >
    {cards.map((card, index) => (
      <button
        key={index}
        onClick={() => onButtonClick(card)}
        className={`multiple-choice-button-base 
          ${selectedAnswer ? 'cursor-not-allowed' : 'multiple-choice-button-hover'} 
          ${correctAnswer === card && selectedAnswer ? ('bg-green-900 border-green-900 text-green-900' 
          ) : (selectedAnswer === card && exerciseState === 'wrong' ? ( 'bg-red-900 border-red-900 text-red-900' 
          ) : 'multiple-choice-button-base-colors')}
        `}
        disabled={selectedAnswer !== null}
      >
        <div className="w-8 h-8 flex items-center justify-center text-base font-semibold rounded-full bg-bg-secondary opacity-50">
          {index + 1}
        </div>
        {card.lemma_lang_to}
      </button>
    ))}
  </div>
);

export default MultipleChoiceOptions;
