import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../baseComponents/Button';
import { MainLayout } from '../baseComponents/Layout';
import { NavbarBackAndGameProgress } from '../baseComponents/ProgressBar';
import ChevronRightWhite from '../../assets/chevron-right-white.svg';
import ChevronRightSecondary from '../../assets/chevron-left-tertiary.svg';
import { useLanguage } from '../../languages/LanguageContext';
import FooterControls from '../baseComponents/FooterControls';
import { OnboardingData, User } from '../../types';
import { useAuth } from '../../authentification/AuthContext';

interface OnboardingLayoutProps {
    step: number;
    children: React.ReactNode;
    isActionButtonDisabled?: boolean;
    isFullHeight?: boolean;
}

const stepDirectories = ['/onboarding-welcome', '/onboarding-language', '/onboarding-level', '/library'];

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({ step, children, isActionButtonDisabled, isFullHeight }) => {
    const navigate = useNavigate();
    const { getTextLangFrom } = useLanguage();
    const location = useLocation();
    const onboardingState = location.state?.onboardingState as OnboardingData;
    const {user, updateUser} = useAuth();

    const handleButtonClick = async (directory: string) => {
        // console.log("onboardingState", onboardingState);
        if (user && directory === '/library') {
            const updatedUser = { 
                ...user, 
                status: 'active-user',
                lang_to: onboardingState.language,
                cefr_level: onboardingState.cefr_level,
            } as User;
            await updateUser(updatedUser);
        }
        navigate(directory, { state: { onboardingState } });
    }
  
    useEffect(() => {
      if (!onboardingState) {
        location.state = { onboardingState: {} as OnboardingData };
      }
    }, [location, onboardingState]);

    //console.log("onboardingState", onboardingState);

    return (
        <MainLayout hasNavbar={false} isFullHeight={isFullHeight} hasFooterControls={true}>
            <NavbarBackAndGameProgress session_progress={step * 25} onBackClick={() => navigate('/login')} />
            <div className="flex flex-col items-center gap-10 max-w-[560px] ml-auto mr-auto flex-1 w-full">
                {children}
            </div>
            <FooterControls>
                <div className="footer-controls-action-div">
                    { step !== 1 && (
                    <Button
                        variant="secondary"
                        size="control"
                        leadingIcon={ChevronRightSecondary}
                        onClick={() => handleButtonClick(stepDirectories[step - 2])}
                        text={getTextLangFrom('word-back')}
                    />
                    )}
                    <Button
                        size="control"
                        trailingIcon={ChevronRightWhite}
                        onClick={() => handleButtonClick(stepDirectories[step])}
                        text={getTextLangFrom('word-next')}
                        disabled={isActionButtonDisabled}
                    />
                </div>
            </FooterControls>
        </MainLayout>
    );
};

export default OnboardingLayout;