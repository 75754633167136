// FlashCardPage.tsx

import React, { useEffect } from 'react';
import OnboardingLayout from './OnboardingLayout';
import AvatarWriteImage from '../../assets/multiple-choice-bot.svg';
import { OnboardingOption } from '../../types';
import { CellScore } from '../profilePage/TableCellStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnboardingData } from '../../types';

interface OnboardingProps {
  step: number;
  title: string;
  onboardingOptions: OnboardingOption[];
}

const OnboardingMultipleChoiceTemplate: React.FC<OnboardingProps> = ({ step, title, onboardingOptions }) => {
  const [selectedOptionCode, setSelectedOptionCode] = React.useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const onboardingState = location.state?.onboardingState as OnboardingData;

  useEffect(() => {
    console.log('onboardingState', onboardingState);
    if (step === 2 && onboardingState?.language) {
      setSelectedOptionCode(onboardingState.language);
    } else if (step === 3 && onboardingState?.cefr_level) {
      setSelectedOptionCode(onboardingState.cefr_level);
    }
  }, [onboardingState, step, navigate]);

  const handleSelectAnswer = (option: OnboardingOption) => {
    // console.log('option', option);
    setSelectedOptionCode(option.code);
    location.state = { onboardingState: { 
      ...onboardingState, 
      ...(step === 2 ? { language: option.code } : { cefr_level: option.code }) 
    } as OnboardingData };
  }

  return (
    <OnboardingLayout step={step} isActionButtonDisabled={selectedOptionCode === null}>
      <div className="flex items-center gap-6 w-full">
        <h1 className=" font-semibold w-full leading-relaxed text-2xl md:text-4xl">{title}</h1>
        <img src={AvatarWriteImage} alt="Onboarding" className="w-full max-w-[90px] md:max-w-[150px]" />
      </div>
      <div  className='flex flex-col w-full gap-4'>
        {onboardingOptions.map((option, index) => (
          <button
            key={index}
            onClick={() => handleSelectAnswer(option)}
            className={`multiple-choice-button-base
              ${selectedOptionCode === option.code ? 'multiple-choice-button-active' : 'multiple-choice-button-base-colors multiple-choice-button-hover'} 
            `}
          >
            <div className="flex items-center pl-4 gap-4 text-base">
            {step === 3 ? (
                <CellScore score={index - 1} />
              ) : (
                <span className="text-4xl">{option.icon}</span>
              )}
              {option.text}
            </div>
          </button>
        ))}
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingMultipleChoiceTemplate;
