import React, { useRef } from 'react';
import {
  ChatPageWrapperWithNavbar,
  AvatarDiv,
  AvatarImage,
  MessageDiv,
} from '../ChatPageStyles';
import { User, Message} from '../../../types';
import ChatBar from './ChatBar';
import BotImage from '../../../assets/chat_bot.svg';
import exerciseText from './ExerciseText';
import { NavbarBackProgressVoice } from '../../baseComponents/ProgressBar';
import { MainLayout } from '../../baseComponents/Layout';

const ChatExercise: React.FC<{
  user: User | null;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  chat_id: number | null;
  agent_id: number | null;
  wrapperHeight: number;
  game_mode:  'book-chat-mode' | 'chat-mode';
  handleBack: () => void;
  handleVoiceModeClick: () => void;
  handleResumeSynthesizer: () => void;
  handleInterruptSynthesizer: () => void;
  isRecording: boolean;
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSpeechOn: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTTSMessageIndex: React.Dispatch<React.SetStateAction<number | null>>;
  audioElementsRef: React.MutableRefObject<HTMLAudioElement[]>;
  currentTTSMessageIndex: number | null;
}> = ({
  user,
  messages,
  setMessages,
  chat_id,
  agent_id,
  wrapperHeight,
  game_mode,
  handleBack,
  handleVoiceModeClick,
  handleResumeSynthesizer,
  handleInterruptSynthesizer,
  isRecording,
  setIsRecording,
  isLoading,
  setIsLoading,
  setIsSpeechOn,
  setCurrentTTSMessageIndex,
  audioElementsRef,
  currentTTSMessageIndex,
}) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  return (
    <MainLayout
      hasNavbar={false}
      hasFooterControls={false}
      // style={{ height: wrapperHeight }} 
    >
      <NavbarBackProgressVoice
        onBackClick={handleBack}
        handleVoiceModeClick={handleVoiceModeClick}
        game_mode={game_mode}
        user={user}
        handleResumeSynthesizer={handleResumeSynthesizer}
        handleInterruptSynthesizer={handleInterruptSynthesizer}
      />
      <div className="chat-wrapper">
        <div className="chat-inner-wrapper">
          <div className="chat-scroll-wrapper" ref={messagesContainerRef}>
            <div className="chat-scroll-inner-div">
              {messages.map((message, index) => (
                <MessageDiv
                  key={index}
                  message_role={message.message_role}
                  game_mode={game_mode}
                  transition={index === messages.length - 1 && message.message_role === 'user'}
                  isreadingthismessage={index === currentTTSMessageIndex}
                  data-tip
                  data-for="tooltip"
                >
                  {message.message_role !== 'user' && game_mode === 'chat-mode' ? (
                    <AvatarDiv>
                      <AvatarImage src={BotImage} alt="Avatar" />
                    </AvatarDiv>
                  ) : null}
                  {message.message_role === 'user' ? (
                    exerciseText(message.text, game_mode, user?.theme || 'light')
                  ) : (
                    message.text
                  )}
                </MessageDiv>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ChatBar
        game_mode={game_mode}
        chat_id={chat_id}
        agent_id={agent_id}
        messages={messages}
        setMessages={setMessages}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setIsSpeechOn={setIsSpeechOn}
        setCurrentTTSMessageIndex={setCurrentTTSMessageIndex}
        audioElementsRef={audioElementsRef}
        user={user}
      />
    </MainLayout>
  );
};

export default ChatExercise;