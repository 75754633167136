import React from 'react';

interface TagProps {
  text: string;
  icon?: string;
  isBrandColor?: boolean;
}

const Tag: React.FC<TagProps> = ({ isBrandColor, text, icon }) => {
  return (
    <div
      className={`flex items-center gap-1.5 text-xs font-medium rounded-full px-2 py-0.5 whitespace-nowrap ${
        isBrandColor
          ? 'text-text-primary bg-bg-brand border border-border-brand'
          : 'text-text-tertiary bg-bg-tertiary border border-border-primary'
      }`}
      style={{ width: 'fit-content' }}
    >
      {icon && <img src={icon} alt="tag" className="w-3 h-3" />}
      {text}
    </div>
  );
};

export default Tag;
