// MultipleChoicePage.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeGame, fetchGameCard, evaluateExerciseState, terminateGame } from '../../../api/Games';
import MultipleChoiceOptions from './MultipleChoiceOptions';
import MultipleChoiceControls from './MultipleChoiceControls';
import { Card, ExerciseState } from '../../../types';
import { useAuth } from '../../../authentification/AuthContext';
import useMultipleChoiceKeyboardControl from './useMultipleChoiceKeyboardControls';
import HeaderWithBubble from '../HeaderWithBubble';
import { NavbarBackAndGameProgress } from '../../baseComponents/ProgressBar';
import { MainLayout } from '../../baseComponents/Layout';

const shuffleArray = (array: any[]) => {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

const MultipleChoicePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [cards, setCards] = useState<Card[]>([]);
  const [mainCard, setMainCard] = useState<Card>({} as Card);
  const [selectedAnswer, setSelectedAnswer] = useState<Card | null>(null);
  const [exerciseState, setExerciseState] = useState<ExerciseState>('unanswered');
  const [correctAnswer, setCorrectAnswer] = useState<Card | null>(null);
  const [correctAnswerText, setCorrectAnswerText] = useState<string>('');
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState<boolean>(true);
  const isInitialized = useRef(false);
  const locationState = location.state?.gameData;

  useEffect(() => {
    if (!locationState) {
      navigate('/exercises');
      return;
    }

    const { game_mode, exercise_type, initial_card_s, chat_id } = locationState;

    const fetchInitialCard = async () => {
      if (isInitialized.current) return;
      isInitialized.current = true;

      try {
        if (user) {
          let fetchedCards: Card[] = [];
          if (game_mode === 'multiple-choice-mode') {
            fetchedCards = await initializeGame<Card[]>({
              user,
              game_mode,
              exercise_type,
              chat_id,
            });
          }
          if (fetchedCards.length === 0) {
            navigate('/exercises');
          } else {
            setMainCard(fetchedCards[0]);
            setCards(shuffleArray(fetchedCards));
          }
        }
      } catch (error) {
        console.error('Error fetching initial card:', error);
      }
    };

    fetchInitialCard();
  }, [locationState, user, navigate]);

  const fetchNextCards = useCallback(async () => {
    const { game_mode } = locationState;
    try {
      const fetchedCards = await fetchGameCard<Card[]>(game_mode, mainCard);
      if (fetchedCards.length === 0) {
        const { accuracy, session_duration } = await terminateGame(mainCard?.session_id || 0, game_mode);
        navigate('/endgame', { state: { gameData: { ...locationState, accuracy, session_duration } } });
      } else {
        setMainCard(fetchedCards[0]);
        setCards(shuffleArray(fetchedCards));
        setSelectedAnswer(null);
        setExerciseState('unanswered');
        setCorrectAnswer(null);
        setCorrectAnswerText('');
      }
    } catch (error) {
      console.error('Error fetching next cards:', error);
    }
  }, [locationState, navigate, mainCard]);

  const handleButtonClick = useCallback(async (selectedCard: Card) => {
    setSelectedAnswer(selectedCard);
    const state = await evaluateExerciseState(selectedCard.lemma_lang_from, mainCard.lemma_lang_from);
    mainCard.score = state === 'correct' ? true : false;
    setExerciseState(state);
    const correctCard = cards.find(card => card.lemma_lang_from === mainCard.lemma_lang_from);
    if (correctCard) {
      setCorrectAnswer(correctCard);
      setCorrectAnswerText(correctCard.lemma_lang_to || '');
    }
    setIsActionButtonDisabled(false);
  }, [cards, mainCard]);

  const handleNextButtonClick = useCallback(async () => {
    await fetchNextCards();
    setIsActionButtonDisabled(true);
  }, [fetchNextCards]);

  useMultipleChoiceKeyboardControl(selectedAnswer, cards, (index: number) => handleButtonClick(cards[index]), handleNextButtonClick);

  return (
    <MainLayout hasNavbar={false} hasFooterControls={true}>
      <NavbarBackAndGameProgress 
        session_progress={mainCard?.session_progress ?? 0}
        onBackClick={() => navigate('/exercises')}
      />
      <HeaderWithBubble
        card={mainCard || undefined}
        user={user || undefined}
      />
      <MultipleChoiceOptions 
        cards={cards} 
        selectedAnswer={selectedAnswer} 
        correctAnswer={correctAnswer} 
        exerciseState={exerciseState} 
        onButtonClick={handleButtonClick} 
      />
      <MultipleChoiceControls 
        exerciseState={exerciseState} 
        card={mainCard}
        isActionButtonDisabled={isActionButtonDisabled}
        onNextButtonClick={handleNextButtonClick} 
      />
    </MainLayout>
  );
};

export default MultipleChoicePage;
