import React, { Dispatch, SetStateAction } from 'react';
import Tag from '../baseComponents/Tag';
import { BookDetails } from '../../types';
import { useNavigate } from 'react-router-dom';
import { handleStartGameClick } from '../baseComponents/startGame';
import DropDownCustom from '../baseComponents/DropDownCustom';
import BookCardProgress from './BookCardProgress';

interface BookCardProps {
  book: BookDetails;
  gameMode: string;
  user: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedOption: (option: string) => void;
  setSelectedBookId: (id: number) => void;
}

const BookCard: React.FC<BookCardProps> = ({
  book,
  gameMode,
  user,
  setIsLoading,
  setSelectedOption,
  setSelectedBookId,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex relative">
      <button
        className={`flex-1 flex p-2 rounded-md transition duration-200
            hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:text-buttonTertiary-fgHover`}
        onClick={() => {
          if (book.progress === 100) {
            setSelectedBookId(book.id);
            setSelectedOption('Navigate');
          } else {
            handleStartGameClick(gameMode, undefined, navigate, user, setIsLoading, book.chat_id, book.id);
          }
        }}
      >
        <div className="flex flex-row gap-4">
          <div className="flex h-32 w-24"
          >
            <div className={`relative flex flex-col items-center justify-start p-3 gap-2 rounded-sm shadow-md overflow-hidden w-full h-full
                ${!book.cover && 'bg-bg-secondary'}`}
              style={{ 
                backgroundImage: `url(${book.cover})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              {!book.cover && (
                <>
                  <h3 className="text-[10px] font-semibold text-text-secondary uppercase">{book.title}</h3>
                  <p className="text-[8px] font-normal text-text-tertiary">{book.author}</p>
                </>
              )}
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-1 text-left">
            <h2 className="text-lg font-semibold text-text-secondary">{book.title}</h2>
            <p className="text-md font-normal text-text-tertiary">{book.author}</p>
              {book.progress === 100 ? (
                <Tag isBrandColor text="Completed" />
              ) : book.progress !== 0 && book.progress !== undefined ? (
                <div className='flex gap-2'>
                    <BookCardProgress progress={book.progress} />
                    <span>{book.progress}% read</span>
                </div >
              ) : (
                <></>
              )}
          </div>
        </div>
      </button>
      <DropDownCustom
        options={book.uploader_id ? ['Navigate', 'Edit', 'Delete'] : ['Navigate']}
        setSelectedOption={setSelectedOption}
        id={book.id}
        setSelectedId={setSelectedBookId}
      />
    </div>
  );
};

export default BookCard;