import { TranslationTooltip } from "../../../../types";

const setTooltipPosition = (tooltip: TranslationTooltip, rect?: DOMRect, event?: React.MouseEvent<HTMLSpanElement>) => {
  const maxTooltipWidth = 350;

  if (rect) {
      tooltip.position.top = rect.bottom + 10;

      if (rect.left + maxTooltipWidth > window.innerWidth) {
          tooltip.position.right = window.innerWidth - rect.right + window.scrollX;
      } else {
          tooltip.position.left = rect.left + window.scrollX;
      }
  } else if (event) {
      tooltip.position.top = event.clientY + window.scrollY + 16;
      
      // If the click is on the right side of the screen
      if (event.clientX > window.innerWidth / 2) {
        tooltip.position.right = window.innerWidth - event.clientX + window.scrollX;
      } else {
        tooltip.position.left = event.clientX + window.scrollX;
      }
  }

  return tooltip;
}

export default setTooltipPosition;