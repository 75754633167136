// src/themes.ts
import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  background_primary: '#ffffff',
  background_primary_hover: '#F9FAFB',
  background_secondary: '#F9FAFB',
  background_tertiary: '#F2F4F7',
  background_overlay: 'rgba(12, 17, 29, 0.7)',
  background_brand: '#17C3B2',

  background_disabled: '#F2F4F7',
  background_disabled_subtle: '#F9FAFB',
  text__text_disabled: '#667085',
  border_disabled: '#D0D5DD',
  border_disabled_subtle: '#EAECF0',
  
  border_primary: '#D0D5DD',
  border_secondary: '#EAECF0', 
  border_tertiary: '#F2F4F7',
  border_brand: '#58E6D1',
  
  icon__featured_icon_modern_border: '#EAECF0',

  text_primary: '#101828',
  text_secondary: '#344054',
  text_tertiary: '#475467',
  text_quaternary: '#667085',
  text__text_placeholder: '#667085',
  text__text_placeholder_subtle: '#D0D5DD',
  text_brand: '#1CA0A8',
  
  buttons_primary_bg: '#15C3B1',
  buttons_primary_border: '#15C3B1',
  buttons_primary_fg: '#FFFFFF',
  buttons_primary_bg_hover: '#1A9EA3',
  buttons_primary_border_hover: '#1A9EA3',
  buttons_primary_fg_hover: '#FFFFFF',
  
  buttons_secondary_bg: '#F9FAFB',
  buttons_secondary_border: '#EAECF0',
  buttons_secondary_fg: '#344054',
  buttons_secondary_bg_hover: '#EAECF0',
  buttons_secondary_border_hover: '#D0D5DD',
  buttons_secondary_fg_hover: '#182230',

  buttons_tertiary_bg: 'transparent',
  buttons_tertiary_border: 'transparent',
  buttons_tertiary_fg: '#475467',
  buttons_tertiary_bg_hover: '#F9FAFB',
  buttons_tertiary_border_hover: 'transparent',
  buttons_tertiary_fg_hover: '#344054',
  buttons_tertiary_bg_selected: 'rgba(21, 195, 177, 0.2)',

  buttons_success_bg: '#3F7A9A',
  buttons_success_bg_hover: '#0B4B6C',
  buttons_success_border: '#3F7A9A',
  buttons_success_border_hover: '#0B4B6C',
  buttons_success_fg: '#FFFFFF',
  
  buttons_error_bg: '#E44F50',
  buttons_error_bg_hover: '#9D3122',
  buttons_error_border: '#E44F50',
  buttons_error_border_hover: '#9D3122',
  buttons_error_fg: '#FFFFFF',

  buttons_warning_bg: '#FFCB77',
  buttons_warning_bg_hover: '#FFCB77',
  buttons_warning_border: '#FFCB77',
  buttons_warning_border_hover: '#FFCB77',
  buttons_warning_fg: '#FFFFFF',

  sussess_700_fg: '#17C3B2',
  success_500_fg: '#3F7A9A',
  success_300_fg: '#3F7A9A',
  success_100_bg: '#DCFAE6',
  success_border: '#3F7A9A',
  success_light_bg: '#E8F9F7',
  error_700_fg: '#FE6D73',
  error_500_fg: '#E44F50',
  error_300_fg: '#E44F50',
  error_100_bg: '#FEE4E2',
  error_border: '#E44F50',
  error_light_bg: '#FCEDED',
  warning_700_fg: '#D3A04A',
  warning_500_fg: '#FFCB77',
  warning_300_fg: '#FFCB77',
  warning_100_bg: '#FFF4E5',
  warning_border: '#FFCB77',
  warning_light_bg: '#FFFAF1',

  brand_color_1: '#227C9D',
  brand_color_2: '#1DA0A8',
  brand_color_3: '#17C3B2',
  brand_color_4: '#FFCB77',
  brand_color_5: '#FFE2B3',
  brand_color_6: '#FEF9EF',
  brand_color_7: '#FEB3B1',
  brand_color_8: '#FE6D73',

  breadcrumb_fg: '#475467',
  breadcrumb_fg_hover: '#344054',
  breadcrumb_bg_hover: '#F9FAFB',

  ring_brand_xs: '0 0 0 4px rgba(21, 195, 177, 0.24)',

  shadow_xs: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
  shadow_sm: '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)',
  shadow_md: '0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06)',
  shadow_lg: '0 12px 16px -4px rgba(16, 24, 40, 0.1), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',

  chat_primary_bg: '#FBFFFE',
  chat_primary_fg: '#344054',
  chat_secondary_fg: '#0B4B6C',
  chat_message_bg: '#CDF3EF',
  user_message_highlight: '#A9ECE5',
  chat_border_input: 'rgba(28, 160, 168, 0.24)',
  chat_border_input_dark: 'rgba(28, 160, 168, .8)',

  new_card_end_bg_gradient: '#CFF2EF',

  tab_selected_bg: 'rgba(28, 160, 168, 0.1)',
  tab_selected_fg: '#17C3B2',
  tab_hover_bg: '#EAECF0',

  tooltip_bg : '#EAECF0',
  tooltip_fg : '#0B4B6C',
};

export const darkTheme: DefaultTheme = {
  background_primary: '#1E293B',
  background_primary_hover: '#283448',
  background_secondary: '#283448',
  background_tertiary: '#212F45',
  background_overlay: 'rgba(12, 17, 29, 0.7)',
  background_brand: '#0B7285',

  background_disabled: '#212F45',
  background_disabled_subtle: '#283448',
  text__text_disabled: '#55657D',
  border_disabled: '#364760',
  border_disabled_subtle: '#2B394E',
  
  border_primary: '#364760',
  border_secondary: '#2B394E', 
  border_tertiary: '#212F45',
  border_brand: '#1DA0A8',
  
  icon__featured_icon_modern_border: '#2B394E',

  text_primary: '#F0F4F8',
  text_secondary: '#C9D5E0',
  text_tertiary: '#919EAB',
  text_quaternary: '#55657D',
  text__text_placeholder: '#7E96B3',
  text__text_placeholder_subtle: '#55657D',
  text_brand: '#17C3B2',
  
  buttons_primary_bg: '#0B7285',
  buttons_primary_border: '#0B7285',
  buttons_primary_fg: '#FFFFFF',
  buttons_primary_bg_hover: '#166D7A',
  buttons_primary_border_hover: '#166D7A',
  buttons_primary_fg_hover: '#FFFFFF',
  
  buttons_secondary_bg: '#283448',
  buttons_secondary_border: '#2B394E',
  buttons_secondary_fg: '#C9D5E0',
  buttons_secondary_bg_hover: '#2B394E',
  buttons_secondary_border_hover: '#2B394E',
  buttons_secondary_fg_hover: '#F0F4F8',

  buttons_tertiary_bg: 'transparent',
  buttons_tertiary_border: 'transparent',
  buttons_tertiary_fg: '#919EAB',
  buttons_tertiary_bg_hover: '#283448',
  buttons_tertiary_border_hover: 'transparent',
  buttons_tertiary_fg_hover: '#C9D5E0',
  buttons_tertiary_bg_selected: 'rgba(21, 195, 177, 0.2)',

  buttons_success_bg: '#0B4B6C',
  buttons_success_bg_hover: '#3F7A9A',
  buttons_success_border: '#0B4B6C',
  buttons_success_border_hover: '#3F7A9A',
  buttons_success_fg: '#FFFFFF',
  
  buttons_error_bg: '#9D3122',
  buttons_error_bg_hover: '#E44F50',
  buttons_error_border: '#9D3122',
  buttons_error_border_hover: '#E44F50',
  buttons_error_fg: '#FFFFFF',

  buttons_warning_bg: '#D3A04A',
  buttons_warning_bg_hover: '#FFCB77',
  buttons_warning_border: '#D3A04A',
  buttons_warning_border_hover: '#FFCB77',
  buttons_warning_fg: '#FFFFFF',

  success_700_fg: '#17C3B2',
  success_500_fg: '#DCFAE6',
  success_300_fg: '#DCFAE6',
  success_100_bg: '#0B4B6C',
  success_border: '#DCFAE6',
  success_light_bg: '#1F3845',
  error_700_fg: '#FE6D73',
  error_500_fg: '#FEE4E2',
  error_300_fg: '#FEB3B1',
  error_100_bg: '#9D3122',
  error_border: '#FEE4E2',
  error_light_bg: '#342C3B',
  warning_700_fg: '#FFCB77',
  warning_500_fg: '#FFE2B3',
  warning_300_fg: '#FFE2B3',
  warning_100_bg: '#FFCB77',
  warning_border: '#FFE2B3',
  warning_light_bg: '#36383F',

  brand_color_1: '#227C9D',
  brand_color_2: '#1DA0A8',
  brand_color_3: '#17C3B2',
  brand_color_4: '#FFCB77',
  brand_color_5: '#FFE2B3',
  brand_color_6: '#FEF9EF',
  brand_color_7: '#FEB3B1',
  brand_color_8: '#FE6D73',

  breadcrumb_fg: '#919EAB',
  breadcrumb_fg_hover: '#F0F4F8',
  breadcrumb_bg_hover: '#283448',


  ring_brand_xs: '0 0 0 4px rgba(21, 195, 177, 0.24)',

  shadow_xs: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
  shadow_sm: '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)',
  shadow_md: '0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06)',
  shadow_lg: '0 12px 16px -4px rgba(16, 24, 40, 0.1), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',

  chat_primary_bg: '#1E293B',
  chat_primary_fg: '#C9D5E0',
  chat_secondary_fg: '#FFFFFF', 
  chat_message_bg: '#293449',
  user_message_highlight: '#1E293B',

  chat_border_input: 'rgba(28, 160, 168, 0.24)',
  chat_border_input_dark: 'rgba(28, 160, 168, 0.8)',

  new_card_end_bg_gradient: '#0B4B6C',

  tab_selected_bg: 'rgba(28, 160, 168, 0.1)',
  tab_selected_fg: '#17C3B2',
  tab_hover_bg: '#2B394E',

  tooltip_bg: '#0D1119',
  tooltip_fg: '#C9D5E0',
};