import { useNavigate } from 'react-router-dom';
import { initializeGame } from '../../api/Games';
import { Card, GameState, Message } from '../../types';
import { startBook } from '../../api/Books';

export const handleStartGameClick = async (
  game_mode: string,
  exercise_type: string | undefined,
  navigate: ReturnType<typeof useNavigate>,
  user: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  chat_id?: number,
  book_id?: number,
  chapter_part_id?: number
  ) => {
    if (!user) {
        navigate('/library');
        return null;
    }

    setIsLoading(true);

    let game_url = '';
    let initial_card_s: Card | Card[] | undefined = undefined
    let initial_message_s: Message[] | undefined = undefined

    if (game_mode === 'start-new-book' || game_mode === 'resume-book') {
        game_url = '/book-mode';
        const gameDataStart: GameState = {
            user_id: user.id,
            game_url: game_url,
            book_id: book_id,
            chat_id: chat_id,
            chapter_part_id: chapter_part_id,
        };
        console.log('gameDataStart', gameDataStart);
        const bookResponse = await startBook(gameDataStart);
        initial_message_s = bookResponse.initial_message_s;
        game_mode = 'book-chat-mode';            
    } else if (exercise_type && game_mode === 'multiple-choice-mode') {
        game_url = '/multiple-choice-mode';
        const tempGameData = await initializeGame<Card | Card[]>({
            user: user,
            game_mode: game_mode,
            exercise_type: exercise_type,
            chat_id: chat_id,
        });
        initial_card_s = tempGameData as Card[];

    } else if (exercise_type && game_mode === 'flashcard-mode') {
        game_url = '/flashcard-mode';
        const tempGameData = await initializeGame<Card | Card[]>({
            user: user,
            game_mode: game_mode,
            exercise_type: exercise_type,
            chat_id: chat_id,
        });
        initial_card_s = tempGameData as Card;

    } else if (exercise_type && game_mode === 'write-mode') {
        game_url = '/write-mode';
        const tempGameData = await initializeGame<Card | Card[]>({
            user: user,
            game_mode: game_mode,
            exercise_type: exercise_type,
            chat_id: chat_id,
        });
        initial_card_s = tempGameData as Card;

    } else if (game_mode === 'chat-mode') {
        game_url = '/chat-mode';
    } else {
        console.log('Invalid game mode', game_mode);
        setIsLoading(false);
        navigate('/library');
    }
    const gameData: GameState = {
        user_id: user.id,
        game_mode: game_mode,
        game_url: game_url,
        exercise_type: exercise_type,
        chat_id: chat_id,
    };
    if ( initial_card_s ) {
        gameData.initial_card_s = initial_card_s as Card[];
    }
    if ( initial_message_s) {
        gameData.initial_message_s = initial_message_s as Message[];
    }
    setIsLoading(false);
    navigate(game_url, { state: { gameData } });
  };