import React from 'react';
import SearchIcon from '../../assets/search-lg.svg';
import { useLanguage } from '../../languages/LanguageContext';

type SearchBarProps = {
  filter: string;
  setFilter: (filter: string) => void;
  placeholder?: string;
  className?: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ filter, setFilter, placeholder, className }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className={`flex flex-col gap-1.5 mr-auto ${className}`}>
      <div className="relative">
        <img
          src={SearchIcon}
          alt="Search"
          className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 h-5"
        />
        <input
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={placeholder || getTextLangFrom('SearchBar-placeholder')}
          className={`w-full py-2.5 pl-11 pr-4 border border-border-secondary rounded-lg shadow-sm bg-transparent text-text-primary 
            font-medium focus:border-brand focus:outline-none focus:shadow-brand-xs 
            disabled:text-text-disabled disabled:border-disabled disabled:cursor-not-allowed 
            placeholder:text-placeholder placeholder:text-text-tertiary placeholder:font-normal`}
        />
      </div>
    </div>
  );
};

export default SearchBar;
