// src/authentification/AuthContext.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { getUserByEmail, checkUserStatusByEmail, updateUser as updateUserAPI } from '../api/Users'; // Adjust based on your actual implementation
import { User, UserStatus, AuthContextType } from '../types';


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedAuthState = localStorage.getItem('isAuthenticated');
    const storedUser = localStorage.getItem('user');

    if (storedAuthState === 'true' && storedUser) {
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const loginWithGoogle = async (email: string) => {
    try {
      // Simulate fetching user data from your backend based on email
      const userData = await getUserByEmail(email);

      if (userData) {
        setUser(userData);
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        // Handle case where user does not exist in your system (optional)
        // You can create a new user account here if needed
        throw new Error('User not found');
      }
    } catch (error) {
      console.error('Google login failed:', error);
      throw new Error('Google login failed');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  };


  const checkUserStatus = async (email: string): Promise<UserStatus> => {
    try {
      return await checkUserStatusByEmail(email);
    } catch (error) {
      return { status: 'server-error' };
    }
  };

  const updateUser = useCallback(async (updatedUser: User) => {
    try {
      const updated = await updateUserAPI(updatedUser); // Assuming updateUserAPI makes an API call to update user
      if (updated) {
        setUser(updatedUser); // Update local state
        localStorage.setItem('user', JSON.stringify(updatedUser)); // Update localStorage
      } else {
        throw new Error('Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      throw error; // Propagate error to handle in components
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loginWithGoogle, logout, updateUser, checkUserStatus }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
