import React from 'react';
import { Navbar, MobileNavbar } from './Navbar';
import StreakInfo from './StreakInfo';

interface MainLayoutProps {
  children: React.ReactNode;
  hasNavbar: boolean;
  hasFooterControls?: boolean;
  className?: string;
  title?: string;
  hideStreak?: boolean;
  isFullHeight?: boolean;
  hasNoPadding?: boolean;
  isFullWidth?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, hasNavbar, hasFooterControls, className = '', title, hideStreak, isFullHeight, isFullWidth, hasNoPadding }) => {
  return (
    <section className={`flex flex-col relative text-left text-text-primary w-screen h-screen bg-bg-primary
      ${hasFooterControls ? 'pb-[71px] md:pb-[96px]' : ''}
      ${hasNavbar ? 'lg:flex-row pb-[70px] lg:pb-0' : ''}
      ${className}`}
    >
      {hasNavbar && <><Navbar /><MobileNavbar /></>}
      <div className={`flex flex-col items-center w-full overflow-y-auto ${isFullHeight && 'h-full'} ${!hasNoPadding && 'px-4 md:px-6 pt-8 pb-12'}`}>
        <div className={`flex flex-col ${!isFullWidth && 'max-w-[800px]'} max-w-[800px] w-full gap-8 ${isFullHeight && 'h-full'}`}>
          {title && 
            <div className="flex w-full justify-between items-center">
              <div className="flex flex-col w-full items-start"> 
                <h1 className="text-2xl font-semibold md:text-3xl">{title}</h1>
              </div>
              { !hideStreak && <StreakInfo />}
            </div>
          }
          {children}
        </div>
      </div>
    </section>
  );
};
