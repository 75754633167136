import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ChevronRightWhite from '../../assets/chevron-right-white.svg';
import FooterControls from '../baseComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import { handleStartGameClick } from '../baseComponents/startGame';
import { useAuth } from '../../authentification/AuthContext';
import { ReactComponent as BackIcon } from '../../assets/chevron-left-tertiary-24px.svg';
import { get_number_book_exercise_words } from '../../api/Books';
import LearnImage from '../../assets/listen-mode.svg';
import RefreshImage from '../../assets/home-multiple-choice.svg';
import { MainLayout } from '../baseComponents/Layout';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';

const ExercisesDiv = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 550px;
    margin: 0 24px;
    gap: 30px;
`;

const ExerciseWrapper = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 12px;
    }
`;

const ExerciseLeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    height: 100%;
    position: relative;
`;

const VerticalBarTop = styled.div<{ index: number, isCurrentExercise?:boolean, isPreviousExercise?: boolean, isFirstBar?: boolean }>`
    bottom: 100px;
    position: absolute;
    height: ${({ isFirstBar }) => isFirstBar? "50px" : "100px"};
    width: 6px;
    border-radius: 20px;
    background-color: ${({ isCurrentExercise, isPreviousExercise, theme }) => (isPreviousExercise || isCurrentExercise) ? "#1B6669" : "#283145"};
`;

const ExerciseImage = styled.div<{ isCurrentExercise: boolean, isPreviousExercise?: boolean }>`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    padding: 8px;
    background-color: ${({ isCurrentExercise, isPreviousExercise, theme }) => (isCurrentExercise || isPreviousExercise) ? "#1B6669" : "#283145"};
`;

const ExerciseRightDiv = styled.div<{ isCurrentExercise: boolean, isPreviousExercise?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 12px;
    height: 100%;
    border-radius: 8px;
    flex: 1;
    font-size: 16px;
    // background-color: ${({ isCurrentExercise, theme }) => isCurrentExercise ? "#283145" : "transparent"};
    color: ${({ isCurrentExercise, isPreviousExercise, theme }) => isCurrentExercise ? theme.text_primary : isPreviousExercise ? theme.text_secondary : theme.text_tertiary};
`;

const ExerciseTitle = styled.h2<{ isCurrentExercise: boolean, isPreviousExercise?: boolean }>`
    font-size: 20px;
    font-weight: 500;
    color: ${({ isCurrentExercise, isPreviousExercise, theme }) => isCurrentExercise ? theme.text_brand : isPreviousExercise ?  theme.text_secondary : theme.text_tertiary};
`;

const ExerciseDescription = styled.p`
`;

const ExerciseBottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
`; 

const ExerciseNumberWords = styled.p`
    font-weight: 500;
    font-size: 14px;
`;

const ExerciseHp = styled.div`
    display: flex;
    gap: 12px;
`;

const ExerciseHpNumber = styled.p<{ isCurrentExercise: boolean, isPreviousExercise?: boolean }>`
    font-weight: 600;
    font-size: 20px;
    color: ${({ isCurrentExercise, isPreviousExercise, theme }) => (isCurrentExercise || isPreviousExercise) ? "#FECB77" : theme.text_tertiary};
    line-height: 1.2;
`;

const EndChapterPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getTextLangFrom } = useLanguage();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextExercise, setNextExercise] = useState<string>('book-chapter-review');
    const [NumberNewTranslations, setNumberNewTranslations] = useState<number>(0);
    const [NumberReviewTranslations, setNumberReviewTranslations] = useState<number>(0);
    const [NumberGeneralReviewTranslations, setNumberGeneralReviewTranslations] = useState<number>(0);
    const hasFetchedData = useRef<boolean>(false);

    useEffect(() => {
        if (!location.state || !location.state.gameData) {
            navigate('/library');
            return;
        }
    }, [location, navigate]);

    const { exercise_type, chat_id } = location.state.gameData;


    const exercises = [
        {   
            type: "book-chapter-review",
            title: getTextLangFrom('EndChapterPage-chapter-review-title'),
            description: getTextLangFrom('EndChapterPage-chapter-review-description'),
            numberOfWords: NumberReviewTranslations + " " + getTextLangFrom('word-words'),
            imgSrc: LearnImage,
            imgAlt: "Image"
        },
        {
            type: "book-general-review",
            title: getTextLangFrom('EndChapterPage-general-review-title'),
            description: getTextLangFrom('EndChapterPage-general-review-description'),
            numberOfWords: NumberGeneralReviewTranslations + " " + getTextLangFrom('word-words'),
            imgSrc: RefreshImage,
            imgAlt: "Image"
        }
    ];


    useEffect(() => {
        if (user && !hasFetchedData.current && (!location.state.gameData.NumberNewTranslations || !location.state.gameData.NumberReviewTranslations)) {
            hasFetchedData.current = true;
            get_number_book_exercise_words(chat_id, user.id).then((data) => {
                location.state.gameData.NumberNewTranslations = data.number_new_translations;
                location.state.gameData.NumberReviewTranslations = data.number_chapter_review_translations;
                location.state.gameData.NumberGeneralReviewTranslations = data.number_general_review_translations;
                setNumberNewTranslations(data.number_new_translations);
                setNumberReviewTranslations(data.number_chapter_review_translations);
                setNumberGeneralReviewTranslations(data.number_general_review_translations);

                if (data.number_chapter_review_translations > 0 && exercise_type === undefined) {
                    setNextExercise('book-chapter-review');
                } else {
                    setNextExercise('book-general-review');
                }
            });
        }
    }, [user, chat_id, location.state.gameData, exercise_type]);

    const handleStart = useCallback(() => {
        if (nextExercise === 'book-chapter-review') {
            handleStartGameClick("write-mode", nextExercise, navigate, user, setIsLoading, chat_id);
        } else if (nextExercise === 'book-general-review') {
            handleStartGameClick("multiple-choice-mode", nextExercise, navigate, user, setIsLoading, chat_id);
        }
    }, [navigate, nextExercise, user, chat_id]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'ArrowRight') {
                handleStart();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleStart]);

    return (
        <EndGameLayout
            title={getTextLangFrom('EndChapterPage-title')}
            isLoading={isLoading}
        >
            <ExercisesDiv>
                {exercises.map((exercise, index) => (
                    (exercise.type === "book-general-review" || (exercise.type === "book-chapter-review" && NumberReviewTranslations > 0)
                    ) && (
                        <ExerciseWrapper key={index}>
                            <ExerciseLeftDiv>
                                <ExerciseImage isCurrentExercise={exercise.type === nextExercise} isPreviousExercise={ exercises.findIndex((ex) => ex.type === nextExercise) > index }>
                                    <img src={exercise.imgSrc} alt={exercise.imgAlt} />
                                </ExerciseImage>
                                {/* <VerticalBarBottom index={index}/> */}
                            </ExerciseLeftDiv>
                            <ExerciseRightDiv isCurrentExercise={exercise.type === nextExercise} isPreviousExercise={ exercises.findIndex((ex) => ex.type === nextExercise) > index }>
                                <ExerciseTitle isCurrentExercise={exercise.type === nextExercise} isPreviousExercise={ exercises.findIndex((ex) => ex.type === nextExercise) > index }>
                                    {exercise.title}
                                </ExerciseTitle>
                                <ExerciseDescription>{exercise.description}</ExerciseDescription>
                                <ExerciseBottomDiv>
                                    <ExerciseNumberWords>{exercise.numberOfWords}</ExerciseNumberWords>
                                </ExerciseBottomDiv>
                            </ExerciseRightDiv>
                        </ExerciseWrapper>
                    )
                ))}
            </ExercisesDiv>
        <FooterControls>
            <div className="footer-controls-action-div">
                <Button 
                    size="control"
                    trailingIcon={ChevronRightWhite}
                    onClick={handleStart}
                    text={getTextLangFrom('word-continue')}
                />
            </div>
        </FooterControls>
    </EndGameLayout>
    );
};

export default EndChapterPage;