import styled from 'styled-components';


export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
  max-width: 720px;
  width: 100%;
  justify-content: center;
  flex: 1;

  margin-bottom: 99px;

  @media (max-width: 768px) {
    margin-bottom: 110px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.2px;

  @media (max-width: 768px) or (max-height: 800px) {
    font-size: 24px;
  }
`;

export const TitleWithSupportButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

