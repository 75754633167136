import React from 'react';
import { BookExercisePart } from '../../../types';
import TranslationsDetailsDiv from './TranslationDetails';

interface BookDiscoveryDivProps {
  focusedExerciseIndex: number;
  bookExerciseParts: (string | BookExercisePart)[];
}

const BookDiscoveryDiv: React.FC<BookDiscoveryDivProps> = ({ focusedExerciseIndex, bookExerciseParts }) => {
  const bookExercise = bookExerciseParts[focusedExerciseIndex] as BookExercisePart;
  
  return (
    <div className="flex justify-center border-t border-border-secondary">
      <div className="flex flex-col w-full max-w-screen-md px-5 py-3 gap-2">
        <TranslationsDetailsDiv bookExercise={bookExercise} size="large" />
      </div>
    </div>
  );
};

export default BookDiscoveryDiv;
