import React, { useState, useRef, useEffect } from 'react';
import { Message, GameState } from '../../types';
import { initializeChat } from '../../api/Messages';
import { useAuth } from '../../authentification/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import useVisualViewportResize from '../baseComponents/ViewportHeight';
import { fetchTTSMessages, playTTSMessages } from '../../api/textToSpeech';
import { User, Card } from '../../types';
import ChatExercise from './RandomChat/ChatExercise';
import { useLanguage } from '../../languages/LanguageContext';
import BookExercise from './Book/BookExercise';
import Modal from '../baseComponents/Modal';
import { NavbarBackProgressVoice } from '../baseComponents/ProgressBar';


const ChatPage: React.FC<{ card?: Card}> = ({ card }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let { user, updateUser } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const [chat_id, setChatId] = useState<number | null>(null);
  const [agent_id, setAgentId] = useState<number | null>(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const isInitialized = useRef(false);
  const audioElementsRef = useRef<HTMLAudioElement[]>([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isSpeechOn, setIsSpeechOn] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentTTSMessageIndex, setCurrentTTSMessageIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();


  // Check if the location state is valid and contains the game data
  useEffect(() => {
    if (!location.state || !location.state.gameData) {
      navigate('/library');
      return;
    }
  }, [location, navigate]);

  const { game_mode } = location.state.gameData;

  useEffect(() => {
    const fetchInitialMessages = async () => {
      if (isInitialized.current) {
        return;
      }

      isInitialized.current = true;
      try {
        if (user) {
          let initialMessages: Message[] = [];
          if (game_mode === 'chat-mode') {
            initialMessages = await initializeChat(user.id);
          } else if (game_mode === 'book-chat-mode') {
              let gameData: GameState | undefined = (location.state as { gameData?: GameState })?.gameData;
              if (gameData) {
                initialMessages = gameData.initial_message_s || [];
              }
          }

          setMessages(initialMessages);
          setChatId(initialMessages[initialMessages.length - 1].chat_id);
          setAgentId(initialMessages[initialMessages.length - 1].agent_id);


          if (user.voice_on) {
            let number_msgs_to_read = 1;
            
            // In case of a new book, read all the initial messages
            if ( (game_mode === 'book-chat-mode') && location.state.gameData.game_url && location.state.gameData.game_url !== '/library') {
              number_msgs_to_read = initialMessages.length;
            }

            const audioElements = await fetchTTSMessages(initialMessages, number_msgs_to_read, user.lang_to, user.voice_speed, user.voice_name);
            audioElementsRef.current.push(...audioElements);
            if (user.voice_on) {
              await playTTSMessages(audioElements, setIsSpeechOn, setCurrentTTSMessageIndex, initialMessages.length);
              setIsSpeechOn(false);
            }
          }
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching initial message:', error);
      }
    };

    fetchInitialMessages();
  }, [game_mode, user, card, location.state]);

  // useEffect(() => {
  //   let interval: NodeJS.Timeout | undefined;
  
  //   const checkAndFetchMessage = async () => {
  //     if (game_mode === 'book-listen-mode' && user?.voice_on && !isSpeechOn) {
  //       setIsSpeechOn(true);
  //       const newMessages = await getMessageResponse<Message[]>(messages[messages.length - 1], game_mode);
  //       setMessages((prevMessages) => [...prevMessages, ...newMessages]);
  //       const audioElements = await fetchTTSMessages([...messages, ...newMessages], newMessages.length, user.lang_to, user.voice_speed, user.voice_name);
  //       audioElementsRef.current.push(...audioElements);
  //       await playTTSMessages(audioElements, setIsSpeechOn, setCurrentTTSMessageIndex, messages.length + 1);
  //     }
  //   };
  
  //   if (game_mode === 'book-listen-mode' && user?.voice_on) {
  //     interval = setInterval(checkAndFetchMessage, 50);
  //   }
  
  //   return () => {
  //     if (interval) clearInterval(interval);
  //   };
  // }, [game_mode, user, messages, isSpeechOn]);  

  const handleBackClick = () => {
    handleInterruptSynthesizer();
    if (game_mode === 'book-chat-mode') {
      setIsModalOpen(true);
    } else if (game_mode === 'chat-mode') {
      navigate('/library');
    }
  };

  const handleBack = () => {
    handleInterruptSynthesizer();
    navigate('/library');
  }

  useEffect(() => {
    if (!user?.voice_on || !isSpeechOn || isLoading || isRecording) {
      handleInterruptSynthesizer();
    }
  });

  const handleInterruptSynthesizer = () => {
    try {
      console.log('Interrupting synthesizer...');
      audioElementsRef.current.forEach((audio) => {
        try {
          audio.pause();
        } catch (error) {
          console.error('Error pausing audio:', error);
        }
        audio.src = '';
        audio.load();
      });
      audioElementsRef.current = [];
      setIsSpeechOn(false); // Update state to reflect that audio is not playing
    } catch (error) {
      console.error('Error interrupting synthesizer:', error);
    }
  };
  
  const handleResumeSynthesizer = async (updatedUser?: User) => {
    if (isSpeechOn) {
      console.log('Synthesizer is already playing. Cannot resume again.');
      return; // Prevent resuming if already playing
    }
  
    setIsSpeechOn(true);
    if (user) {
      try {
        console.log('Resuming synthesizer...');
        if (updatedUser) {
          user = updatedUser;
        }
        let languageTTS = user.lang_from;
        if (game_mode === 'book-chat-mode' || game_mode === 'chat-mode') {
          languageTTS = user.lang_to;
        }
        
        // Fetch audio elements
        const audioElements = await fetchTTSMessages(messages, 1, languageTTS, user.voice_speed, user.voice_name);
        audioElementsRef.current.push(...audioElements);
        
        if (user.voice_on) {
          await playTTSMessages(audioElements, setIsSpeechOn, setCurrentTTSMessageIndex, messages.length);
        }
      } catch (error) {
        console.error('Error resuming synthesizer:', error);
        setIsSpeechOn(false); // Reset state in case of error
      }
    }
  };

  useVisualViewportResize((height: number) => {
    setWrapperHeight(height);
  });

  const handleVoiceModeClick = async () => {
    if (user) {
      const updatedUser = { ...user, voice_on: !user.voice_on };
      user = updatedUser;
      try {
        await updateUser(updatedUser);
        if (updatedUser.voice_on) {
          handleResumeSynthesizer();
        }
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
  };

  return (
    <div className='flex flex-col h-full fixed top-0 left-0 w-full bg-bg-primary'>
      <div className="px-4 pt-6 md:px-6 md:pt-8">
        <NavbarBackProgressVoice
          onBackClick={handleBackClick}
          handleVoiceModeClick={handleVoiceModeClick}
          game_mode={game_mode}
          user={user}
          handleResumeSynthesizer={handleResumeSynthesizer}
          handleInterruptSynthesizer={handleInterruptSynthesizer}
          session_progress={messages[messages.length - 1]?.session_progress ? messages[messages.length - 1]?.session_progress : 0}
        />
      </div>
      {game_mode === 'chat-mode' ? (
        <ChatExercise
          user={user}
          messages={messages}
          setMessages={setMessages}
          chat_id={chat_id}
          agent_id={agent_id}
          wrapperHeight={wrapperHeight}
          game_mode={game_mode}
          handleBack={handleBack}
          handleVoiceModeClick={handleVoiceModeClick}
          handleResumeSynthesizer={handleResumeSynthesizer}
          handleInterruptSynthesizer={handleInterruptSynthesizer}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsSpeechOn={setIsSpeechOn}
          currentTTSMessageIndex={currentTTSMessageIndex}
          setCurrentTTSMessageIndex={setCurrentTTSMessageIndex}
          audioElementsRef={audioElementsRef}
        />
      ) : (
        <BookExercise
          messages={messages}
          game_mode={game_mode}
          user={user}
          currentTTSMessageIndex={currentTTSMessageIndex}
          setMessages={setMessages} 
          setIsSpeechOn={setIsSpeechOn}
          setCurrentTTSMessageIndex={setCurrentTTSMessageIndex}
          audioElementsRef={audioElementsRef}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={getTextLangFrom('modal-interrupt-reading-header')}
        message={getTextLangFrom('modal-interrupt-reading-description')}
        confirmText={getTextLangFrom('word-resume')}
        cancelText={getTextLangFrom('word-leave')}
        onConfirm={() => setIsModalOpen(false)} 
        onCancel={handleBack}
      />
    </div>
  );
};

export default ChatPage;
