import React, { useState, useEffect } from 'react';
import LoginImage from '../../assets/login_robot.svg';
import SignupImage from '../../assets/sign-up.svg';
import { useLanguage } from '../../languages/LanguageContext';
import GoogleLoginButton from './GoogleLoginButton';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);

  useEffect(() => {
    const path = window.location.pathname;
    setIsLoginPage(path === '/login');
  }, []);

  const handleSwitchLoginSignup = () => {
    setIsLoginPage(!isLoginPage);
    setError('');
    navigate(isLoginPage ? '/signup' : '/login');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-bg-primary lg:bg-bg-secondary">
      <div className="flex flex-col items-center justify-center bg-bg-primary rounded-lg p-8 max-w-[440px] w-full gap-6 shadow-sm lg:shadow-none">
        <div className="flex justify-center w-full aspect-square">
          <img src={isLoginPage ? LoginImage : SignupImage} alt="login" className="w-full max-w-[350px] aspect-[1/1]" />
        </div>
        <div className="flex flex-col items-center text-center gap-2 text-text-tertiary">
          <h1 className="text-text-primary text-xl font-semibold">{isLoginPage ? getTextLangFrom('LoginPage-title') : getTextLangFrom('SignupPage-title')}</h1>
          <p className="text-text-tertiary">{isLoginPage ? getTextLangFrom('LoginPage-subtitle') : getTextLangFrom('SignupPage-subtitle')}</p>
        </div>
        {error && <div className="text-text-error">{error}</div>}
        <GoogleLoginButton setError={setError} />
        <div className="text-sm text-text-tertiary flex gap-2 justify-center flex-wrap">
          {isLoginPage ? getTextLangFrom('LoginPage-no-account-text') : getTextLangFrom('SignupPage-have-account-text')}
          <button onClick={handleSwitchLoginSignup} className="cursor-pointer text-text-brand font-medium">
            {isLoginPage ? getTextLangFrom('LoginPage-join-waitlist') : getTextLangFrom('word-login')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
