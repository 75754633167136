import {BookExercisePart } from '../../../types';

export const parseTextWithExercises = (text: string): (string | BookExercisePart)[] => {
  // Text format: "Some text $isExercise>translation_id>word_lang_from_with_details>word_lang_to_with_details>solution>userAnswer>has_uku>tag$ other text";
  const regex = /\$(.*?)>(.*?)>(.*?)>(.*?)>(.*?)>(.*?)>(.*?)>(.*?)\$/g;
  let regexMatch: RegExpExecArray | null;
  const parts: (string | BookExercisePart)[] = [];
  let lastIndex = 0;

  while ((regexMatch = regex.exec(text)) !== null) {
    const index = regexMatch.index;

    const [matchedText, bookExercise] = parseExercise(regexMatch);
    // console.log("bookExercise", bookExercise, "matched text", matchedText);

    if (index > lastIndex) {
      parts.push(text.substring(lastIndex, index));
    } 

    parts.push(bookExercise as BookExercisePart);

    lastIndex = index + (matchedText as string).length 
  }

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }
  return parts;
};


const parseExercise = (regexMatch: RegExpExecArray): [string, BookExercisePart] => {
  const [matchedText, isExercise, translation_id, word_lang_from_with_details, word_lang_to_with_details, solution, userAnswer, has_uku, tag] = regexMatch;
  const [lemma_lang_to, pos_lang_to, gender_lang_to, number_lang_to, plural_lang_to] = word_lang_to_with_details.split('/');

  const [lemma_lang_from, pos_lang_from, gender_lang_from, number_lang_from, plural_lang_from] = word_lang_from_with_details.split('|')[0].split('/');

  const alt_str = word_lang_from_with_details.split('|')[1];
  const alternative_lemmas_lang_from = alt_str === '' ? [] : alt_str.split('/');

  // console.log("lemma_lang_to", lemma_lang_to, "has_uku", has_uku);

  let bookExercise = {
    translation_id: parseInt(translation_id),
    lemma_lang_from: lemma_lang_from,
    pos_lang_from: pos_lang_from,
    gender_lang_from: gender_lang_from,
    number_lang_from: number_lang_from,
    plural_lang_from: plural_lang_from,
    alternative_lemmas_lang_from: alternative_lemmas_lang_from,
    lemma_lang_to: lemma_lang_to,
    pos_lang_to: pos_lang_to,
    gender_lang_to: gender_lang_to,
    number_lang_to: number_lang_to,
    plural_lang_to: plural_lang_to,
    solution,
    userAnswer,
    exerciseState: "unanswered",
    isExercise: isExercise === '1' ? true : false,
    has_uku: has_uku === '1' ? true : false,
    tag: tag,
  }

  return [matchedText as string, bookExercise as BookExercisePart];
}


export const parseTextWithoutExercises = (text: string) => {
  // Input text format: "Some text $word_id>lemma_lang_from>solution>userAnswer$ other text";
  // Keep the lemma_lang_to, remove the lemma_lang_from and userAnswer
  // Output text: "Some text solution other text";
  const regex = /\$(.*?)>(.*?)>(.*?)>(.*?)>(.*?)>(.*?)\$/g;
  let regexMatch;
  let outputText = '';
  let lastIndex = 0;

  while ((regexMatch = regex.exec(text)) !== null) {
    const [matchedText, isExercise, translation_id, lemma_lang_from, lemma_lang_to, solution, userAnswer] = regexMatch;
    const index = regexMatch.index;
    if (index > lastIndex) {
      outputText += text.substring(lastIndex, index);
    }
    outputText += solution;
    lastIndex = index + matchedText.length;
  }
  if (lastIndex < text.length) {
    outputText += text.substring(lastIndex);
  }
  return outputText;
}


export const parseTextWithMask = (text: string) => {
  const maskRegex = /\$(\d+)>[^$]*\$/g;
  let newText = text.replace(maskRegex, (_, id) => {
    const textStart = _.indexOf('>') + 1;
    const textEnd = _.lastIndexOf('$');
    const maskedText = _.substring(textStart, textEnd);
    return maskedText;
  });
  return newText;
}